//@flow

// Vendors
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Styles
import './section.scss';

// Types
type Props = {
  className?: string,
  title: string,
  children?: Node,
  viewAll?: {
    text: string,
    url: string,
  },
};

/**
 * @visibleName Default
 */
const Section = ({ className, title, children, viewAll, ...props }: Props) => (
  <section {...props} className={classnames('section', className)}>
    <header className="section-header" role="banner" aria-label="banner" >
      {title && <h2 className="section-title">{title}</h2>}
      {viewAll && viewAll.text && (
        <Link to={viewAll.url} className="section-view-all">
          {viewAll.text}
        </Link>
      )}
    </header>
    <div className="section-body">{children}</div>
  </section>
);

export default Section;
