// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputCode } from '../../..';
import { InputGroupCodeResendCode } from './InputGroupCodeComponents';

// Styles
import './input-group-code.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Code
 */
const InputGroupCode = ({
  className,
  autoFocus = false,
  resendCodeActionCreator,
  onResendCodeSuccess,
  formikProps,
  bannerType,
  channelCode,
  labelPosition = 'inside',
  ...props
}) => (
  <InputGroup {...props} className={classnames('input-group-code', className)}>
    <InputCode
      formikProps={formikProps}
      autoFocus={autoFocus}
      labelPosition={labelPosition}
    />
    {bannerType !== 'RetryLimitError' && (
      <InputGroupCodeResendCode
        formikProps={formikProps}
        resendCodeActionCreator={resendCodeActionCreator}
        onResendCodeSuccess={onResendCodeSuccess}
        channelCode={channelCode}
      />
    )}
  </InputGroup>
);

InputGroupCode.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool.isRequired,
  resendCodeActionCreator: PropTypes.func,
  onResendCodeSuccess: PropTypes.func,
  formikProps: formikInjectedPropsTypes.isRequired,
  bannerType: PropTypes.string,
  channelCode: PropTypes.string,
  labelPosition: PropTypes.string,
};

export default InputGroupCode;
