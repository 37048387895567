import React from 'react';

// Components
import { Trans } from '../..';

//utils
import { sortAgencies } from '../../../lib/helpers/util';

function addAgencyServiceItem(agency) {
  return {
    service: (
      <Trans
        file="Labels"
        id={agency.agencySlug.toUpperCase() + 'Services'}
        fallback="Massage therapy license services"
      />
    ),
    //Agency name label used for Spanish translation
    agency: (
      <Trans
        file={agency.agencySlug}
        id="AgencyName"
        fallback={agency.agencyName}
      />
    ),
    path: handleAgencyPath(agency.agencySlug),
  };
}

function handleAgencyPath(agencySlug) {
  return '/agency/' + agencySlug;
}

export function getServiceMenuItems(agencies) {
  const menuItemsServices = Object.entries(agencies.agencies).map(item => {
    return addAgencyServiceItem(item[1]);
  });

  menuItemsServices.sort(sortAgencies);
  return menuItemsServices;
}
