// Vendors
import React from 'react';

import { useDispatch } from 'react-redux';

// Components
import { Modal, Trans } from '../..';
import { closeModal } from '../../../../reducers/modal/modalActions';

//Styles
import './payment-form-error-modal.scss';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

const PaymentFormErrorModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="PaymentFormErrorModal"
      className="payment-form-error-modal"
      disableOnClickOutside
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="IframeTimeOutTitle" />
      </h2>
      <div className="modal-content">
        <Trans file="Modals" id="IframeTimeOutBody" />
      </div>
      <ButtonPrimary
        className="modal-close-button"
        onClick={() => dispatch(closeModal('PaymentFormErrorModal'))}
      >
        <Trans file="Modals" id="Ok" />
      </ButtonPrimary>
    </Modal>
  );
};

export default PaymentFormErrorModal;
