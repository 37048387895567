/**
 * Sends a message to the native app web container.
 * @param {Object} data - The data to be sent to the native app.
 * ex. https://medium.com/@sreeharikv112/communication-from-webview-to-native-ios-android-app-6d842cefe02d
 */
export const sendMessageToNativeApp = data => {
  const message = JSON.stringify(data);

  // Check if the Android interface exists
  if (
    window.JSBridge &&
    typeof window.JSBridge.jsMessageHandler === 'function'
  ) {
    window.JSBridge.jsMessageHandler(message);
  }
  // Check if the iOS interface exists
  else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.jsMessageHandler &&
    typeof window.webkit.messageHandlers.jsMessageHandler.postMessage ===
      'function'
  ) {
    window.webkit.messageHandlers.jsMessageHandler.postMessage(message);
  }
};
