const lookupHints = {
  lastFourOfVIN: {
    content: { file: 'dmv', id: 'dmvrr_LastFourOfVIN_Hint' },
    onFocusOnly: true,
  },
  plateNumber: {
    content: { file: 'dmv', id: 'dmvrr_PlateNumber_Hint' },
    onFocusOnly: true,
  },
  ownerIdentification: {
    content: { file: 'dmv', id: 'dmvrr_TexasDl_Hint' },
    onFocusOnly: true,
  },
  FullSSN: {
    content: { file: 'dps', id: 'SSNHint' },
    onFocusOnly: true,
  },
  DLNumber: {
    content: { file: 'dps', id: 'DLNumberHint' },
    onFocusOnly: true,
  },
  AuditNumber: {
    content: { file: 'dps', id: 'AuditNumberHint' },
    onFocusOnly: true,
  },
};

module.exports = lookupHints;
