export const dpslr = {
  agencySlug: 'dps',
  appCode: 'DLR',
  slug: 'dpslr',
  linkService: {
    linkType: 'license',
    form: {
      labels: {
        dlIDNumber: { id: 'DLNumber' },
        auditNumber: { id: 'AuditNumber' },
        dob: { id: 'DOB_MDY' },
        ssn: { id: 'FullSSN' },
      },
    },
  },
};
