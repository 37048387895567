// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputRadio, InputRadioInverse, Trans } from '../../..';

// Styles
import './input-radio-group.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Radio Group
 */
const InputRadioGroup = ({
  className,
  label,
  name,
  onChange,
  options,
  optionValueKey = '',
  inverse,
  inline,
  spacedEvenly,
  formikProps,
}) => {
  const {
    handleChange,
    getFieldValue,
    getFieldError,
    getFieldStatus,
    setFieldValue,
    submitCount,
  } = formikProps;
  const localError = getFieldError(name);
  const serverError = (getFieldStatus('serverErrors') || {})[name];
  const errorMap =
    serverError && typeof serverError.id === 'string'
      ? serverError
      : localError;
  const showError = errorMap && submitCount > 0;
  const InputComponent = inverse ? InputRadioInverse : InputRadio;

  const onChangeHandler = event => {
    const selectedOptionValue = event.target.value || '';

    if (handleChange) handleChange(event);
    if (onChange) onChange(event);
    if (!optionValueKey) return;

    let selectedOptionObject =
      options.find(
        option => option && option.value[optionValueKey] === selectedOptionValue
      ) || {};
    if (setFieldValue) setFieldValue(name, selectedOptionObject.value);
  };

  return (
    <div
      className={classnames('input-radio-group', className, {
        'input-radio-group-inline': inline,
        'input-radio-group-inline-spaced-evenly': spacedEvenly,
        hasError: showError,
        hasLabel: label,
      })}
    >
      {label && <p className="input-radio-group-label">{label}</p>}

      {options.map(option => {
        const valueIsObject = () =>
          optionValueKey && typeof option.value === 'object';

        const value = valueIsObject()
          ? option.value[optionValueKey]
          : option.value;

        const isChecked = () =>
          valueIsObject()
            ? getFieldValue(`${name}.${optionValueKey}`) ===
              option.value[optionValueKey]
            : getFieldValue(name) === option.value;

        return (
          <InputComponent
            id={option.id}
            key={option.id}
            className={option.className}
            name={name}
            label={option.label}
            value={value}
            onChange={onChangeHandler.bind(this)}
            checked={isChecked()}
            hideError={true}
            formikProps={formikProps}
            disabled={option.disabled}
          />
        );
      })}

      {showError && (
        <div className="input-radio-group-error">
          <span>
            <Trans file="Errors" id={errorMap.id} />
          </span>
        </div>
      )}
    </div>
  );
};

const radioOptionsProp = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
});

InputRadioGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(radioOptionsProp).isRequired,
  optionValueKey: PropTypes.string,
  inverse: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired,
  spacedEvenly: PropTypes.bool.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputRadioGroup;
