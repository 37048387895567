// Helpers
import {
  to,
  callbacksWithSelectedMFAIndex,
  callbacksWithMFACode,
  // callbacksWithMFACodeResend,
  // updateWithDeviceDetails,
  // updateWithRememberThisDevice,
  requiresMFACode,
} from '../../lib/helpers';
import { gaActions } from '../gaActionTypes';

// Requests
import {
  loginRequest,
  loginMFARequest,
  // loginSuccessRequest,
} from '../../backend/requests';

// Actions
import { getSession } from '../auth/authActions';
import { storeAlertInfo, clearAlertInfo } from '../alert/alertActions';
import {
  selectLoginMFACallbacks,
  selectLoginMFABody,
  // selectRememberThisDevice,
  selectSelectedMFAOption,
  selectDefaultMFAOption,
} from './loginSelectors';
import { checkRequestFailure } from '../../lib/validation';
import * as errorActions from '../../reducers/error/errorActions';
import {
  pushMessageBanner,
  clearMessageBanner,
} from '../../reducers/messageBanner/messageBannerActions';

// Consts

// Login flow actions
export const LOGIN_SUBMITTED = `[LOGIN] ${gaActions.submit} Login Submitted`;
//export const LOGIN_DEVICE_DETAILS_SENT = `[LOGIN] Device Details Sent`;
export const LOGIN_MFA_OPTION_SENT = `[LOGIN] ${gaActions.submit} MFA Option Sent`;
export const LOGIN_MFA_CODE_RESENT = `[LOGIN] ${gaActions.click} MFA Code Resent`;
export const LOGIN_MFA_CODE_SENT = `[LOGIN] ${gaActions.submit} MFA Code Sent`;
//export const LOGIN_REMEMBER_DEVICE_SENT = `[LOGIN] Remember Device Sent`;
export const LOGIN_SUCCESS = `[LOGIN] ${gaActions.success} Login Success`;
export const LOGIN_FAILURE = `[LOGIN] ${gaActions.failure} Login Failure`;

// Other login actions
export const LOGIN_STORE_DATA = `[LOGIN] Data Stored`;
export const LOGIN_STORE_EMAIL = '[LOGIN] Email Stored';
export const LOGIN_UPDATE_MESSAGE = '[LOGIN] Message Updated';
export const LOGIN_RESET_MESSAGE = '[LOGIN] Reset Message';
export const LOGIN_SET_STEP = '[LOGIN] Set Step';
export const LOGIN_CLEAR_STATE = '[LOGIN] Clear State';
export const LOGIN_STORE_REDIRECT = '[LOGIN] Storing Redirect';

// Login Steps
export const LOGIN_STEPS = {
  CREDENTIALS: 'credentials',
  MFA_OPTIONS: 'mfa-options',
  MFA_CODE: 'mfa-code',
};

// dispatch(storeLoginRedirect(window.location.pathname));
export const storeLoginRedirect = payload => dispatch => {
  dispatch({ type: LOGIN_STORE_REDIRECT, payload });
};

// NOTE: Login Middleware is used to control the logical flow of these functions.
export const loginStoreData = payload => dispatch => {
  dispatch({ type: LOGIN_STORE_DATA, payload });
};
/**
 * Decides wheter username and password are correct
 *
 * @param {Object} values
 * @param {Object} param2
 */
export const loginSubmitted = (
  values,
  { setSubmitting, resetForm, setStatus }
) => async dispatch => {
  //const state = getState();
  await dispatch(getSession()); // Ensure we have the latest session data
  const [{ body, response }, err] = await to(
    dispatch(
      loginRequest(
        values,
        process.env.REACT_APP_FORGEROCK_URL + '/authenticate'
      )
    )
  );

  if (err) {
    resetForm({ email: values.email, password: undefined });
    setSubmitting(false);

    switch (err.body.errorDescription.toLowerCase()) {
      case 'unauthorized':
        setStatus({
          serverErrors: { email: { id: 'Field_PasswordIncorrect' } },
        });
        break;
      case 'user is locked since otp was incorrect':
      case 'user is locked':
        dispatch({ type: errorActions.ERROR_LOCKOUT });
        break;
      case 'user credentials are incorrect':
        setStatus({
          serverErrors: { password: { id: 'Field_PasswordIncorrect' } },
        });
        break;
      default:
        setStatus({
          serverErrors: { email: { id: 'Field_PasswordIncorrect' } },
        });
        break;
    }
    return;
  }

  //If no errors, dispatch next step
  if (!body.errorDescription && !body.status && !body.errorCode) {
    dispatch(clearAlertInfo());
    return dispatch({ type: LOGIN_SUBMITTED, payload: { body, response } });
  }
};

/**
 * Call after OTP code has been sent
 *
 * @param {Object} param0
 */
export const loginSuccess = ({ body, response }) => async dispatch => {
  if (checkRequestFailure({ response, body }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  else return dispatch({ type: LOGIN_SUCCESS, payload: { body, response } });
};

/**
 * Only a single MFA option available, use that
 */
export const UseDefaultMFAOption = () => async (dispatch, getState) => {
  const state = getState();
  const selectedMFAOption = selectDefaultMFAOption(state);
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedMFAIndex({
        selectedMFAOption,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  const [{ body, response }] = await to(
    dispatch(
      loginMFARequest(
        request,
        process.env.REACT_APP_FORGEROCK_URL + '/mfaChoices'
      )
    )
  );
  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  return dispatch({
    type: LOGIN_MFA_OPTION_SENT,
    payload: { body, selectedMFAOption },
  });
};

/**
 * Sending choice for MFA options
 *
 * @param {o\object} values
 * @param {Object} param1
 */
export const MFAOptionSubmitted = (values, { setSubmitting }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { messageBanner } = state;
  let messageId;
  if (messageBanner) {
    messageId = messageBanner.messages.map(a => a.messageId);
    dispatch(clearMessageBanner(messageId));
  }
  const selectedMFAOption = values.MFAOption.value;
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedMFAIndex({
        selectedMFAOption,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  const [{ body, response }] = await to(
    dispatch(
      loginMFARequest(
        request,
        process.env.REACT_APP_FORGEROCK_URL + '/mfaChoices'
      )
    )
  );
  if (response.status !== 200) setSubmitting(false);
  return dispatch({
    type: LOGIN_MFA_OPTION_SENT,
    payload: { body, selectedMFAOption },
  });
};

/**
 * Sends OTP code
 *
 * @param {Object} values
 * @param {Object} param1
 */
export const MFACodeSubmitted = (
  values,
  { setSubmitting, resetForm, setStatus }
) => async (dispatch, getState) => {
  const state = getState();
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithMFACode({
        MFACode: values.code,
        rememberThisDevice: values.rememberThisDevice,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  const [{ body, response }, err] = await to(
    dispatch(
      loginMFARequest(
        request,
        process.env.REACT_APP_FORGEROCK_URL + '/processOTP'
      )
    )
  );

  const { messageBanner } = state;
  let messageId;
  if (messageBanner) {
    messageId = messageBanner.messages.map(a => a.messageId);
  }

  const messageIdTime = Date.now();

  if (err) {
    setSubmitting(false);
    dispatch(clearAlertInfo());
    dispatch(clearMessageBanner(messageId));
    switch (err.body.errorDescription.toLowerCase()) {
      case 'user is locked since otp was incorrect':
        dispatch({ type: errorActions.ERROR_LOCKOUT });
        break;

      case 'otp entered has expired':
        dispatch(clearLoginState());
        dispatch(
          pushMessageBanner([
            {
              messageId: messageIdTime.toString(),
              textEnglish: `{"type": "Errors", "id": "Field_Sorry_Title"}`,
              textSpanish: `{"type": "Errors", "id": "Field_Sorry_Title"}`,
              startTime: '2021-01-01 18:35:00.0',
              endTime: '2040-06-30 12:05:00.0',
              priority: '2',
              status: 'R',
              author: 'USER',
              valid: true,
            },
          ])
        );
        break;
      default:
        break;
    }
    return;
  }

  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });

  if (requiresMFACode(body)) {
    resetForm();
    setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: { body, selectedMFAOption: selectSelectedMFAOption(getState()) },
    });
  }

  if (body && !body.errorDescription && !body.status && !body.errorCode) {
    return dispatch({
      type: LOGIN_MFA_CODE_SENT,
      payload: {
        ...{ body, response },
        rememberThisDevice: values.rememberThisDevice,
      },
    });
  }
};

export const loginOTPTimeout = () => async dispatch => {
  dispatch(clearLoginState());
  dispatch(
    storeAlertInfo({
      type: 'warning',
      title: { file: 'Alerts', id: 'SorryTitle' },
      message: { file: 'Alerts', id: 'SignInExpired' },
      closeable: true,
    })
  );
};

/**
 * Resend code to your Phone # or email
 */
export const resendCode = () => (dispatch, getState) => {
  const state = getState();
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithMFACode({
        MFACode: '',
        rememberThisDevice: false,
        callbacks: selectLoginMFACallbacks(state),
        resendCode: 'true',
      }),
    },
  };

  return dispatch(
    loginMFARequest(
      request,
      process.env.REACT_APP_FORGEROCK_URL + '/processOTP'
    )
  );
};

export const onResendCodeSuccess = ({ body }) => dispatch =>
  dispatch({ type: LOGIN_MFA_CODE_RESENT, payload: { body } });

export const storeEmail = email => dispatch =>
  dispatch({ type: LOGIN_STORE_EMAIL, payload: email });

export const updateLoginMessage = loginMessage => dispatch =>
  dispatch({ type: LOGIN_UPDATE_MESSAGE, payload: loginMessage });

export const resetLoginMessage = () => dispatch =>
  dispatch({ type: LOGIN_RESET_MESSAGE });

export const clearLoginState = () => dispatch =>
  dispatch({ type: LOGIN_CLEAR_STATE });
