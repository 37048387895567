//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Form, Modal, Trans, InputText } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

//Helpers
import { addressLine2ValidationSchema } from '../../../../lib/validation/schemas/common';

// Styles
import './address-two-missing-modal.scss';
import { states, counties } from '../../../../lib/data/agencyData/BON';

// Types
type Props = {
  id?: string,
  onCancel?: Function,
  onSuccess: Function,
  modal: Object,
  closeModal: Function,
  className?: string,
};

function AddressTwoMissingModal(props: Props) {
  const {
    className,
    onSuccess,
    closeModal,
    id = 'AddressTwoMissingModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.addressOptions) return false;

  const handleSubmit = values => {
    closeModal(id);
    onSuccess({
      ...data.enteredAddress,
      state:
        states.find(state => state.value === data.enteredAddress.state) || null,
      county:
        counties.find(county => county.label === data.enteredAddress.county) ||
        null,
      addressLine2: values.addressLine2,
    });
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className={classnames('address-two-missing-modal', className)}
    >
      <h1 className="h1 modal-title">
        <Trans file="Modals" id="AddressTwoMissingTitle" />
      </h1>
      <div className="modal-content">
        <div>
          <p className="modal-description">
            <Trans file="Modals" id="AddressTwoMissingBody" />
          </p>
          <h4 className="modal-address">
            {data.enteredAddress.streetAddress} <br />
            {data.enteredAddress.city}, {data.enteredAddress.state}{' '}
            {data.enteredAddress.zipFive} {data.enteredAddress.county}
          </h4>
        </div>
        <Form
          formikConfig={{
            initialValues: {
              addressLine2: '',
            },
            validationSchema: addressLine2ValidationSchema,
            onSubmit: handleSubmit,
          }}
          render={({ FormActions, FormActionsItem, formikProps }) => (
            <>
              <InputText
                className="address-line-2"
                name="addressLine2"
                maxLength={/DMV/.test(id) ? 30 : 32}
                formikProps={formikProps}
                optional={true}
                label={<Trans file="Modals" id="AddressTwoInputLabel" />}
                labelPosition="above"
              />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary type="submit">
                    <Trans file="Labels" id="Confirm" />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressTwoMissingModal);
