// Vendors
import { lazy } from 'react';
import PAGE_TYPES from '../pageTypes';

export default [
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'BNLREligibilityRequirements',
    component: lazy(() => import('./BON/BNLR/BNLREligibilityRequirements')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'BNLRPage',
    component: lazy(() => import('./BON/BNLR/BNLRPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'DLRLRPage',
    component: lazy(() => import('./DLR/DLRLR/DLRLRPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'DPSUpdateEmergencyContactsPage',
    component: lazy(() =>
      import(
        './DLR/DLRLR/DLRLRSteps/DPSUpdateEmergencyContacts/DPSUpdateEmergencyContactsPage'
      )
    ),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'DPSUpdateEmergencyContactsReceipt',
    component: lazy(() =>
      import(
        './DLR/DLRLR/DLRLRSteps/DPSUpdateEmergencyContacts/DPSUpdateEmergencyContactsReceipt'
      )
    ),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'TDLRLRPage',
    component: lazy(() => import('./TDLR/TDLRLR/TDLRLRPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'DMVRRPage',
    component: lazy(() => import('./DMV/DMVRR/DMVRRPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'DMVStickerTracker',
    component: lazy(() => import('./DMV/DMVStickerTracker/DMVStickerTracker')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'CybersecurityPage',
    component: lazy(() => import('./Cybersecurity/CybersecurityPage')),
  },
];
