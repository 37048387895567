//@flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../reducers/modal/modalActions';
import { TextButton } from 'texkit/dist/components/Buttons';
import { Trans } from '../../..';

const LicenseDetailModal = () => {
  const dispatch = useDispatch();

  return (
    <TextButton
      icon="info"
      onClick={event => {
        event.preventDefault();
        dispatch(openModal('LicenseDetailsModal'));
      }}
      type="button"
    >
      <Trans
        file="Labels"
        id="HowToFindLicenseNumbers"
        fallback="HowToFindLicenseNumbers"
      />{' '}
    </TextButton>
  );
};

export default LicenseDetailModal;
