//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

// Actions
import { onboardingSaveProgress } from '../../../../reducers/onboarding/onboardingActions';

// Helpers
import { ONBOARDING_SAVE_POINT_NAMES } from '..';

// Graphics
import { ReactComponent as OnboardSuccessSvg } from '../../../../assets/onboarding/onboard-success-star.svg';

// Components
import {
  FormActions,
  FormActionsItem,
  Trans,
} from '../../../../components/common';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

const OnboardingStartLinking = () => {
  const dispatch = useDispatch();

  return (
    <>
      <h2 className="h1 title">
        <Trans file="Onboarding" id="CompleteTitle" fallback="CompleteTitle" />
      </h2>
      <>
        <p className="lead">
          <Trans
            file="Onboarding"
            id="CompleteDescription_One"
            fallback="CompleteDescription_One"
          />
        </p>
        <p className="lead onboarding-list">
          <Trans
            file="Onboarding"
            id="CompleteDescription_Two"
            fallback="CompleteDescription_Two"
          />
        </p>
        <div className="onboarding-body">
          <div className="graphic">
            <OnboardSuccessSvg role="presentation" />
          </div>

          <FormActions align="center">
            <FormActionsItem>
              <ButtonPrimary
                onClick={() => {
                  dispatch(
                    onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
                  );
                  dispatch(replace('/dashboard'));
                }}
              >
                <Trans
                  file="Labels"
                  id="ViewMyDashboard"
                  fallback="ViewMyDashboard"
                />
              </ButtonPrimary>
            </FormActionsItem>
          </FormActions>
        </div>
      </>
    </>
  );
};

export default OnboardingStartLinking;
