//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import {
  Trans,
  FormActionsItem,
  FormActions,
} from '../../../../components/common';
import { ButtonPrimary, TextButton } from 'texkit/dist/components/Buttons';
import { OnboardingSecurityQuestionsStepWrapper } from './index';
import { ONBOARDING_STEP_ROUTES, ONBOARDING_SAVE_POINT_NAMES } from '..';
import { onboardingSaveProgress } from '../../../../reducers/onboarding/onboardingActions';

// Graphics
import { ReactComponent as OnboardingSecureAccountLockSvg } from '../../../../assets/onboarding/onboarding-secure-account-lock.svg';

const OnboardingSecurityQuestions = () => {
  const dispatch = useDispatch();
  const handleNextClick = () =>
    dispatch(
      push(
        `/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS_CAPTURE}`
      )
    );
  return (
    <OnboardingSecurityQuestionsStepWrapper>
      <div className="onboarding-body">
        <div className="modal-graphic">
          <OnboardingSecureAccountLockSvg role="presentation" />
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={handleNextClick}>
              <Trans file="Labels" id="Next" fallback="Next" />
            </ButtonPrimary>
          </FormActionsItem>
        </FormActions>

        <div className="page-links">
          <TextButton
            onClick={async () => {
              await dispatch(
                onboardingSaveProgress(
                  ONBOARDING_SAVE_POINT_NAMES.SECURITY_QUESTIONS
                )
              );
              dispatch(
                push(`/getting-started/${ONBOARDING_STEP_ROUTES.START_LINKING}`)
              );
            }}
          >
            <Trans file="Labels" id="SkipForNow" fallback="Skip for now" />
          </TextButton>
        </div>
      </div>
    </OnboardingSecurityQuestionsStepWrapper>
  );
};

export default OnboardingSecurityQuestions;
