// @flow

// Vendors
import React, { type Node } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Styles
import './slide-transition.scss';

// Types
type Props = {
  children: Node,
  className?: string,
};

const SlideTransition = ({ className, children, ...props }: Props) => (
  <CSSTransition
    {...props}
    classNames="slide-transition"
    timeout={500}
    unmountOnExit
  >
    <div className={classnames('slide-transition', className)}>{children}</div>
  </CSSTransition>
);

export default SlideTransition;
