// Actions
import { signOut } from '../auth/authActions';
import { storeAlertInfo } from '../alert/alertActions';
import moment from 'moment';
import initialState from '../../store/initialState';

import { selectSessionData } from './sessionSelectors';

export const SESSION_UPDATED = '[SESSION] Updated';
export const SESSION_ENDED = '[SESSION] Ended';
export const REFRESHING_TOKEN = '[SESSION] Refreshing Token';
export const SET_SESSION_TIMEOUT_MODAL =
  '[SESSION] Update Session timout modal';

export const updateSession = date => dispatch => {
  dispatch({
    type: SESSION_UPDATED,
    payload: {
      latestAccessTime: moment
        .utc(date)
        .subtract(15, 'minutes')
        .format(),
      maxIdleExpirationTime: moment.utc(date).format(),
      maxSessionExpirationTime: moment
        .utc(date)
        .add(105, 'minutes')
        .format(),
    },
  });
};

export const updateRefreshingSessionFlag = data => dispatch => {
  dispatch({
    type: REFRESHING_TOKEN,
    payload: data,
  });
};

export const updateIdleExpirationTime = date => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: SESSION_UPDATED,
    payload: {
      ...selectSessionData(state),
      maxIdleExpirationTime: moment.utc(date).format(),
    },
  });
};

export const endSession = (warning = {}) => async dispatch => {
  await dispatch(signOut());

  const { titleId, messageId } = warning;
  dispatch(
    storeAlertInfo({
      type: 'warning',
      title: { file: 'Alerts', id: titleId || 'SessionExpiredTitle' },
      message: { file: 'Alerts', id: messageId || 'SignInAgain' },
      closeable: true,
    })
  );
};

export const setTimeoutModal = payload => dispatch => {
  dispatch({
    type: SET_SESSION_TIMEOUT_MODAL,
    payload,
  });
};

export const resetTimeoutModal = () => dispatch => {
  dispatch({
    type: SET_SESSION_TIMEOUT_MODAL,
    payload: initialState.session.timeoutModal,
  });
};
