//@flow

// Vendors
import React, { useRef } from 'react';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Styles
import '../dps-FAQ-modal.scss';

const DPS_Renewal_FAQModal = () => {
  const modal = useRef();
  const modalMobile = useRef();

  return (
    <Modal name="DPS_Renewal_FAQModal" className="faq-modal">
      <div className="modal-content-wrapper" ref={modalMobile}>
        <h2 className="h1 modal-title">
          <Trans file="dps" id="dps_RENEWAL_FAQ_modal_title" />
        </h2>
        <p className="modal-description">
          <Trans file="dps" id="dps_RENEWAL_FAQ_modal_desc" />
        </p>
        <div ref={modal} className="modal-inner-body">
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_1" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_1_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_2" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_2_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_3" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc after">
              <Trans file="dps" id="dps_RENEWAL_FAQ_3_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_4" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_4_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_5" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_5_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_6" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_6_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_7" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_7_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_8" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_8_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_9" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_9_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_10" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_10_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_11" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_11_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_12" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_12_Desc" />
            </p>
          </div>
          <div className="modal-content">
            <p>
              <Trans file="dps" id="dps_RENEWAL_FAQ_13" />
            </p>
          </div>
          <div>
            <p className="modal-content-desc">
              <Trans file="dps" id="dps_RENEWAL_FAQ_13_Desc" />
            </p>
          </div>
        </div>
      </div>
      <ButtonPrimary
        className="modal-button initial"
        onClick={() => {
          if (modal && modal.current) modal.current.scrollTop = 0;
        }}
        icon="more"
      >
        <Icon name="chevron-up" />
        <Trans file="Labels" id="BackToTop" />
      </ButtonPrimary>
      <ButtonPrimary
        className="modal-button-mobile initial"
        onClick={() => {
          if (modalMobile && modalMobile.current)
            modalMobile.current.scrollTop = 0;
        }}
        icon="more"
      >
        <Icon name="chevron-up" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DPS_Renewal_FAQModal;
