import React from 'react';
import PropTypes from 'prop-types';
//Components
import { TxAlert } from 'texkit-ui/components';
import Trans from '../../Trans/Trans';

import VALIDATION_MAP from './ValidationFieldMap';

const ValidationAlert = ({ errorType, errors, title }) => {
  return (
    <TxAlert
      type={errorType}
      heading={
        <Trans
          file="Errors"
          id={title}
          fallback="Something went wrong. Please go back and fix the following errors:"
        />
      }
    >
      <ul>
        {Object.keys(errors).map((v, i) => {
          if (v !== 'REF')
            return (
              <li key={i}>
                {<Trans file="dmv" id={VALIDATION_MAP[v]} />} -{' '}
                {<Trans file="Errors" id={errors[v].id} />}
              </li>
            );
        })}
      </ul>
    </TxAlert>
  );
};

ValidationAlert.propTypes = {
  errorType: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ValidationAlert;
