//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal, Trans } from '../..';
import PaymentFrame, {
  PaymentFrameTypes,
} from '../../../Payment/PaymentFrame/PaymentFrame';

// Actions
import { getPaymentAccounts } from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';

import './add-payment-account-modal.scss';

const AddPaymentAccountModal = () => {
  const dispatch = useDispatch();

  const handleSuccess = () => {
    dispatch(getPaymentAccounts());
    dispatch(closeModal('AddPaymentAccountModal'));
  };

  return (
    <Modal
      name="AddPaymentAccountModal"
      className="add-payment-account-modal"
      disableOnClickOutside="true"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Payment"
          id="AddCardTitle"
          fallback="Add a new credit or debit card."
        />
      </h2>
      <p className="lead">
        <Trans
          file="Payment"
          id="AddCardDescription"
          fallback="You can update or remove this information at any time from your account settings."
        />
      </p>
      <PaymentFrame
        file={PaymentFrameTypes.ADD_ACCOUNT}
        onSuccess={handleSuccess}
        onCancel={() => dispatch(closeModal('AddPaymentAccountModal'))}
      />
    </Modal>
  );
};

export default AddPaymentAccountModal;
