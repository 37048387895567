import React, { useState } from 'react';
import { TxInput } from 'texkit-ui';
import { ButtonIcon } from 'texkit/dist/components/Buttons';

import './ssn-format-input.scss';

const SSNFormInput = props => {
  const [inputType, setInputType] = useState('password');

  const InputIcon = (
    <ButtonIcon
      className="ssn-input-icon"
      icon={inputType === 'password' ? 'eye' : 'eye-closed'}
      onClick={() => {
        setInputType(inputType === 'password' ? 'text' : 'password');
      }}
      tabIndex="-1"
      aria-hidden={true}
      aria-label="password-eye-icon"
    />
  );

  return <TxInput {...props} icon={InputIcon} type={inputType} />;
};

export default SSNFormInput;
