//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';
import { Route, withRouter } from 'react-router-dom';

// Components
import { StepperWithRoutesStep } from '.';

// Styles
import '../stepper.scss';

// Types
type Props = {
  steps: Array<Object>,
  defaultStepId: string,
  transition?: string,
  onStepChange?: Function,
  onStepChanged?: Function,
  showDefaultURLPath?: boolean,
  redirectDefaultToStep?: boolean,
  className?: string,
  match: Object,
  children?: Function,
};

const StepperWithRoutes = ({
  steps,
  defaultStepId,
  transition,
  onStepChange,
  onStepChanged,
  showDefaultURLPath,
  redirectDefaultToStep,
  className,
  match,
  children,
  ...props
}: Props) => {
  if (!steps.length) return null;

  const parentRoute = match.url;
  defaultStepId = defaultStepId || steps[0].id;

  return (
    <div className={classnames('stepper', className)}>
      <Route
        path={`${parentRoute}/:stepId?`}
        render={({
          match: {
            params: { stepId },
          },
        }) => (
          <>
            {children && children({ activeStepId: stepId || defaultStepId })}
            <StepperWithRoutesStep
              steps={steps}
              stepId={stepId}
              defaultStepId={defaultStepId}
              parentRoute={parentRoute}
              transition={transition}
              onStepChange={onStepChange}
              onStepChanged={onStepChanged}
              showDefaultURLPath={showDefaultURLPath}
              redirectDefaultToStep={redirectDefaultToStep}
            />
          </>
        )}
      />
    </div>
  );
};

export default withRouter(StepperWithRoutes);
