import { REQUEST_TYPES } from '.';

export const sendOTPRequest = body => ({
  type: `[API] OTP Send`,
  url: `/user/ui/sendOTP`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const verifyOTPRequest = ({ userEmail, code, headers }) => {
  return {
    type: `[API] OTP Verify`,
    url: `/user/ui/verify/otp/${userEmail || undefined}/${code || undefined}`,
    APIMethod: REQUEST_TYPES.GET,
    headers,
  };
};

export const verifyOTPRequestCreateAccount = ({
  userPhone,
  userEmail,
  code,
  headers,
}) => {
  const channelCode = userPhone ? 'SMS' : 'SMTP';
  const communicationAddress = userPhone || userEmail;
  const url = `/user/ui/verify/otp/${channelCode}/${communicationAddress}/${code}`;

  return {
    type: `[API] OTP Verify`,
    url,
    APIMethod: REQUEST_TYPES.GET,
    headers,
  };
};
