// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../../..';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../..';
import { Button } from 'texkit/dist/components/Buttons';
import { TxButton, TxButtonMode } from 'texkit-ui';

//Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';

import './reverify-speedbump-modal.scss';

const DPS_Reverify_SpeedBump_Modal = () => {
  const dispatch = useDispatch();

  const handleExitClick = () => {
    window.onunload = window.onbeforeunload = null;
    dispatch(closeModal('DPS_Reverify_SpeedBump_Modal'));
    dispatch(push('/dashboard'));
  };

  return (
    <Modal
      name="DPS_Reverify_SpeedBump_Modal"
      className="reverify-speedbump-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="Linking_Speedbump_Title" />
      </h2>
      <div className="modal-content">
        <p className="lead" style={{ maxWidth: '570px', margin: '0 auto' }}>
          <Trans file="Modals" id="Linking_Speedbump_Body" />
        </p>
      </div>
      <FormActions align="center">
        <FormActionsItem>
          <TxButton mode={TxButtonMode.Danger} onClick={handleExitClick}>
            <Trans file="Labels" id="YesLeave" />
          </TxButton>
        </FormActionsItem>
        <FormActionsItem>
          <Button
            onClick={() => dispatch(closeModal('DPS_Reverify_SpeedBump_Modal'))}
          >
            <Trans file="Labels" id="NoStay" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </Modal>
  );
};

export default DPS_Reverify_SpeedBump_Modal;
