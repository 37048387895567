// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './forgot-username-id-modal.scss';

// Components
import { Trans } from '../../..';
import {
  TxButton,
  TxButtonMode,
  TxModal,
  TxModalBody,
  TxModalFooter,
  TxModalHeader,
} from 'texkit-ui/components';

const ForgotUsernameIdModal = ({ closeModal, open }) => {
  return (
    <TxModal
      open={open}
      onClose={closeModal}
      showCloseBtn={true}
      name="DontHaveIdModal"
      className="forgot-username-id-modal"
    >
      <TxModalHeader>
        <p className="modal-heading">
          <Trans file="Modals" id="DontHaveId" fallback="" />
        </p>
      </TxModalHeader>
      <TxModalBody className="modal-body">
        <p className="modal-body">
          <Trans file="Modals" id="CallHelpDesk_forgot_username" fallback="" />
        </p>
      </TxModalBody>
      <TxModalFooter>
        <TxButton mode={TxButtonMode.Primary} onClick={closeModal}>
          <Trans file="Modals" id="Ok" fallback="" />
        </TxButton>
      </TxModalFooter>
    </TxModal>
  );
};

ForgotUsernameIdModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotUsernameIdModal;
