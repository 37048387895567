//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, ServiceIcon, Trans } from '../..';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Styles
import './server-error-modal.scss';

const ServerErrorModal = () => {
  const errorReference = (useSelector(selectModalData) || {})['REF'];
  const { navigateOnClose = true } = useSelector(selectModalData) || {};

  const dispatch = useDispatch();

  return (
    <Modal
      name="ServerErrorModal"
      className="server-error-modal"
      onClose={() => {
        if (navigateOnClose) {
          dispatch(push('/'));
        }
      }}
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Errors"
          id="ServerErrorModal_Title"
          fallabck="Houston, we have a problem."
        />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        <p className="modal-description">
          <Trans file="Errors" id="ServerErrorModal_Content" />
          <ServiceIcon type="external-link" />
        </p>
        {errorReference && (
          <p className="error-id">
            <Trans file="Labels" id="ErrorID" fallback="Error ID: " />{' '}
            <strong>{errorReference}</strong>
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ServerErrorModal;
