//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem } from '../../Forms';
import { getCentralOffset } from '../../../../lib/datetime';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';
import { APP_CONSTANTS } from '../../../../lib/data';
import { sendMessageToNativeApp } from '../../../../lib/helpers';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Selectors
import { selectIsAuthenticated } from '../../../../reducers/auth/authSelectors';

// Styles
import './service-down-error-modal.scss';

const ServiceDownModal = () => {
  const dispatch = useDispatch();
  const pathArr = window.location.pathname.split('/');

  const isAuthenticated = useSelector(selectIsAuthenticated)

  const { errorCode, options } = (useSelector(selectModalData) || {});

  const serviceType = options ? options.url ? options.url.split('/').pop() : '' : '';

  const socureServices = ['createAccount', 'forgotUsername', 'stepUp'].includes(serviceType);

  let agency;
  if (pathArr.includes('dmvrr') || pathArr.includes('dmv')) {
    agency = 'DMV';
  } else if (pathArr.includes('dps') || pathArr.includes('dpslr')) {
    agency = 'DPS';
  } else if (pathArr.includes('tdlr') || pathArr.includes('tdlrlr')) {
    agency = 'TDLR';
  } else if (socureServices) {
    agency = 'Agency'
  }

  const errorMap = {
    MGMW_EX_4003: agency,
  };

  let errorCodeKey;
  let errorCodeValue;
  if (errorCode && Array.isArray(errorCode)) {
    [errorCodeKey, errorCodeValue] = errorCode;
  } else {
    errorCodeKey = errorCode;
  }

  const serviceDownName = errorMap[errorCodeKey];
  return (
    <Modal
      name="ServiceDownModal"
      className="modal-sm server-error-modal"
      disableOnClickOutside
      disableCloseButton
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Errors"
          id="ServiceDownModal_Title"
          fallabck="Houston, we have a problem."
        />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        {serviceDownName && (
          <>
            <p className="service-down-description">
              {serviceDownName}{' '}
              <Trans
                file="Errors"
                id="ServiceDownModal_Content"
                fallback="services are unavailable at this time. Please try again after"
              />
              {` `}
              {errorCodeValue && (
                <>
                  {errorCodeValue}
                  {` `}
                  {getCentralOffset()}
                </>
              )}
            </p>
            <FormActions align="center">
              <FormActionsItem>
                <ButtonPrimary
                  onClick={() => {
                    dispatch(closeModal('ServiceDownModal'));
                    dispatch(replace(isAuthenticated ? '/dashboard' : '/'));
                    if (socureServices) {
                      sendMessageToNativeApp({
                        ...APP_CONSTANTS.SOCURE_DOWN_MSG,
                        timestamp: new Date().toISOString(),
                      });
                    }
                  }}
                >
                  <Trans
                    file="Labels"
                    id={isAuthenticated ? "BackToDashboard" : "BackToSignIn"}
                  />
                </ButtonPrimary>
              </FormActionsItem>
            </FormActions>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ServiceDownModal;
