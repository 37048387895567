// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { replace, push } from 'connected-react-router';
import { Formik } from 'formik';
import {
  TxButton,
  TxButtonMode,
  TxLoadingText,
  TxSelect,
} from 'texkit-ui/components';
import { TxFormGroup, TxFormActions } from 'texkit-ui/forms';
import { withTranslation } from 'react-i18next';

// Actions
import { getMyApps } from '../../../reducers/agency/agencyActions';
import {
  verifyAppLink,
  getPreLookupForLink,
  storeLinkServiceData,
} from '../../../reducers/agency/linkService/linkServiceActions';
import {
  selectAgencyByAppRouteParam,
  selectAppByRouteParam,
} from '../../../reducers/agency/agencySelectors';

// Components
import { LINK_SERVICES_STEPS } from '.';
import { LoadingDots, Trans, FormError } from '../../../components';

// Helpers
import { getAppDataBySlug } from '../../../lib/helpers';
import { preLookupValidationSchema } from '../../../lib/validation';

function applyFormik(
  name,
  { handleChange, handleBlur, values, touched, errors }
) {
  return {
    name,
    onChange: handleChange,
    onBlur: handleBlur,
    value: values[name],
    errorMessage:
      touched[name] && errors[name] ? (
        <Trans file="Errors" id={errors[name].id} />
      ) : null,
  };
}

class LinkServicePreLookup extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.checkPreLookup();
  }

  async checkPreLookup() {
    const appExists = !!getAppDataBySlug(this.props.match.params.appSlug);
    if (!appExists) return this.props.replace('/link');
    if (!this.props.agency.apps || !this.props.app)
      await this.props.getMyApps();

    const { app, replace, getPreLookupForLink } = this.props;

    getPreLookupForLink(app.appId).then(([{ body }]) => {
      const isStepUp = this.props.location.pathname.includes('stepup');
      if (body && body.status === 'NOT_APPLICABLE') {
        if (isStepUp) {
          return replace(
            `/link/${LINK_SERVICES_STEPS.START}/${app.appSlug}/stepup`
          );
        }
        return replace(`/link/${LINK_SERVICES_STEPS.LOOKUP}/${app.appSlug}`);
      }
      if (!body || !body.status === 'SUCCESS') {
        return replace('/link');
      }

      this.setState({ loading: false });
    });
  }

  handleSubmit(values) {
    const { app, storeLinkServiceData, link } = this.props;

    const program = link.preLookup.programs[values.program];
    const license = program.licenses[values.license];

    storeLinkServiceData({ selected: { program, license } });

    this.props.push(`/link/${LINK_SERVICES_STEPS.LOOKUP}/${app.appSlug}`);
  }

  render() {
    const { link, agency, app, replace } = this.props;

    if (this.state.loading || !link || !link.preLookup || !agency || !app) {
      return <LoadingDots />;
    }

    return (
      <>
        <h1 className="h2">
          <Trans
            file={agency.agencySlug === 'dpslr' ? 'dps' : agency.agencySlug}
            id={`${app.appSlug}_LinkService_LookupTitle`}
          />
        </h1>

        <Formik
          validationSchema={preLookupValidationSchema}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {formik => {
            const { values, isSubmitting, isValid, handleSubmit } = formik;

            return (
              <form onSubmit={handleSubmit}>
                <TxFormGroup>
                  <TxSelect
                    {...applyFormik('program', formik)}
                    required={true}
                    label={<Trans file="Labels" id="Board" />}
                    placeholder={this.props.t(
                      'PreLookup_ProgramEmptySelectOption',
                      { ns: 'Labels' }
                    )}
                    listItems={link.preLookup.programs.map((p, i) => ({
                      label: p.programName,
                      value: i,
                    }))}
                  />

                  <TxSelect
                    {...applyFormik('license', formik)}
                    required={true}
                    label={<Trans file="Labels" id="LicenseType" />}
                    placeholder={this.props.t(
                      'PreLookup_LicenseEmptySelectOption',
                      { ns: 'Labels' }
                    )}
                    listItems={
                      values.program
                        ? link.preLookup.programs[
                            Number(values.program)
                          ].licenses.map((l, i) => ({
                            label: l.licenseType,
                            value: i,
                          }))
                        : []
                    }
                    disabled={!values.program}
                  />
                </TxFormGroup>

                <FormError formikProps={formik} />

                <TxFormActions>
                  <TxButton type="submit" disabled={!isValid}>
                    {isSubmitting ? (
                      <TxLoadingText>
                        <Trans file="Labels" id="Loading" fallback="Loading" />
                      </TxLoadingText>
                    ) : (
                      <Trans file="Labels" id="Next" fallback="Next" />
                    )}
                  </TxButton>

                  <TxButton
                    mode={TxButtonMode.Secondary}
                    onClick={() => replace('/link')}
                    disabled={isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </TxButton>
                </TxFormActions>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  link: state.agency.link,
  agency: selectAgencyByAppRouteParam(state, props),
  app: selectAppByRouteParam(state, props),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      verifyAppLink,
      replace,
      push,
      getMyApps,
      getPreLookupForLink,
      storeLinkServiceData,
    },
    dispatch
  );

const ConnectedLinkServicePreLookup = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LinkServicePreLookup)
);

const RouteForConnectedLinkServicePreLookup = () => (
  <Route
    path="/link/prelookup/:appSlug"
    render={props => <ConnectedLinkServicePreLookup {...props} />}
  />
);

LinkServicePreLookup.propTypes = {
  link: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  verifyAppLink: PropTypes.func.isRequired,
  getMyApps: () =>
    // Promise
    PropTypes.objectOf({
      then: PropTypes.object,
      catch: PropTypes.object,
    }).isRequired,
  getPreLookupForLink: PropTypes.func.isRequired,
  storeLinkServiceData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func,
};

export default RouteForConnectedLinkServicePreLookup;
