//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './addressOutOfState.scss';

const AddressOutOfState = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="AddressOutOfState" className="dps-donations-modal">
      <h2 className="h1 modal-title">
        <Trans file="dps" id="cdl_Address_Out_of_State" />
      </h2>
      <p className="modal-description">
        <Trans file="dps" id="cdl_Address_Out_of_State_Desc" />
      </p>
      <span className="modal-button" />
      <ButtonPrimary
        className="modal-button"
        onClick={() => dispatch(closeModal('AddressOutOfState'))}
      >
        <Trans file="Labels" id="gotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ goBack }, dispatch);

export default connect(null, mapDispatchToProps)(AddressOutOfState);
