//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import Modal from '../Modal';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { TxButton, TxButtonMode } from 'texkit-ui';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './step-up-modal.scss';

const StepUpModal = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal('StepUpModal'));
  };

  return (
    <Modal name="StepUpModal" className="step-up-modal">
      <h2 className="step-up-modal-title">
        <Trans file="Modals" id="StepUp_Title" />
      </h2>
      <p className="step-up-modal-message">
        <Trans file="Modals" id="StepUp_Description_One" />
      </p>
      <p className="step-up-modal-message-bold">
        <Trans file="Modals" id="StepUp_Description_Two" />
      </p>
      <div className="step-up-modal-btns">
        <TxButton mode={TxButtonMode.Secondary} onClick={handleCloseModal}>
          <Trans file="Labels" id="Cancel" />
        </TxButton>
        <ButtonPrimary
          onClick={() => {
            handleCloseModal();
            dispatch(push('/reverify'));
          }}
        >
          <Trans file="Modals" id="StepUp_Confirmation" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default StepUpModal;
