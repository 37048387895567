// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, InputRadio, FormActions, FormActionsItem, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Helpers
import { melissaAddressFormatForDPS } from '../../../../lib/helpers';

// Styles
import './dps-verify-address-modal.scss';
import {
  states,
  counties,
  statesWithProvinces,
} from '../../../../lib/data/agencyData/DPS';

function DpsVerifyAddressModal(props) {
  const {
    onSuccess,
    closeModal,
    id = 'DpsVerifyAddressModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.addressOptions || !data.enteredAddress) return false;
  if (data.enteredAddress.state && data.enteredAddress.state.value)
    data.enteredAddress = {
      ...data.enteredAddress,
      state: data.enteredAddress.state.value,
    };

  const [selectedAddress, setSelectedAddress] = useState(
    data.addressOptions[0] || null
  );

  function handleChange(event) {
    const { name, value } = event.target;
    if (name && value) setSelectedAddress(JSON.parse(value));
  }

  function handleSuccess() {
    closeModal(id);
    onSuccess({
      ...selectedAddress,
      verifySkip: true,
      state:
        states.find(state => state.value === selectedAddress.state) ||
        statesWithProvinces.find(
          state => state.value === selectedAddress.state
        ),
      county: counties.find(
        county => county.label === selectedAddress.county.toUpperCase()
      ) || { label: 'OTHER', value: '999' },
    });
    setSelectedAddress(data.addressOptions[0] || null);
  }

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="dps-verify-address-modal"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="ConfirmCorrectAddress"
          fallback="I want to make sure I have your correct address"
        />
      </h2>
      <div className="modal-content">
        <div className="verify-address-modal-input-wrapper">
          <span className="verify-address-modal-label">
            {data.addressOptions.length > 1 ? (
              <Trans
                file="Labels"
                id="SuggestedAddresses"
                fallback="SuggestedAddresses"
              />
            ) : (
              <Trans
                file="Labels"
                id="SuggestedAddress"
                fallback="SuggestedAddress:"
              />
            )}
          </span>
          {data.addressOptions.map((address, index) => (
            <InputRadio
              key={`verifyAddress${index}`}
              id={`verifyAddress${index}`}
              name="verifyAddress"
              onChange={handleChange}
              label={melissaAddressFormatForDPS(address)}
              value={JSON.stringify(address)}
              checked={
                selectedAddress === null ||
                JSON.stringify(selectedAddress) === JSON.stringify(address)
              }
            />
          ))}
        </div>
        <hr></hr>
        <div className="verify-address-modal-input-wrapper">
          <span className="verify-address-modal-label">
            <Trans
              file="Modals"
              id="UseAddressEntered"
              fallback="Address you entered:"
            />
          </span>
          <InputRadio
            id="enteredAddress"
            name="verifyAddress"
            onChange={handleChange}
            label={melissaAddressFormatForDPS(data.enteredAddress)}
            value={JSON.stringify(data.enteredAddress)}
            // checked={
            //   JSON.stringify(selectedAddress) ===
            //   JSON.stringify(data.enteredAddress)
            // }
          />
        </div>

        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={handleSuccess}>
              <Trans
                file="Labels"
                id="UpdateAddress"
                fallback="Update address"
              />
            </ButtonPrimary>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

DpsVerifyAddressModal.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DpsVerifyAddressModal);
