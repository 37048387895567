// @flow

// Vendors
import React, { type Node } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Styles
import './grow-transition.scss';

// Types
type Props = {
  children: Node,
  className?: string,
};

const GrowTransition = ({ className, children, ...props }: Props) => (
  <CSSTransition
    {...props}
    classNames="grow-transition"
    timeout={300}
    unmountOnExit
  >
    <div className={classnames('grow-transition', className)}>{children}</div>
  </CSSTransition>
);

export default GrowTransition;
