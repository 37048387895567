//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '..';
import { Accordion } from '../../../../components';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './donations-modal.scss';

type Props = {
  donationOptionsInfo: Array,
  donationModalDesc: String,
  donationModalFile: String,
};

const DonationsModal = ({
  donationOptionsInfo = [],
  donationModalDesc,
  donationModalFile,
}: Props) => {
  const dispatch = useDispatch();
  return (
    <Modal name="DMVDonationsModal" className="dmv-donations-modal">
      <h2 className="h1 modal-title">
        <Trans file="dmv" id="dmvrr_DonationsModal_Title" />
      </h2>
      <p className="modal-description">
        <Trans
          file={donationModalFile ? donationModalFile : 'dmv'}
          id={
            donationModalDesc
              ? donationModalDesc
              : 'dmvrr_DonationsModal_Description'
          }
        />
      </p>
      {donationOptionsInfo.map(
        ({ titleId, file, contentId, display = true }) => {
          if (display) {
            return (
              <Accordion
                key={titleId}
                title={<Trans file={file} id={titleId} />}
                content={<Trans file={file} id={contentId} />}
              />
            );
          }
          return null;
        }
      )}
      <ButtonPrimary
        className="modal-close-button"
        onClick={() => dispatch(closeModal('DMVDonationsModal'))}
      >
        <Trans file="Labels" id="okGotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DonationsModal;
