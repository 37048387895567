// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// Actions
import { openModal } from '../../../../../reducers/modal/modalActions';

// Components
import { InputCheckboxInverse, Trans } from '../../..';

// Styles
import './input-accept-terms.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

const AcceptTermsLabel = () => (
  <>
    <Trans file="Labels" id="IAcceptThe" fallback="I Accept The" />{' '}
    <Link target={'_blank'} to="/terms-conditions">
      <Trans
        file="Common"
        id="TermsAndConditions"
        fallback="terms & conditions"
      />
    </Link>
  </>
);

AcceptTermsLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
};

/**
 * @visibleName Accept Terms
 */
const InputAcceptTerms = ({ className, formikProps, ...props }) => (
  <InputCheckboxInverse
    {...props}
    name="terms"
    label={<AcceptTermsLabel />}
    checked={formikProps.values.terms === true}
    formikProps={formikProps}
    className={classnames('input-accept-terms', className)}
  />
);

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openModal }, dispatch);

InputAcceptTerms.propTypes = {
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(InputAcceptTerms);
