//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { FormActions, FormActionsItem, Modal, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { TxButton, TxButtonMode } from 'texkit-ui/components';

// Styles
import './address-invalid-modal.scss';
import { states, counties } from '../../../../lib/data/agencyData/BON';

// Types
type Props = {
  id?: string,
  onSuccess: Function,
  onCancel?: Function,
};

function AddressInvalidModal({
  onSuccess,
  id = 'AddressInvalidModal',
  ...rest
}: Props) {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.modal);

  if (!data) {
    return false;
  }

  const {
    enteredAddress: { streetAddress, city, state, zipFive, county } = {},
  } = data;

  const handleConfirmClick = () => {
    dispatch(closeModal(id));

    onSuccess({
      ...data.enteredAddress,
      state:
        states.find(state => state.value === data.enteredAddress.state) || null,
      county:
        counties.find(county => county.label === data.enteredAddress.county) ||
        null,
    });
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className={'address-invalid-modal'}
    >
      <h1 className="h1 modal-title">
        <Trans file="Modals" id="AddressCouldNotValidateTitle" />
      </h1>
      <div className="modal-content">
        <div>
          <p className="modal-description">
            <Trans file="Modals" id="AddressCouldNotValidateBody" />
          </p>
          <h4 className="modal-address">
            {streetAddress} <br />
            {city}, {state} {zipFive} {county}
          </h4>
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={() => dispatch(closeModal(id))}>
              <Trans file="Labels" id="Edit " />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem>
            <TxButton
              mode={TxButtonMode.Secondary}
              onClick={handleConfirmClick}
            >
              <Trans file="Labels" id="Confirm " />
            </TxButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
}

export default AddressInvalidModal;
