import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSDKKeys } from '../reducers/auth/authActions';

const SOCURE_JS_SDK = 'https://sdk.dv.socure.us/latest/device-risk-sdk.js';

/**
 * Custom React hook for loading the Socure device risk script.
 * @param {string} key - The name of the Socure key to be used.
 * @returns {boolean} - Indicates whether the script has been loaded.
 */
const useLoadDeviceRiskScript = key => {
  const [scriptTagAttributes, setScriptTagAttributes] = useState(null);
  const { socureKeys } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchKeys() {
      try {
        await dispatch(getSDKKeys());
      } catch (error) {
        console.error(error);
      }
    }

    if (!Array.isArray(socureKeys) || !socureKeys.length) {
      fetchKeys();
    }
  }, []);

  useEffect(() => {
    if (!Array.isArray(socureKeys) || !socureKeys.length) {
      return;
    }

    const socureKey = socureKeys.find(({ name }) => name === key);

    if (!socureKey) {
      console.error(`No key found for ${key}`);
      return;
    };

    setScriptTagAttributes(socureKey);
  }, [socureKeys]);

  return scriptTagAttributes;
};

export default useLoadDeviceRiskScript;