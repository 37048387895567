//@flow

// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

// Actions
import {
  linkAppLink,
  linkServiceSuccess,
} from '../../../reducers/agency/linkService/linkServiceActions';

// Components
import { Trans } from '../../../components';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';
import { TxButton, TxButtonMode, TxLoadingText } from 'texkit-ui/components';
import { TxFormActions, TxFormGroup } from 'texkit-ui/forms';

// Helpers
import { capitalize, scrollToTop } from '../../../lib/helpers';

import './link-service-confirm.scss';

const TDLRLinkServiceConfirm = ({ licenses }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const handleConfirmClick = () => {
    setSubmitting(true);
    dispatch(linkAppLink())
      .then(() => dispatch(linkServiceSuccess()))
      .catch(() => {
        scrollToTop();
        setSubmitting(false);
      });
  };

  return (
    <div className="tdlr-link-service-confirm">
      <h1 className="h2">
        <Trans file="tdlr" id={`tdlrlr_LinkService_ConfirmTitleDefault`} />
      </h1>
      <p>
        <Trans
          file="tdlr"
          id={`tdlrlr_LinkService_ConfirmDescriptionDefault`}
        />
        {(licenses || []).length > 1 && (
          <Trans
            file="LinkService"
            id="MultipleLicensesConfirmLink"
            fallback="All supported licenses/agencies, including those below, will be linked at the same time."
          />
        )}
      </p>
      <div className="tdlr-link-service-confirm-summary">
        {(licenses || []).length > 0 &&
          licenses.map(license => (
            <React.Fragment key={license.licenseType}>
              <SummaryCard>
                <SummaryCard.List
                  items={[
                    {
                      className: 'word-break',
                      label: <Trans file="Labels" id="Name" fallback="Name" />,
                      value: license.name,
                    },
                    {
                      label: (
                        <Trans
                          file="Labels"
                          id="LicenseNumber"
                          fallback="License number"
                        />
                      ),
                      value: license.licenseNumber,
                    },
                    {
                      label: (
                        <Trans
                          file="Labels"
                          id="LicenseName"
                          fallback="License Name"
                        />
                      ),
                      value: capitalize(license.licenseType),
                    },
                  ]}
                />
              </SummaryCard>
            </React.Fragment>
          ))}
      </div>
      <TxFormGroup>
        <TxFormActions>
          <TxButton onClick={handleConfirmClick}>
            {submitting ? (
              <TxLoadingText>
                <Trans file="Labels" id="Linking" fallback="Linking" />
              </TxLoadingText>
            ) : (
              <Trans
                file="Labels"
                id="LinkToMyAccount"
                fallback="Link To My Account"
              />
            )}
          </TxButton>
          <TxButton
            mode={TxButtonMode.Secondary}
            onClick={() => dispatch(goBack())}
            disabled={submitting}
            className="min-width-btn"
          >
            <Trans file="Labels" id="Back" fallback="Back" />
          </TxButton>
        </TxFormActions>
      </TxFormGroup>
    </div>
  );
};

TDLRLinkServiceConfirm.propTypes = {
  licenses: PropTypes.array.isRequired,
};

export default TDLRLinkServiceConfirm;
