const validationMap = {
  'Company Name': 'dmvrr_ConfirmInsurance_formLabel_name',
  'Insurance agent Phone Number': 'dmvrr_ConfirmInsurance_formLabel_phone',
  'Policy start date': 'dmvrr_ConfirmInsurance_formLabel_start',
  'Policy Number': 'dmvrr_ConfirmInsurance_formLabel_policy',
  'Agent Name': 'dmvrr_ConfirmInsurance_formLabel_agent',
  'Business Name': 'dmvrr_BusinessName',
  'First Name': 'dmvrr_First_Name',
  'Last Name': 'dmvrr_Last_Name',
};

export default validationMap;
