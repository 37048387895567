//@flow

// Vendors
import React, { useRef } from 'react';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { Accordion } from '../../../../..';

// Styles
import '../dps-FAQ-modal.scss';

const DPSFAQModal = () => {
  const modal = useRef();
  const modalMobile = useRef();

  return (
    <>
      <Modal name="DPSFAQModal" className="faq-modal">
        <div className="modal-content-wrapper" ref={modalMobile}>
          <h2 className="h1 modal-title">
            <Trans file="dps" id="dps_FAQ_modal_title" />
          </h2>
          <p className="modal-description">
            <Trans file="dps" id="dps_FAQ_modal_desc" />
          </p>
          <div ref={modal} className="modal-inner-body">
            <p className="h4 modal-subHeading ">
              <Trans file="dps" id="dps_General" />
            </p>
            <Accordion
              key={'dps_General_FAQ_1'}
              title={<Trans file="dps" id="dps_General_FAQ_1" />}
              content={<Trans file="dps" id="dps_General_FAQ_1_Desc" />}
            />
            <Accordion
              key={'dps_FAQ_1'}
              title={<Trans file="dps" id="dps_FAQ_1" />}
              content={<Trans file="dps" id="dps_FAQ_1_Desc" />}
            />
            <Accordion
              key={'dps_General_FAQ_2'}
              title={<Trans file="dps" id="dps_General_FAQ_2" />}
              content={<Trans file="dps" id="dps_General_FAQ_2_Desc" />}
            />
            <Accordion
              key={'dps_FAQ_2'}
              title={<Trans file="dps" id="dps_FAQ_2" />}
              content={<Trans file="dps" id="dps_FAQ_2_Desc" />}
            />
            <Accordion
              key={'dps_REPLACEMENT_FAQ_2'}
              title={<Trans file="dps" id="dps_REPLACEMENT_FAQ_2" />}
              content={<Trans file="dps" id="dps_REPLACEMENT_FAQ_2_Desc" />}
            />
            <Accordion
              key={'dps_FAQ_4'}
              title={<Trans file="dps" id="dps_FAQ_4" />}
              content={<Trans file="dps" id="dps_FAQ_4_Desc" />}
            />
            <Accordion
              key={'dps_General_FAQ_3'}
              title={<Trans file="dps" id="dps_General_FAQ_3" />}
              content={<Trans file="dps" id="dps_General_FAQ_3_Desc" />}
            />

            <p className="h4 modal-subHeading ">
              <Trans file="dps" id="dps_ID_CARD_Renewal" />
            </p>
            <Accordion
              key={'dps_FAQ_3'}
              title={<Trans file="dps" id="dps_FAQ_3" />}
              content={<Trans file="dps" id="dps_FAQ_3_Desc" />}
            />
            <Accordion
              key={'dps_FAQ_12'}
              title={<Trans file="dps" id="dps_FAQ_12" />}
              content={<Trans file="dps" id="dps_FAQ_12_Desc" />}
            />
            <Accordion
              key={'dps_FAQ_5'}
              title={<Trans file="dps" id="dps_FAQ_5" />}
              content={<Trans file="dps" id="dps_FAQ_5_Desc" />}
            />
            <Accordion
              key={'dps_FAQ_6'}
              title={<Trans file="dps" id="dps_FAQ_6" />}
              content={<Trans file="dps" id="dps_FAQ_6_Desc" />}
            />
            <Accordion
              key={'dps_RENEWAL_FAQ_13'}
              title={<Trans file="dps" id="dps_RENEWAL_FAQ_13" />}
              content={<Trans file="dps" id="dps_RENEWAL_FAQ_13_Desc" />}
            />

            <p className="h4 modal-subHeading ">
              <Trans file="dps" id="dps_Card_Replacement" />
            </p>
            <Accordion
              key={'dps_Card_Replacement_FAQ_1'}
              title={<Trans file="dps" id="dps_Card_Replacement_FAQ_1" />}
              content={
                <Trans file="dps" id="dps_Card_Replacement_FAQ_1_Desc" />
              }
            />
            <Accordion
              key={'dps_RENEWAL_FAQ_7'}
              title={<Trans file="dps" id="dps_RENEWAL_FAQ_7" />}
              content={<Trans file="dps" id="dps_RENEWAL_FAQ_7_Desc" />}
            />
            <Accordion
              key={'dps_RENEWAL_FAQ_8'}
              title={<Trans file="dps" id="dps_RENEWAL_FAQ_8" />}
              content={<Trans file="dps" id="dps_RENEWAL_FAQ_8_Desc" />}
            />
            <Accordion
              key={'dps_Card_Replacement_FAQ_2'}
              title={<Trans file="dps" id="dps_Card_Replacement_FAQ_2" />}
              content={
                <Trans file="dps" id="dps_Card_Replacement_FAQ_2_Desc" />
              }
            />
            <Accordion
              key={'dps_REPLACEMENT_FAQ_7'}
              title={<Trans file="dps" id="dps_REPLACEMENT_FAQ_7" />}
              content={<Trans file="dps" id="dps_REPLACEMENT_FAQ_7_Desc" />}
            />
            <Accordion
              key={'dps_REPLACEMENT_FAQ_9'}
              title={<Trans file="dps" id="dps_REPLACEMENT_FAQ_9" />}
              content={<Trans file="dps" id="dps_REPLACEMENT_FAQ_9_Desc" />}
            />

            <p className="h4 modal-subHeading ">
              <Trans file="dps" id="dps_update_emergency_Contacts" />
            </p>
            <Accordion
              key={'dps_EMERGENCY_FAQ_1'}
              title={<Trans file="dps" id="dps_EMERGENCY_FAQ_1" />}
              content={<Trans file="dps" id="dps_EMERGENCY_FAQ_1_Desc" />}
            />
            <Accordion
              key={'dps_EMERGENCY_FAQ_2'}
              title={<Trans file="dps" id="dps_EMERGENCY_FAQ_2" />}
              content={<Trans file="dps" id="dps_EMERGENCY_FAQ_2_Desc" />}
            />
            <Accordion
              key={'dps_EMERGENCY_FAQ_3'}
              title={<Trans file="dps" id="dps_EMERGENCY_FAQ_3" />}
              content={<Trans file="dps" id="dps_EMERGENCY_FAQ_3_Desc" />}
            />
            <Accordion
              key={'dps_EMERGENCY_FAQ_4'}
              title={<Trans file="dps" id="dps_EMERGENCY_FAQ_4" />}
              content={<Trans file="dps" id="dps_EMERGENCY_FAQ_4_Desc" />}
            />
            <Accordion
              key={'dps_EMERGENCY_FAQ_5'}
              title={<Trans file="dps" id="dps_EMERGENCY_FAQ_5" />}
              content={<Trans file="dps" id="dps_EMERGENCY_FAQ_5_Desc" />}
            />

            <p className="h4 modal-subHeading ">
              <Trans file="dps" id="dps_REAL_ID" />
            </p>
            <Accordion
              key={'dps_FAQ_9'}
              title={<Trans file="dps" id="dps_FAQ_9" />}
              content={<Trans file="dps" id="dps_FAQ_9_Desc" />}
            />
            <Accordion
              key={'dps_REAL_ID_FAQ_1'}
              title={<Trans file="dps" id="dps_REAL_ID_FAQ_1" />}
              content={<Trans file="dps" id="dps_REAL_ID_FAQ_1_Desc" />}
            />
            <Accordion
              key={'dps_RENEWAL_FAQ_11'}
              title={<Trans file="dps" id="dps_RENEWAL_FAQ_11" />}
              content={<Trans file="dps" id="dps_RENEWAL_FAQ_11_Desc" />}
            />
            <Accordion
              key={'dps_REAL_ID_FAQ_2'}
              title={<Trans file="dps" id="dps_REAL_ID_FAQ_2" />}
              content={<Trans file="dps" id="dps_REAL_ID_FAQ_2_Desc" />}
            />
            <Accordion
              key={'dps_REAL_ID_FAQ_3'}
              title={<Trans file="dps" id="dps_REAL_ID_FAQ_3" />}
              content={<Trans file="dps" id="dps_REAL_ID_FAQ_3_Desc" />}
            />

            <p className="h4 modal-subHeading ">
              <Trans file="dps" id="dps_Fees" />
            </p>
            <Accordion
              key={'dps_RENEWAL_FAQ_12'}
              title={<Trans file="dps" id="dps_RENEWAL_FAQ_12" />}
              content={<Trans file="dps" id="dps_RENEWAL_FAQ_12_Desc" />}
            />
          </div>
        </div>
        <ButtonPrimary
          className="modal-button initial"
          onClick={() => {
            if (modal && modal.current) modal.current.scrollTop = 0;
          }}
          icon="more"
        >
          <Icon name="chevron-up" />
          <Trans file="Labels" id="BackToTop" />
        </ButtonPrimary>
        <ButtonPrimary
          className="modal-button-mobile initial"
          onClick={() => {
            if (modalMobile && modalMobile.current)
              modalMobile.current.scrollTop = 0;
          }}
          icon="more"
        >
          <Icon name="chevron-up" />
        </ButtonPrimary>
      </Modal>
    </>
  );
};

export default DPSFAQModal;
