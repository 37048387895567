import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Trans } from '../../..';
import {
  TxButton,
  TxButtonMode,
  TxModal,
  TxModalBody,
  TxModalFooter,
  TxModalHeader,
} from 'texkit-ui/components';

// Styles
import styles from './ModalOptInModal.module.scss';

const MobileOptInModal = ({ open, close }) => {
  return (
    <TxModal
      open={open}
      onClose={() => close()}
      showCloseBtn={true}
      focusOnFirst={true}
    >
      <TxModalHeader>
        <Trans
          file="Modals"
          id="MobileOptInHeader"
          fallback="Message and data rates may apply"
        />
      </TxModalHeader>
      <div className={styles.modalOptInModalBody}>
        <TxModalBody>
          <Trans
            file="Modals"
            id="MobileOptInBody"
            fallback="<p>By providing your phone number, you agree to receive text notifications from TxT. Message and data rates may apply. You can always update your notification preferences in the Notifications section of the Account Settings page.</p><p>We’ll use your mobile phone number to send you messages for things like:</p><ul><li>Confirming your identity during sign in</li><li>Resetting your password</li><li>Reminding you when it’s time to renew your licenses or registrations</li>"
          />
        </TxModalBody>
      </div>
      <TxModalFooter>
        <TxButton mode={TxButtonMode.Primary} onClick={() => close()}>
          <Trans file="Modals" id="Ok" fallback="OK, got it" />
        </TxButton>
      </TxModalFooter>
    </TxModal>
  );
};

MobileOptInModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  bodyRef: PropTypes.element,
};

export default MobileOptInModal;
