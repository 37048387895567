// Vendors
import * as yup from 'yup';

export const dps = {
  APP_RULES: {
    dpslr: {
      linkService: {
        licenseNumber: yup
          .string()
          .trim()
          .required({ id: 'Field_LicenseNumberRequired' }),
      },
    },
  },
};
