import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Trans, FormActions, FormActionsItem } from '../../../components';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

const DPSAlreadyLinkedSummary = () => {
  const dispatch = useDispatch();
  return (
    <>
      <h1 className="h2">
        <Trans file="dps" id="dpslr_LinkService_ConfirmTitleLinked" />
      </h1>
      <p>
        <Trans file="dps" id="dpslr_LinkService_ConfirmDescriptionLinked" />
      </p>
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(push('/dashboard'))}>
            <Trans file="Labels" id="ViewMyDashboard" />
          </ButtonPrimary>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

export default DPSAlreadyLinkedSummary;
