// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

// Components
import { Trans } from '../../../components';
import { TxButton } from 'texkit-ui/components';
import { TxFormActions, TxFormGroup } from 'texkit-ui/forms';

// Types
import { AppParamsPropTypes } from '../../../components/common/Forms/LinkServiceLookupForm/LinkServiceLookupForm';

const LinkServiceSuccess = ({ link }) => {
  if (!link || !link.agency || !link.app || !link.success)
    return <Redirect to="/link" />;

  const dispatch = useDispatch();

  const { agency, app, relinkData } = link;

  const relinkLabel = relinkData ? '_Relink' : '';

  return (
    <div className="tdlr-link-service-success">
      <TxFormGroup>
        <h1 className="h2">
          <Trans
            file={agency.agencySlug}
            id={`${app.appSlug}_LinkService_SuccessTitle${relinkLabel}`}
          />
        </h1>

        <p>
          <Trans
            file="LinkService"
            id="SuccessCustomContent_TDLR"
            fallback="When it's time to renew your license, we'll send you an email. Your TxT Dashboard will also show a reminder."
          />
        </p>
      </TxFormGroup>

      <TxFormActions>
        <TxButton
          onClick={() => dispatch(push(`/agency/${agency.agencySlug}`))}
        >
          <Trans file="Labels" id={`${app.appSlug}_ViewMyLicense`} />
        </TxButton>
      </TxFormActions>
    </div>
  );
};

LinkServiceSuccess.propTypes = {
  link: PropTypes.shape({
    agency: PropTypes.object,
    app: PropTypes.object,
    linkData: PropTypes.object,
    params: PropTypes.arrayOf(AppParamsPropTypes),
    paramsWithValues: PropTypes.arrayOf(PropTypes.object),
    preLookup: PropTypes.object,
    selected: PropTypes.object,
    success: PropTypes.object,
    relinkData: PropTypes.string,
  }).isRequired,
};

export default LinkServiceSuccess;
