//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '../../../../components/common/Modals';
import { Accordion, Trans } from '../../../../components';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './notification-settings-info.scss';

const NotificationSettingsInfoModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="NotificationSettingsInfoModal"
      className="notification-settings-info"
    >
      <div>
        <h2 className="h1 modal-header">
          <Trans
            file="dmv"
            id="NotificationSettingsInfo_Title"
            fallback="More about notification settings"
          />
        </h2>
        <p className="modal-description">
          <Trans
            file="dmv"
            id="NotificationSettingsInfo_content"
            fallback="<span style='text-align: left;'>This setting only applies to the TxDMV vehicle registration renewal notice that is sent to registered vehicle owners once per year. This notice can be used to register your vehicle by mail.</p><p style='text-align: left;'>Settings for reminders specific to your TxT account can be updated separately in your Account Settings. These reminders can be sent by text and/or email and include:</p><p style='text-align: left;padding-left:20px;'><ul><li style='padding-left:12px;'>Registration renewal reminders</li><li style='padding-left:12px;'>Messages about new features and services</li></ul></span>"
          />
        </p>
      </div>

      <ButtonPrimary
        className="modal-close-button "
        onClick={() => dispatch(closeModal('NotificationSettingsInfoModal'))}
      >
        <Trans file="Labels" id="okGotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

export default NotificationSettingsInfoModal;

// //@flow

// // Vendors
// import React from 'react';
// import { useDispatch, connect } from 'react-redux';

// // Components
// import { Modal } from '..';
// import Trans from '../../Trans/Trans';
// import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// // Actions
// import { closeModal } from '../../../../reducers/modal/modalActions';

// // Styles
// import './notification-settings-info.scss';
// type Props = {
//   modal: Object,
//   id: String,
// };
// const NotificationSettingsInfoModal = ({
//   id = 'VerifyAddressModal',
//   modal: { data: {} = {} } = {},
// }: Props) => {
//   const dispatch = useDispatch();

//   return (
//     <Modal
//       id={id}
//       name="NotificationSettingsInfoModal"
//       className="notification-settings-info"
//     >
//       <div>
//         <h2 className="h1 modal-header">
//           <Trans
//             file="dmv"
//             id="NotificationSettingsInfo_Title"
//             fallback="More about notification settings"
//           />
//         </h2>
//         <p className="modal-description">
//           <Trans
//             file="dmv"
//             id="NotificationSettingsInfo_contents"
//             fallback="<p>This setting only applies to the TxDMV vehicle registration renewal notice that is sent to registered vehicle owners once per year. This notice can be used to register your vehicle by mail.</p><p>Settings for reminders specific to your TxT account can be updated separately in your Account Settings. These reminders can be sent by text and/or email and include:</p><p><ul><li>Registration renewal reminders</li><li>Messages about new features and services</li></ul></p>"
//           />
//         </p>
//       </div>
//       <ButtonPrimary
//         className="modal-close-button"
//         onClick={() => {
//           dispatch(closeModal('NotificationSettingsInfoModal'));
//         }}
//       >
//         <Trans file="Labels" id="okgotit" />
//       </ButtonPrimary>
//     </Modal>
//   );
// };

// const mapStateToProps = state => ({
//   modal: state.modal,
// });
// export default connect(mapStateToProps, null)(NotificationSettingsInfoModal);
