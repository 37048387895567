import { to, cloneObject, formatAddressFromForm } from '../../../lib/helpers';

// Vendors
import { push } from 'connected-react-router';
import moment from 'moment';

// Requests
import {
  lookupAppLinkRequest,
  userVerifyAddressRequest,
  getAppFromId,
  verifyRenewalNotice,
  ernoUpdate,
} from '../../../backend/requests';

import {
  checkRequestFailure,
  handleRequestFailure,
  ERRORS_MAP,
} from '../../../lib/validation';

// Actions
import {
  APP_TRANSACTION_IDS,
  storeBasicInfo,
  clearAppData,
} from '../../app/appActions';
//import { selectDonationDetails } from './dmvSelectors';
import { openModal } from '../../modal/modalActions';

export const DMV_DMVRR_CLEAR_DATA = '[DMV] DMVRR - Clear Data';
export const DMV_RENEW_LICENSE = '[DMV] Renew License';
export const DMV_STORE_APP_PROFILE = '[DMV] Store App Profile';
export const DMV_STORE_LOOKUP = '[DMV] Store Lookup';
export const DMV_APPLINK_REQUEST = '[DMV] Applink verify';
export const DMV_STORE_FULL_PROFILE = '[DMV] Store Full Profile';
export const DMV_STORE_APP_INSURANCE = '[DMV] Store Insutance Information';
export const DMV_UPDATE_FEE_DETAILS = '[DMV] Update Fee Details';
export const DMV_UPDATE_DONATION_DETAILS = '[DMV] Update Donation Details';
export const DMV_UPDATE_VEHICLE_COLOR_DETAILS =
  '[DMV] Updating Vehicle Color Details';
export const DMV_UPDATE_ERNO = '[DMV] Update Erno';
export const DMV_UPDATE_IMC_NO = '[DMV] Update ImcNo';
export const DMV_UPDATE_MAILING_ADDRESS = '[DMV] Update Mailing Address';
export const DMV_UPDATE_RENEWAL_TERM = '[DMV] Update Renewal Term';

export const DMV_UPDATE_PAYMENT_WAS_MADE = 'TRUE';
export const DMV_UPDATE_IS_OUT_OF_STATE = 'DMV_UPDATE_IS_OUT_OF_STATE';
export const DMV_IS_VEHICLE_CERTIFIED = 'DMV_IS_VEHICLE_CERTIFIED';

export const DMV_MELISA_SUCCESS = 'SUCCESS';
export const DMV_MELISA_CONTINUE = 'CONTINUE';
export const DMV_MELISA_WARNING = 'WARNING';
export const DMV_MELISA_ERROR = 'ERROR';
export const DMV_MELISA_ADDRESS_TWO_MISSING = 'ADDRESS_TWO_MISSING';
export const DMV_MELISA_ADDRESS_TWO_INVALID = 'ADDRESS_TWO_INVALID';
export const DMV_MELISA_ADDRESS_NOT_VERIFIED = 'ADDRESS_NOT_VERIFIED';
export const DMV_MELISA_INVALID_ADDRESS = 'INVALID_ADDRESS';

export const renewDMVRegistration = data => {
  return async function(dispatch) {
    await dispatch({
      type: DMV_RENEW_LICENSE,
      payload: data,
    });
    await dispatch(storeBasicInfo(data));
    await dispatch(push('/dmvrr'));
  };
};

export const stickerTrackerDMV = data => {
  return async function(dispatch) {
    dispatch({
      type: DMV_STORE_APP_PROFILE,
      payload: data,
    });

    dispatch(push('/dmv/sticker-tracker'));
  };
};

export const editRenewalNotice = data => {
  return async function(dispatch) {
    dispatch({
      type: DMV_STORE_APP_PROFILE,
      payload: data,
    });

    dispatch(push('/dmv/edit-renewal-notice'));
  };
};

export const updateFeeDetails = payload => {
  return {
    type: DMV_UPDATE_FEE_DETAILS,
    payload,
  };
};

export const updateDonationDetails = payload => {
  return async (dispatch, getStore) => {
    const donations = getStore().dmv.appProfile.donationDetails;

    donations.forEach(donation => {
      if (payload[donation.feeCode.replace('-', '')] !== '')
        donation.feeAmount = payload[donation.feeCode.replace('-', '')];
    });

    return {
      type: DMV_UPDATE_DONATION_DETAILS,
      donations,
    };
  };
};

export const updateVehicleColors = payload => {
  return async (dispatch, getStore) => {
    const vehicle = getStore().dmv.appProfile.vehicle;

    if (
      vehicle.majorColorCode !== payload.majorColor.value ||
      vehicle.minorColorCode !== payload.minorColor.value
    ) {
      vehicle.majorColorCode = payload.majorColor.value;
      vehicle.minorColorCode = payload.minorColor.value;
      return {
        type: DMV_UPDATE_VEHICLE_COLOR_DETAILS,
        vehicle,
      };
    }
  };
};

export const updatePaymentWasMade = (status = false) => {
  return {
    type: DMV_UPDATE_PAYMENT_WAS_MADE,
    status: status,
  };
};

export const updateErno = payload => {
  return {
    type: DMV_UPDATE_ERNO,
    payload,
  };
};

export const agencyAppRequest = () => {
  return async function(dispatch) {
    const [res, err] = await to(dispatch(getAppFromId()));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  };
};

export const verify = payload => {
  return async function(dispatch, getStore) {
    const store = getStore();
    const registration = store.dmv.appProfile;
    const { userKey, appId, plateNumber, vin } = registration;
    const lastFourVin = vin.trim().slice(-4);
    const userKeys = [userKey];
    const { data } = payload;
    // populating values from the lookup call
    const dataWithVehicleInfo = data.map(v => {
      if (v.name === 'plateNumber') {
        return { ...v, value: plateNumber.trim() };
      } else if (v.name === 'lastFourOfVIN') {
        return { ...v, value: lastFourVin };
      }
    });

    const requestBody = {
      appId,
      userKeys,
      transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
      params: dataWithVehicleInfo,
    };
    const [res, err] = await to(dispatch(verifyRenewalNotice(requestBody)));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  };
};

export const verifyRenewalFlow = payload => {
  return async function(dispatch, getStore) {
    const store = getStore();
    const registration = store.dmv.selected;
    const { userKey, appId, plateNumber, vin } = registration;
    const lastFourVin = vin.trim().slice(-4);
    const userKeys = [userKey];
    const { data } = payload;
    // populating values from the lookup call
    const dataWithVehicleInfo = data.map(v => {
      if (v.name === 'plateNumber') {
        return { ...v, value: plateNumber.trim() };
      } else if (v.name === 'lastFourOfVIN') {
        return { ...v, value: lastFourVin };
      }
    });

    const requestBody = {
      appId,
      userKeys,
      transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
      params: dataWithVehicleInfo,
    };
    const [res, err] = await to(dispatch(verifyRenewalNotice(requestBody)));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  };
};

export const ernoUpdated = () => {
  return async function(dispatch, getStore) {
    const store = getStore();
    const registration = store.dmv.appProfile;
    const {
      userKey,
      appId,
      vin,
      plateNumber,
      documentNumber,
      agencyId,
    } = registration;
    const transactionInfo = {
      vehicleDetails: {
        documentNumber,
        vin,
        renewalNoticeCd: store.dmv.appProfile.ernoNoticeChoice,
        plateNumber,
        expirationDate: store.dmv.appProfile.expirationDate,
        vehicleMake: store.dmv.appProfile.vehicleMake,
        vehicleModelYear: store.dmv.appProfile.vehicleModelYear,
      },
    };

    const userKeys = [userKey];

    const requestBody = {
      appId,
      userKeys,
      transactionInfo,
      agencyId,
      transactionId: APP_TRANSACTION_IDS.ERNOUPDATE,
    };

    const [{ body }, err] = await to(dispatch(ernoUpdate(requestBody)));
    if (body.errors) return Promise.reject(body);
    if (err) return Promise.reject(err);
    return Promise.resolve(body);
  };
};

export const updateImcNo = payload => {
  return {
    type: DMV_UPDATE_IMC_NO,
    payload,
  };
};

export const updateMailingAddress = payload => {
  return {
    type: DMV_UPDATE_MAILING_ADDRESS,
    payload,
  };
};

export const storeInsuranceInfo = payload => {
  return async function(dispatch) {
    dispatch({
      type: DMV_STORE_APP_INSURANCE,
      payload,
    });
  };
};

export const storeAppProfile = payload => {
  return {
    type: DMV_STORE_APP_PROFILE,
    payload,
  };
};

export const storeLookUp = payload => {
  return {
    type: DMV_STORE_LOOKUP,
    payload,
  };
};

export const getStickerStatus = () => {
  return async (dispatch, getStore) => {
    const store = getStore();

    const registration = store.dmv.appProfile;
    const { userKey, appId } = registration;

    const userKeys = [userKey];

    const requestBody = {
      appId,
      userKeys,
      transactionId: APP_TRANSACTION_IDS.STICKER_TRACKER,
    };

    const [{ body }, err] = await to(
      dispatch(lookupAppLinkRequest(requestBody))
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(body.data);
  };
};

export const getEligibilityDMVRegistration = () => {
  return async function(dispatch, getStore) {
    const store = getStore();

    const registration = store.dmv.selected;
    const { userKey, appId, documentNumber } = registration;

    const userKeys = [userKey];

    const transactionInfo = {
      documentNumber,
    };

    const requestBody = {
      appId,
      userKeys,
      transactionId: APP_TRANSACTION_IDS.ELIGIBILITY_LOOKUP,
      transactionInfo,
    };

    const [{ body }, err] = await to(
      dispatch(lookupAppLinkRequest(requestBody))
    );
    dispatch(storeAppProfile(body.data));

    if (body.errors) return Promise.resolve(body);

    if (err) return Promise.reject(err);

    return Promise.resolve(body.data);
  };
};

export const getUpdatedDMVFeeDetails = (countyCode, renewalTerm) => {
  return async function(dispatch, getStore) {
    const store = getStore();

    const registration = store.dmv.selected;
    const { userKey, appId, documentNumber } = registration;

    const {
      selfCertifiedIndicator,
      inspectionResult,
      isOutOfState,
      isVehicleCertified,
    } = store.dmv.appProfile;

    const userKeys = [userKey];

    const transactionInfo = {
      documentNumber,
      countyCode,
      ...(renewalTerm &&
        renewalTerm.userSelectedTwoYearRenewal && { regAddlYrElgble: 'true' }),
      selfCertifiedIndicator:
        selfCertifiedIndicator === 'true' &&
        inspectionResult === 'UNKNOWN' &&
        isOutOfState === 'false' &&
        isVehicleCertified === 'false'
          ? 'false'
          : 'true',
    };

    const requestBody = {
      appId,
      userKeys,
      transactionId: APP_TRANSACTION_IDS.FEE_CALCULATION,
      transactionInfo,
    };

    const [{ body }, err] = await to(
      dispatch(lookupAppLinkRequest(requestBody))
    );

    dispatch(updateFeeDetails({ ...body.data, countyCode, renewalTerm }));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  };
};

export const clearDMVRRData = () => dispatch => {
  dispatch(clearAppData());
  return dispatch({ type: DMV_DMVRR_CLEAR_DATA });
};

export function getLookUpDataAndRenewDMVRegistration({
  appId,
  userKeys,
  transactionId,
}) {
  return async function(dispatch) {
    const requestBody = {
      appId,
      userKeys,
      transactionId,
    };
    const [{ body }, err] = await to(
      dispatch(lookupAppLinkRequest(requestBody))
    );

    dispatch(storeLookUp(body.data));
    dispatch(
      renewDMVRegistration({
        ...body.data.profiles[0].account[0],
        userKey: body.data.profiles[0].userKey,
        appId,
        agencyId: '9',
      })
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(body.data);
  };
}

export const verifyDMVMailingAddress = ({ values, formikActions }) => {
  return async dispatch => {
    const formValues = cloneObject(values);
    formValues.address = formatAddressFromForm(formValues);

    const [{ body, response }, err] = await to(
      dispatch(userVerifyAddressRequest(formValues.address))
    );

    if (checkRequestFailure({ response, body }))
      return handleRequestFailure(body, formikActions, ERRORS_MAP.ADDRESS);

    if (err) return Promise.reject(err);

    formikActions.setSubmitting(false);

    if (checkRequestFailure({ body, response })) {
      handleRequestFailure(body, formikActions, {
        'address.streetAddress': 'streetAddress',
        'address.addressLine2': 'addressLine2',
        'address.city': 'city',
        'address.state.value': 'state',
        'address.zipFive': 'zipFive',
      });

      return Promise.reject();
    }

    //A modified address was found...promp user which to use
    if (body.status === DMV_MELISA_CONTINUE) {
      dispatch(
        openModal('VerifyAddressModal/DMV', {
          enteredAddress: formValues.address,
          addressOptions: body.data,
        })
      );
    }

    //Action may be needed from user
    if (
      body.status === DMV_MELISA_WARNING ||
      body.status === DMV_MELISA_ERROR
    ) {
      switch (body.message) {
        case DMV_MELISA_ADDRESS_TWO_MISSING:
          dispatch(
            openModal('AddressTwoMissingModal/DMV', {
              enteredAddress: formValues.address,
              addressOptions: body.data,
            })
          );
          break;
        case DMV_MELISA_ADDRESS_TWO_INVALID:
          dispatch(
            openModal('AddressTwoIncorrectModal/DMV', {
              enteredAddress: formValues.address,
              addressOptions: body.data,
            })
          );
          break;
        case DMV_MELISA_ADDRESS_NOT_VERIFIED:
        case DMV_MELISA_INVALID_ADDRESS:
          dispatch(
            openModal('AddressCantConfirm/DMV', {
              enteredAddress: formValues.address,
            })
          );
          break;
        default:
          break;
      }
    }

    return Promise.resolve(body);
  };
};

export const formattDMVPayment = () => {
  return async function(dispatch, getStore) {
    const { dmv } = getStore();

    const { appProfile } = dmv;

    const registration = dmv.selected;
    const { userKey, appId, agencyId, vin } = registration;

    const userKeys = [userKey];

    const contDetails = dmv.appProfile.mailingAddress;

    const contactDetails = {
      fName: contDetails.firstName ? contDetails.firstName : '',
      mName: '',
      lName: contDetails.lastName ? contDetails.lastName : '',
      suffix: '',
      entity: contDetails.businessName ? contDetails.businessName : '',
      customerType: contDetails.addressType,
      userEmail: contDetails.email,
      phoneNum: contDetails.phoneNumber.replaceAll('-', ''),
      imcNo: dmv.appProfile.imcNo ? dmv.appProfile.imcNo : '',
      mailingAddress: {
        streetAddress: contDetails.streetAddress,
        addressLine2: contDetails.addressLine2 ? contDetails.addressLine2 : '',
        city: contDetails.city,
        zipFive: contDetails.zipFive,
        zipFour: contDetails.zipFour ? contDetails.zipFour : '',
        state: contDetails.state.value,
        deliveryPointCode: contDetails.deliveryPointCode
          ? contDetails.deliveryPointCode
          : '',
      },
    };

    const vehicleDetails = {
      documentNumber: dmv.appProfile.vehicle.documentNumber,
      expirationDate: dmv.appProfile.vehicle.expirationDate,
      majorColorCode: dmv.appProfile.vehicle.majorColorCode,
      minorColorCode: dmv.appProfile.vehicle.minorColorCode,
      vehicleMake: dmv.appProfile.vehicle.vehicleMake,
      vehicleModelYear: dmv.appProfile.vehicle.vehicleModelYear,
      vin,
      renewalNoticeCd: dmv.appProfile.ernoNoticeChoice,
    };

    //Need to send everytime, issue with DMV services
    const insuranceDetails = {
      companyName: dmv.appProfile.insuranceDetails
        ? dmv.appProfile.insuranceDetails.companyName
        : '',
      policyNumber: dmv.appProfile.insuranceDetails
        ? dmv.appProfile.insuranceDetails.policyNumber
        : '',
      agentName: dmv.appProfile.insuranceDetails
        ? dmv.appProfile.insuranceDetails.agentName
        : '',
      phoneNumber: dmv.appProfile.insuranceDetails
        ? dmv.appProfile.insuranceDetails.phoneNumber.replaceAll('-', '')
        : '',
      policyStartDate: dmv.appProfile.insuranceDetails
        ? moment(dmv.appProfile.insuranceDetails.policyStartDate).format(
            'YYYYMMDD'
          )
        : '', // yyyymmdd,
      policyExpirationDate: dmv.appProfile.insuranceDetails
        ? moment(dmv.appProfile.insuranceDetails.policyExpirationDate).format(
            'YYYYMMDD'
          )
        : '',
    };

    const transactionInfo = {
      feeDetails: dmv.appProfile.feeDetails,
      donationDetails: dmv.appProfile.donationDetails,
      vehicleDetails,
      contactDetails,
      insuranceDetails,
      countyCode: dmv.appProfile.countyCode,
      paymentToken: '',
      paymentAccountId: '',
      paymentAccountType: '',
      ...(appProfile.regAddlYrElgble === "true" && { regAddlYrElgble: appProfile.renewalTerm.userSelectedTwoYearRenewal })
    };

    return {
      userKeys,
      appId,
      agencyId,
      transactionId: APP_TRANSACTION_IDS.SUBMIT_RENEWAL_PAYMENT,
      transactionInfo,
    };
  };
};
