//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';

// Styles
import './check-modal.scss';

const CheckModal = () => (
  <Modal name="CheckModal" className="check-modal">
    <div className="modal-graphic">
      <img
        src={publicURL('check.svg')}
        alt={<Trans file="Modals" id="CheckModalImageAlt" />}
      />
    </div>
  </Modal>
);

export default CheckModal;
