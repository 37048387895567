import React from 'react';
import { Route } from 'react-router-dom';
import { push, goBack } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  Container,
  Form,
  InputGroupCode,
  OTPSentContent,
  Trans,
} from '../../../components';

import { TxButton, TxButtonMode } from 'texkit-ui/components';
import { LINK_SERVICES_STEPS } from '.';
import { cleanPhoneNumber } from '../../../lib/helpers';

import { storeLinkServiceData } from '../../../reducers/agency/linkService/linkServiceActions';
import { verifyOTPValidationSchema } from '../../../lib/validation';

const LinkServicePhoneVerify = () => {
  const dispatch = useDispatch();

  const phoneNum = useSelector(({ agency }) => {
    return (agency.link && agency.link.user && agency.link.user.phoneNum) || '';
  });

  const handleSubmit = async (values, formikActions) => {
    console.log('handleSubmit', values);
  };

  return (
    <Container className="link-services phone-verify">
      <h2 className="page-header">
        <Trans file="MFA" id="VerifyPhoneTitle" />
      </h2>
      <OTPSentContent
        channelCode="SMS"
        contactInfo={phoneNum}
        phoneFormat="(###) ###-####"
      />
      <Form
        formikConfig={{
          onSubmit: handleSubmit,
          validateOnBlur: false,
          initialValues: { code: '' },
          validationSchema: verifyOTPValidationSchema,
        }}
        render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
          <>
            <InputGroupCode formikProps={formikProps} channelCode={'SMS'} />
            <FormError />
            <FormActions>
              <FormActionsItem>
                <TxButton
                  mode={TxButtonMode.Primary}
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                  type="submit"
                >
                  <Trans file="Labels" id="Next" fallback="Next" />
                </TxButton>
              </FormActionsItem>
              <FormActionsItem>
                <TxButton
                  mode={TxButtonMode.Secondary}
                  onClick={() => dispatch(goBack())}
                  disabled={formikProps.isSubmitting}
                >
                  <Trans file="Labels" id="Back" />
                </TxButton>
              </FormActionsItem>
            </FormActions>
          </>
        )}
      />
    </Container>
  );
};

const RouteForLinkServicePhoneVerify = () => (
  <Route
    path={`/link/${LINK_SERVICES_STEPS.PHONE_VERIFY}/:appSlug`}
    render={props => <LinkServicePhoneVerify {...props} />}
  />
);

export default RouteForLinkServicePhoneVerify;
