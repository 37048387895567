//@flow

// Vendors
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { Loading } from '../../../components';

// Helpers
import { selectAgencyLink } from '../../../reducers/agency/agencySelectors';
import useRedirect from '../../../hooks/useRedirect';
import DPSAlreadyLinkedSummary from '../../../agencies/DPS/link-service/AlreadyLinkedSummary';
import TDLRAlreadyLinkedSummary from '../../../agencies/TDLR/link-service/AlreadyLinkedSummary';
import DMVAlreadyLinkedSummary from '../../../agencies/DMV/link-service/AlreadyLinkedSummary';

const LinkServiceAlreadyLinked = () => {
  const link = useSelector(selectAgencyLink);
  useRedirect(!link || !link.linkData, '/link');
  if (!link) return <Loading />;

  const {
    licenses,
    vin,
    vehicleMake,
    vehicleModelYear,
    registrationExpiry,
  } = link.linkData;
  const { agency } = link;

  switch (agency.agencySlug) {
    case 'tdlr':
      return <TDLRAlreadyLinkedSummary licenses={licenses} />;
    case 'dmv':
      return (
        <DMVAlreadyLinkedSummary
          {...{
            vin,
            vehicleMake,
            vehicleModelYear,
            registrationExpiry,
          }}
        />
      );
    case 'dps':
      return <DPSAlreadyLinkedSummary />;
    default:
      return <></>;
  }
};

export default LinkServiceAlreadyLinked;
