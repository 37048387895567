// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './service-icon-text.scss';

type Props = {
  text: String,
  type: string,
  isUnlinkVehicleModal?: Boolean,
  className?: string,
};

const ServiceIconText = ({ type, text, className, isUnlinkVehicleModal }: Props) => {
  if (!type) return null;

  return (
    <div className={classnames(`service-icon service-icon-${type}`, className)}>
      <div className="text-container">
        <div className={isUnlinkVehicleModal ? "modalText" : "text"}>{text}</div>
      </div>
    </div>
  );
};

export default ServiceIconText;