// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputPhone, Trans } from '../../..';

import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './input-group-phone.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Phone
 */
const InputGroupPhone = ({ className, formikProps, label, ...props }) => {
  return (
    <>
      <InputGroup
        {...props}
        className={classnames('input-group-phone', className)}
      >
        <InputPhone
          className="input-phone"
          name="phone"
          formikProps={formikProps}
          label={label}
        />

        <TextButton
          as="a"
          onClick={e => props.open(e)}
          className="sms-opt-in-text"
        >
          <Trans
            file="Account"
            id="SMSOptInText"
            fallback="Message and data rates may apply"
          />
        </TextButton>
      </InputGroup>
    </>
  );
};

InputGroupPhone.propTypes = {
  className: PropTypes.string,
  inverse: PropTypes.bool.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  open: PropTypes.func,
};

export default InputGroupPhone;
