// Helpers
import { to } from '../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
  ERRORS_MAP,
} from '../../lib/validation';
import { push } from 'connected-react-router';

import {
  ONBOARDING_SAVE_POINT_NAMES,
  ONBOARDING_STEP_ROUTES,
} from '../../pages/OnboardingPage/OnboardingSteps';

// Requests
import { userUpdateUserSecurityQuestionsRequest } from '../../backend/requests';

// Actions
import { sendOTP, OTP_MESSAGE_TYPES } from '../OTP/OTPActions';
import {
  updateProfileInfo,
  USER_UPDATE_PROFILE_INFO,
} from '../user/updateProfileInfo/updateProfileInfoActions';
import {
  USER_UPDATE_CONTACT_SEND_OTP_SUCCESS_RECEIVED,
  handleCheckIfEmailIsBeingUsed,
} from '../user/updateContact/updateContactActions';
import { USER_UPDATE_USER_SECURITY_QUESTIONS_SUCCESS_RECEIVED } from '../user/updateUserSecurityQuestions/updateUserSecurityQuestionsActions';

// Consts
export const ONBOARDING_RESET_STATE = '[ONBOARDING] Reset State';
export const ONBOARDING_SAVE_GOV_EMPLOYMENT_STATUS =
  '[ONBOARDING] Save Government Employment Status';
export const ONBOARDING_UPDATE = '[ONBOARDING] UPDATE';

// New generic stuff
//-----------------------------------------------------------------------------------
export function updateOnboarding(payload) {
  return {
    type: ONBOARDING_UPDATE,
    payload,
  };
}

export function onboardingSaveProgress(lastCompletedStep) {
  return async function(dispatch) {
    dispatch({
      type: USER_UPDATE_PROFILE_INFO,
      payload: { onBoardingProgressStatus: lastCompletedStep },
    });
    const [{ response, body }] = await to(
      dispatch(
        updateProfileInfo({ onBoardingProgressStatus: lastCompletedStep })
      )
    );
    if (!body || response.status !== 200) return;

    // Clear any sensitive data out of onboarding
    if (lastCompletedStep === ONBOARDING_SAVE_POINT_NAMES.COMPLETE) {
      dispatch({
        type: ONBOARDING_RESET_STATE,
      });
    }

    return Promise.resolve();
  };
}

export function onboardingSaveGovEmploymentStatus({ isGovernmentEmployee }) {
  return async function(dispatch) {
    dispatch(updateOnboarding({ isGovernmentEmployee }));
    await dispatch(
      onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.GOVERNMENT_STATUS)
    );
  };
}

// Original below this point...
//-----------------------------------------------------------------------------------

export const captureGovEmailSubmitRequested = ({
  values: { userEmail, locale },
  props: { workEmail, flow },
  formikActions,
}) => async dispatch => {
  dispatch(updateOnboarding({ governmentEmail: workEmail }));

  const [{ response, body }, err] = await to(
    dispatch(
      handleCheckIfEmailIsBeingUsed({
        typeCode: 'WE',
        newEmail: workEmail,
        formikActions,
      })
    )
  );

  if (response && body && !err) {
    const channelCode = 'SMTP';
    const typeCode = 'WE';

    const [{ body: OTPbody, response: OTPresponse }] = await to(
      dispatch(
        sendOTP({
          userEmail,
          contactInfo: workEmail,
          locale,
          messageType: OTP_MESSAGE_TYPES.UPDATE_WORK_EMAIL,
          channelCode,
          typeCode,
        })
      )
    );
    if (checkRequestFailure({ response: OTPresponse, body: OTPbody }))
      return handleRequestFailure(OTPbody, formikActions);

    const verifyContact = {
      channelCode,
      data: {
        contactGuid: '', // Sai Muni requested this be an empty string for consistency. In other situations we need to pass in the contact Guid.
        channelCode,
        contactInfo: workEmail,
        typeCode,
      },
    };

    dispatch({
      type: USER_UPDATE_CONTACT_SEND_OTP_SUCCESS_RECEIVED,
      payload: { verifyContact },
    });
    if (flow === 'onboarding')
      return dispatch(
        push(`/getting-started/${ONBOARDING_STEP_ROUTES.GOV_EMAIL_VERIFY}`)
      );
  }
};

export const onboardingSaveSecurityQuestions = ({
  values: { question1, question2, question3, answer1, answer2, answer3 },
  formikActions,
}) => async dispatch => {
  const questions = [
    { ...question1, answer: answer1, quesNum: '1' },
    { ...question2, answer: answer2, quesNum: '2' },
    { ...question3, answer: answer3, quesNum: '3' },
  ];

  dispatch(updateOnboarding({ questions }));

  const [{ response, body }] = await to(
    dispatch(userUpdateUserSecurityQuestionsRequest({ questions }))
  );

  if (checkRequestFailure({ response, body }))
    return handleRequestFailure(
      body,
      formikActions,
      ERRORS_MAP.UPDATE_SECURITY_QUESTIONS
    );

  dispatch({
    type: USER_UPDATE_USER_SECURITY_QUESTIONS_SUCCESS_RECEIVED,
    payload: questions,
  });
};

export const resetOnboardingState = () => async dispatch =>
  dispatch({ type: ONBOARDING_RESET_STATE });
