import { useState, useEffect } from 'react';
import { throttle } from '../lib/helpers';

function isMeaningfulEvent(event) {
  if (!event) {
    return false;
  }

  const {
    type,
    target: { nodeName = '' },
  } = event;
  return (
    type === 'scroll' ||
    (type === 'click' && (nodeName === 'BUTTON' || nodeName === 'A')) ||
    (type === 'focusin' && nodeName === 'INPUT') ||
    (type === 'keydown' && nodeName === 'INPUT')
  );
}

/**
 * Custom hook that tracks user activity and triggers a callback function
 * when a specified timeout period has elapsed without any activity.
 *
 * @param {Function} onTimeout - The callback function to be executed when the timeout occurs.
 * @param {number} [timeoutMinutes=1] - The timeout period in minutes. Defaults to 1 minute.
 */
const useUserActivityTimeout = (onTimeout, timeoutMinutes = 1) => {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    const handleActivity = throttle(event => {
      if (isMeaningfulEvent(event)) {
        setLastActivityTime(Date.now());
      }
    });

    window.addEventListener('scroll', handleActivity, true);
    window.addEventListener('click', handleActivity, true);
    window.addEventListener('focusin', handleActivity, true);
    window.addEventListener('keydown', handleActivity, true);

    return () => {
      window.removeEventListener('scroll', handleActivity, true);
      window.removeEventListener('click', handleActivity, true);
      window.removeEventListener('focusin', handleActivity, true);
      window.removeEventListener('keydown', handleActivity, true);
    };
  }, []);

  useEffect(() => {
    const timeLimit = timeoutMinutes * 60 * 1000;
    const timeoutId = setTimeout(() => {
      if (Date.now() - lastActivityTime > timeLimit) {
        onTimeout();
      }
    }, timeLimit);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [lastActivityTime, onTimeout, timeoutMinutes]);
};

export default useUserActivityTimeout;
