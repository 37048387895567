//@flow

// Vendors
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

// Helpers
import { ISO_FORMAT } from '../../../../lib/helpers';

// Components
import { Modal, Trans } from '../..';
import PaymentFrame, {
  PaymentFrameTypes,
} from '../../../Payment/PaymentFrame/PaymentFrame';
import { TxAlert, TxAlertType } from 'texkit-ui/components';

// Actions
import { getPaymentAccounts } from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';
import {
  pushMessageBanner,
  dismissMessageBanner,
} from '../../../../reducers/messageBanner/messageBannerActions';

import './edit-payment-account-modal.scss';

const EditPaymentAccountModal = () => {
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();

  const handleSuccess = () => {
    dispatch(getPaymentAccounts());
    dismissModal();
    setSuccessBanner();
  };

  const handleError = () => {
    setHasError(true);
  };

  const dismissModal = () => {
    dispatch(closeModal('EditPaymentAccountModal'));
    setHasError(false);
  };

  const setSuccessBanner = () => {
    const dt = DateTime.now();
    const startTime = dt.toFormat(ISO_FORMAT);
    const endTime = dt.plus({ minutes: 1 }).toFormat(ISO_FORMAT);
    const messageId = dt.toMillis();

    dispatch(
      pushMessageBanner([
        {
          messageId,
          textEnglish: '{"type": "Payment", "id": "CardUpdatedSuccess"}',
          textSpanish: '{"type": "Payment", "id": "CardUpdatedSuccess"}',
          startTime,
          endTime,
          priority: '4',
          author: 'USER',
          valid: true,
          dismissable: true,
          headerMessage: true,
        },
      ])
    );
    setTimeout(() => {
      dispatch(dismissMessageBanner(messageId));
    }, 60000);
  };

  return (
    <Modal
      name="EditPaymentAccountModal"
      className="edit-payment-account-modal"
      disableOnClickOutside="true"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Payment"
          id="UpdateCardInformation"
          fallback="Update card information"
        />
      </h2>
      <PaymentFrame
        file={PaymentFrameTypes.EDIT_ACCOUNT}
        onSuccess={handleSuccess}
        onCancel={dismissModal}
        onError={handleError}
      />
      {hasError && (
        <TxAlert
          className="iframe-error"
          type={TxAlertType.Warning}
          heading={<Trans file="Payment" id="UnableToUpdateCard" />}
        >
          <Trans file="Payment" id="TryAgain" />
        </TxAlert>
      )}
    </Modal>
  );
};

export default EditPaymentAccountModal;
