export const dps = {
  APP_ERRORS: {
    dpslr: {
      linkService: {
        licenseNumber: 'params.licenseNumber',
        dob: 'params.dob',
        last4ssn: 'params.last4ssn',
      },
    },
    dps: {
      linkService: {
        licenseNumber: 'params.licenseNumber',
        dob: 'params.dob',
        last4ssn: 'params.last4ssn',
      },
    },
  },
};
