const { DateTime } = require('luxon');

export const ISO_FORMAT = 'yyyy-MM-dd HH:mm:ss.u';

export const dayPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const convertISOtoLocaleString = date =>
  DateTime.fromISO(date).toFormat('MM/dd/yyyy');

export function historyDateToLocaleString(locale, date) {
  return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss.u')
    .setLocale(locale)
    .toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' });
}
