export default [
  { label: 'OTHER', value: '999' },
  { label: 'ANDERSON', value: '001' },
  { label: 'ANDREWS', value: '002' },
  { label: 'ANGELINA', value: '003' },
  { label: 'ARANSAS', value: '004' },
  { label: 'ARCHER', value: '005' },
  { label: 'ARMSTRONG', value: '006' },
  { label: 'ATASCOSA', value: '007' },
  { label: 'AUSTIN', value: '008' },
  { label: 'BAILEY', value: '009' },
  { label: 'BANDERA', value: '010' },
  { label: 'BASTROP', value: '011' },
  { label: 'BAYLOR', value: '012' },
  { label: 'BEE', value: '013' },
  { label: 'BELL', value: '014' },
  { label: 'BEXAR', value: '015' },
  { label: 'BLANCO', value: '016' },
  { label: 'BORDEN', value: '017' },
  { label: 'BOSQUE', value: '018' },
  { label: 'BOWIE', value: '019' },
  { label: 'BRAZORIA', value: '020' },
  { label: 'BRAZOS', value: '021' },
  { label: 'BREWSTER', value: '022' },
  { label: 'BRISCOE', value: '023' },
  { label: 'BROOKS', value: '024' },
  { label: 'BROWN', value: '025' },
  { label: 'BURLESON', value: '026' },
  { label: 'BURNET', value: '027' },
  { label: 'CALDWELL', value: '028' },
  { label: 'CALHOUN', value: '029' },
  { label: 'CALLAHAN', value: '030' },
  { label: 'CAMERON', value: '031' },
  { label: 'CAMP', value: '032' },
  { label: 'CARSON', value: '033' },
  { label: 'CASS', value: '034' },
  { label: 'CASTRO', value: '035' },
  { label: 'CHAMBERS', value: '036' },
  { label: 'CHEROKEE', value: '037' },
  { label: 'CHILDRESS', value: '038' },
  { label: 'CLAY', value: '039' },
  { label: 'COCHRAN', value: '040' },
  { label: 'COKE', value: '041' },
  { label: 'COLEMAN', value: '042' },
  { label: 'COLLIN', value: '043' },
  { label: 'COLLINGSWORTH', value: '044' },
  { label: 'COLORADO', value: '045' },
  { label: 'COMAL', value: '046' },
  { label: 'COMANCHE', value: '047' },
  { label: 'CONCHO', value: '048' },
  { label: 'COOKE', value: '049' },
  { label: 'CORYELL', value: '050' },
  { label: 'COTTLE', value: '051' },
  { label: 'CRANE', value: '052' },
  { label: 'CROCKETT', value: '053' },
  { label: 'CROSBY', value: '054' },
  { label: 'CULBERSON', value: '055' },
  { label: 'DALLAM', value: '056' },
  { label: 'DALLAS', value: '057' },
  { label: 'DAWSON', value: '058' },
  { label: 'DEAF SMITH', value: '059' },
  { label: 'DELTA', value: '060' },
  { label: 'DENTON', value: '061' },
  { label: 'DE WITT', value: '062' },
  { label: 'DICKENS', value: '063' },
  { label: 'DIMMIT', value: '064' },
  { label: 'DONLEY', value: '065' },
  { label: 'DUVAL', value: '066' },
  { label: 'EASTLAND', value: '067' },
  { label: 'ECTOR', value: '068' },
  { label: 'EDWARDS', value: '069' },
  { label: 'ELLIS', value: '070' },
  { label: 'EL PASO', value: '071' },
  { label: 'ERATH', value: '072' },
  { label: 'FALLS', value: '073' },
  { label: 'FANNIN', value: '074' },
  { label: 'FAYETTE', value: '075' },
  { label: 'FISHER', value: '076' },
  { label: 'FLOYD', value: '077' },
  { label: 'FOARD', value: '078' },
  { label: 'FORT BEND', value: '079' },
  { label: 'FRANKLIN', value: '080' },
  { label: 'FREESTONE', value: '081' },
  { label: 'FRIO', value: '082' },
  { label: 'GAINES', value: '083' },
  { label: 'GALVESTON', value: '084' },
  { label: 'GARZA', value: '085' },
  { label: 'GILLESPIE', value: '086' },
  { label: 'GLASSCOCK', value: '087' },
  { label: 'GOLIAD', value: '088' },
  { label: 'GONZALES', value: '089' },
  { label: 'GRAY', value: '090' },
  { label: 'GRAYSON', value: '091' },
  { label: 'GREGG', value: '092' },
  { label: 'GRIMES', value: '093' },
  { label: 'GUADALUPE', value: '094' },
  { label: 'HALE', value: '095' },
  { label: 'HALL', value: '096' },
  { label: 'HAMILTON', value: '097' },
  { label: 'HANSFORD', value: '098' },
  { label: 'HARDEMAN', value: '099' },
  { label: 'HARDIN', value: '100' },
  { label: 'HARRIS', value: '101' },
  { label: 'HARRISON', value: '102' },
  { label: 'HARTLEY', value: '103' },
  { label: 'HASKELL', value: '104' },
  { label: 'HAYS', value: '105' },
  { label: 'HEMPHILL', value: '106' },
  { label: 'HENDERSON', value: '107' },
  { label: 'HIDALGO', value: '108' },
  { label: 'HILL', value: '109' },
  { label: 'HOCKLEY', value: '110' },
  { label: 'HOOD', value: '111' },
  { label: 'HOPKINS', value: '112' },
  { label: 'HOUSTON', value: '113' },
  { label: 'HOWARD', value: '114' },
  { label: 'HUDSPETH', value: '115' },
  { label: 'HUNT', value: '116' },
  { label: 'HUTCHINSON', value: '117' },
  { label: 'IRION', value: '118' },
  { label: 'JACK', value: '119' },
  { label: 'JACKSON', value: '120' },
  { label: 'JASPER', value: '121' },
  { label: 'JEFF DAVIS', value: '122' },
  { label: 'JEFFERSON', value: '123' },
  { label: 'JIM HOGG', value: '124' },
  { label: 'JIM WELLS', value: '125' },
  { label: 'JOHNSON', value: '126' },
  { label: 'JONES', value: '127' },
  { label: 'KARNES', value: '128' },
  { label: 'KAUFMAN', value: '129' },
  { label: 'KENDALL', value: '130' },
  { label: 'KENEDY', value: '131' },
  { label: 'KENT', value: '132' },
  { label: 'KERR', value: '133' },
  { label: 'KIMBLE', value: '134' },
  { label: 'KING', value: '135' },
  { label: 'KINNEY', value: '136' },
  { label: 'KLEBERG', value: '137' },
  { label: 'KNOX', value: '138' },
  { label: 'LAMAR', value: '139' },
  { label: 'LAMB', value: '140' },
  { label: 'LAMPASAS', value: '141' },
  { label: 'LASALLE', value: '142' },
  { label: 'LAVACA', value: '143' },
  { label: 'LEE', value: '144' },
  { label: 'LEON', value: '145' },
  { label: 'LIBERTY', value: '146' },
  { label: 'LIMESTONE', value: '147' },
  { label: 'LIPSCOMB', value: '148' },
  { label: 'LIVE OAK', value: '149' },
  { label: 'LLANO', value: '150' },
  { label: 'LOVING', value: '151' },
  { label: 'LUBBOCK', value: '152' },
  { label: 'LYNN', value: '153' },
  { label: 'MADISON', value: '154' },
  { label: 'MARION', value: '155' },
  { label: 'MARTIN', value: '156' },
  { label: 'MASON', value: '157' },
  { label: 'MATAGORDA', value: '158' },
  { label: 'MAVERICK', value: '159' },
  { label: 'MCCULLOCH', value: '160' },
  { label: 'MCLENNAN', value: '161' },
  { label: 'MCMULLEN', value: '162' },
  { label: 'MEDINA', value: '163' },
  { label: 'MENARD', value: '164' },
  { label: 'MIDLAND', value: '165' },
  { label: 'MILAM', value: '166' },
  { label: 'MILLS', value: '167' },
  { label: 'MITCHELL', value: '168' },
  { label: 'MONTAGUE', value: '169' },
  { label: 'MONTGOMERY', value: '170' },
  { label: 'MOORE', value: '171' },
  { label: 'MORRIS', value: '172' },
  { label: 'MOTLEY', value: '172' },
  { label: 'NACOGDOCHES', value: '174' },
  { label: 'NAVARRO', value: '175' },
  { label: 'NEWTON', value: '176' },
  { label: 'NOLAN', value: '177' },
  { label: 'NUECES', value: '178' },
  { label: 'OCHILTREE', value: '179' },
  { label: 'OLDHAM', value: '180' },
  { label: 'ORANGE', value: '181' },
  { label: 'PALO PINTO', value: '182' },
  { label: 'PANOLA', value: '183' },
  { label: 'PARKER', value: '184' },
  { label: 'PARMER', value: '185' },
  { label: 'PECOS', value: '186' },
  { label: 'POLK', value: '187' },
  { label: 'POTTER', value: '188' },
  { label: 'PRESIDIO', value: '189' },
  { label: 'RAINS', value: '190' },
  { label: 'RANDALL', value: '191' },
  { label: 'REAGAN', value: '192' },
  { label: 'REAL', value: '193' },
  { label: 'RED RIVER', value: '194' },
  { label: 'REEVES', value: '195' },
  { label: 'REFUGIO', value: '196' },
  { label: 'ROBERTS', value: '197' },
  { label: 'ROBERTSON', value: '198' },
  { label: 'ROCKWALL', value: '199' },
  { label: 'RUNNELS', value: '200' },
  { label: 'RUSK', value: '201' },
  { label: 'SABINE', value: '202' },
  { label: 'SAN AUGUSTINE', value: '203' },
  { label: 'SAN JACINTO', value: '204' },
  { label: 'SAN PATRICIO', value: '205' },
  { label: 'SAN SABA', value: '206' },
  { label: 'SCHLEICHER', value: '207' },
  { label: 'SCURRY', value: '208' },
  { label: 'SHACKELFORD', value: '209' },
  { label: 'SHELBY', value: '210' },
  { label: 'SHERMAN', value: '211' },
  { label: 'SMITH', value: '212' },
  { label: 'SOMERVELL', value: '213' },
  { label: 'STARR', value: '214' },
  { label: 'STEPHENS', value: '215' },
  { label: 'STERLING', value: '216' },
  { label: 'STONEWALL', value: '217' },
  { label: 'SUTTON', value: '218' },
  { label: 'SWISHER', value: '219' },
  { label: 'TARRANT', value: '220' },
  { label: 'TAYLOR', value: '221' },
  { label: 'TERRELL', value: '222' },
  { label: 'TERRY', value: '223' },
  { label: 'THROCKMORTON', value: '224' },
  { label: 'TITUS', value: '225' },
  { label: 'TOM GREEN', value: '226' },
  { label: 'TRAVIS', value: '227' },
  { label: 'TRINITY', value: '228' },
  { label: 'TYLER', value: '229' },
  { label: 'UPSHUR', value: '230' },
  { label: 'UPTON', value: '231' },
  { label: 'UVALDE', value: '232' },
  { label: 'VAL VERDE', value: '233' },
  { label: 'VAN ZANDT', value: '234' },
  { label: 'VICTORIA', value: '235' },
  { label: 'WALKER', value: '236' },
  { label: 'WALLER', value: '237' },
  { label: 'WARD', value: '238' },
  { label: 'WASHINGTON', value: '239' },
  { label: 'WEBB', value: '240' },
  { label: 'WHARTON', value: '241' },
  { label: 'WHEELER', value: '242' },
  { label: 'WICHITA', value: '243' },
  { label: 'WILBARGER', value: '244' },
  { label: 'WILLACY', value: '245' },
  { label: 'WILLIAMSON', value: '246' },
  { label: 'WILSON', value: '247' },
  { label: 'WINKLER', value: '248' },
  { label: 'WISE', value: '249' },
  { label: 'WOOD', value: '250' },
  { label: 'YOAKUM', value: '251' },
  { label: 'YOUNG', value: '252' },
  { label: 'ZAPATA', value: '253' },
  { label: 'ZAVALA', value: '254' },
];
