//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Assets
import { ReactComponent as LogoSvg } from '../../../assets/logo.svg';

// Styles
import './logo.scss';

type Props = {
  className?: string,
};

/**
 * @visibleName Default
 */
const Logo = ({ className }: Props) => (
  <span className={classnames('logo', className)}>
    <LogoSvg role="presentation" />
  </span>
);

export default Logo;
