//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../..';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { InputCheckbox, InputTextarea } from '../../../../components';
import { TxAlert } from 'texkit-ui/components';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import { deactivateUser } from '../../../../reducers/user/deactivateUser/deactivateUserActions';

// Styles
import './deactivate-user-modal.scss';

// Types
type Props = {
  closeModal: Function,
  deactivateUser: Function,
};

const DeactivateUserModal = ({ closeModal, deactivateUser }: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [messageValue, setMessageValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [numberOfCharacters, setNumberOfCharacters] = useState('');
  const [payload, setPayload] = useState({ reasonCodes: [], comment: '' });
  const [deactivationFailed, setDeactivationFailed] = useState(false);

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    setCheckedItems(prevCheckedItems => ({
      ...prevCheckedItems,
      [name]: checked,
    }));

    const selectedReasons = Object.entries({
      ...checkedItems,
      [name]: checked,
    })
      .filter(([key, value]) => value && key !== '0')
      .map(([key]) => key);

    setPayload(prevPayload => ({
      ...prevPayload,
      reasonCodes: selectedReasons,
    }));
  };

  const handleTextareaChange = ({ target: { value } }) => {
    setMessageValue(value);
    setPayload(prevPayload => ({
      ...prevPayload,
      comment: value,
    }));
  };

  useEffect(() => {
    setNumberOfCharacters(messageValue.length);

    if (messageValue.length > 500) {
      setErrorMessage('Must be 500 characters or less.');
    } else {
      setErrorMessage('');
    }
  }, [messageValue]);

  const isAnyCheckboxSelected = Object.values(checkedItems).some(
    isChecked => isChecked
  );

  useEffect(() => {
    // Filter the checked items to get selected reasons (without numeric key property)
    const selectedReasons = Object.entries(checkedItems)
      .filter(([key, value]) => value && key !== '0')
      .map(([key]) => key);

    setPayload(prevPayload => ({
      ...prevPayload,
      reasonCodes: selectedReasons,
    }));
  }, [checkedItems]);

  return (
    <Modal
      name="DeactivateUserModal"
      className="deactivate-user-modal modal-md"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="DeactivateAccount"
          fallback="Are you sure you want to deactivate your account?"
        />
      </h2>
      <div className="modal-content">
        <h3>
          <Trans
            file="Modals"
            id="UnableToAccessAccountUntilReactivate"
            fallback="Deactivating your TxT account clears all your data except for your account name, email, and transaction history. You'll have to create a new account the next time you want to use TxT."
          />
        </h3>

        <div style={{ textAlign: 'left' }}>
          <p>
            <Trans
              file="Modals"
              id="AskingReasonForDeactivation"
              fallback="Help us improve by selecting the reason(s) you are deactivating your account:"
            />
          </p>
        </div>

        <InputCheckbox
          name="DU"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationOne"
              fallback="TxT is too difficult to use"
            />
          }
          checked={checkedItems['DU']}
          onChange={handleCheckboxChange}
        />

        <InputCheckbox
          name="PS"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationTwo"
              fallback="I have privacy or security concerns with having a TxT account"
            />
          }
          checked={checkedItems['PS']}
          onChange={handleCheckboxChange}
        />

        <InputCheckbox
          name="SF"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationThree"
              fallback="TxT doesn’t provide the services or features I need"
            />
          }
          checked={checkedItems['SF']}
          onChange={handleCheckboxChange}
        />

        <InputCheckbox
          name="TE"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationFour"
              fallback="I am receiving too many emails"
            />
          }
          checked={checkedItems['TE']}
          onChange={handleCheckboxChange}
        />

        <InputCheckbox
          name="NT"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationFive"
              fallback="I no longer live in the state of Texas"
            />
          }
          checked={checkedItems['NT']}
          onChange={handleCheckboxChange}
        />

        <InputCheckbox
          name="NU"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationSix"
              fallback="I no longer need to use TxT services"
            />
          }
          checked={checkedItems['NU']}
          onChange={handleCheckboxChange}
        />
        <InputCheckbox
          name="SI"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationSeven"
              fallback="Site is slow, crashing, not loading, etc."
            />
          }
          checked={checkedItems['SI']}
          onChange={handleCheckboxChange}
        />

        <InputCheckbox
          name="OT"
          label={
            <Trans
              file="Modals"
              id="ReasonForDeactivationEight"
              fallback="Other (please fill in reason below)"
            />
          }
          checked={checkedItems['OT']}
          onChange={handleCheckboxChange}
        />

        <InputTextarea
          name="messageDeactivation"
          label={
            <Trans
              file="Modals"
              id="MessageForDeactivation"
              fallback="Your feedback matters. Is there anything else you’d like us to know?"
            />
          }
          value={messageValue}
          onChange={handleTextareaChange}
          labelPosition="above"
          optional={true}
          showError={errorMessage ? errorMessage : false}
          errorMap={{ id: 'MaxValidationDeactivation' }}
          hideError={false}
        />

        <div style={{ textAlign: 'right' }}>
          <p className={numberOfCharacters > 500 ? 'text-danger' : ''}>
            {numberOfCharacters === 0 ? (
              <Trans
                file="Modals"
                id="CharactersAllowed"
                fallback="500 characters allowed"
              />
            ) : (
              <>
                {numberOfCharacters > 500 ? (
                  <Trans
                    file="Modals"
                    id="MaxReasonForDeactivation"
                    args={[
                      Math.abs(numberOfCharacters - 500),
                      Math.abs(numberOfCharacters - 500) === 1 ? '' : 's',
                    ]}
                    fallback="characters too long"
                  />
                ) : (
                  <Trans
                    file="Modals"
                    id="CharactersLeft"
                    args={[
                      Math.abs(numberOfCharacters - 500),
                      Math.abs(500 - numberOfCharacters) === 1 ? '' : 's',
                    ]}
                    fallback="characters left"
                  />
                )}
              </>
            )}
          </p>
        </div>
        {deactivationFailed && (
          <div>
            <TxAlert
              type="error"
              className="text-left"
              heading={
                <Trans
                  file="errors"
                  id="DeactivateAccount_Title"
                  fallback="There was a problem deactivating your account."
                />
              }
            >
              <Trans
                file="errors"
                id="DeactivateAccount_Desc"
                fallback="Please try again."
              />
            </TxAlert>
          </div>
        )}
        <FormActions align="center">
          <FormActionsItem>
            <TxButton
              onClick={async () => {
                setSubmitting(true);
                try {
                  await deactivateUser(payload);
                } catch (error) {
                  setDeactivationFailed(true);
                } finally {
                  setSubmitting(false);
                }
              }}
              isLoading={isSubmitting}
              mode={TxButtonMode.Danger}
              loadingContent={
                <Trans
                  file="Labels"
                  id="Deactivating"
                  fallback="Deactivating"
                />
              }
              disabled={!isAnyCheckboxSelected || messageValue.length > 500}
            >
              <Trans
                file="Labels"
                id="DeactivateAccount"
                fallback="Deactivate Account"
              />
            </TxButton>
          </FormActionsItem>
          <FormActionsItem>
            <TxButton
              onClick={() => {
                closeModal('DeactivateUserModal');
                setDeactivationFailed(false);
              }}
              disabled={isSubmitting}
              mode={TxButtonMode.Secondary}
            >
              <Trans file="Labels" id="No_Cancel" fallback="No, cancel" />
            </TxButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal, deactivateUser }, dispatch);

export default connect(null, mapDispatchToProps)(DeactivateUserModal);
