// @flow

import { REQUEST_TYPES } from '.';

export const reverifyVerifyStepUpUserRequest = (
  body: ReverifyUserRequest,
  extraOptions?: Object
): RequestOptions => ({
  url: '/user/reverify/stepUp',
  type: `[API] Reverify Step Up User`,
  body,
  APIMethod: REQUEST_TYPES.POST,
  extraOptions,
});

export const reverifyConfirmAndFinishRequest = (
  body: ReverifyConfirmAndFinishRequestRequest,
  extraOptions?: Object
): RequestOptions => ({
  url: '/user/reverify/confirm',
  type: `[API] Reverify User`,
  body,
  APIMethod: REQUEST_TYPES.POST,
  extraOptions,
});
