//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Assets
import { ReactComponent as LoadingSvg } from '../../../assets/loading.svg';

// Styles
import './loading.scss';

type Props = {
  className?: string,
};

/**
 * @visibleName Default
 */
const Loading = ({ className, ...props }: Props) => (
  <div {...props} className={classnames('loading-container', className)}>
    <LoadingSvg className="loading" aria-label="content loading" />
  </div>
);

export default Loading;
