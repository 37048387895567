// Vendors
import React from 'react';
import { replace } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TxModal,
  TxModalBody,
  TxModalHeader,
  TxModalFooter,
  TxButton,
} from 'texkit-ui/components';

// Components
import { Trans } from '../..';
import { closeModal } from '../../../../reducers/modal/modalActions';

export default connect(({ modal }) => ({ modal }), null)(VehicleNotFoundModal);

export function VehicleNotFoundModal({ modal }) {
  const dispatch = useDispatch();

  function onClick() {
    dispatch(closeModal('VehicleNotFoundModal'));
    dispatch(replace('/dashboard'));
  }

  return (
    <TxModal
      open={modal.activeModal === 'VehicleNotFoundModal'}
      className="vehicle-not-found-modal"
    >
      <TxModalHeader>
        <Trans file="Errors" id="VehicleNotFoundModal_Title" />
      </TxModalHeader>

      <TxModalBody contain={false}>
        <Trans file="Errors" id="VehicleNotFoundModal_Content" />
      </TxModalBody>

      <TxModalFooter>
        <TxButton onClick={onClick}>
          <Trans file="Labels" id="ViewMyDashboard" />
        </TxButton>
      </TxModalFooter>
    </TxModal>
  );
}

VehicleNotFoundModal.propTypes = {
  modal: PropTypes.object,
};
