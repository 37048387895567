import * as yup from 'yup';
import { commonRules } from '../rules';

export const capturePhoneSchema = yup.object().shape({
  phoneNum: yup.string().matches(commonRules.REGEX.PHONE_WITH_PARENS, {
    excludeEmptyString: true,
  }),
});

export const captureIdentityReverifychema = yup.object().shape({
  fName: commonRules.firstNameMin2Chars,
  lName: commonRules.lastNameMin2Chars,
  dlNumber: commonRules.driverLicense,
  auditNum: commonRules.auditNumber,
  dob: commonRules.dob_Reverify,
  ssn: commonRules.fullSSN,
});

export const homeAddressSchema = yup.object().shape({
  streetAddress: commonRules.streetAddress,
  addressLine2: commonRules.addressLine2,
  city: commonRules.city,
  state: commonRules.state,
  zipFive: commonRules.zipFive(),
});
