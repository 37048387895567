//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../../..';
import { ButtonPrimary, TextButton } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './account-exists-modal.scss';

type Props = {
  existingEmail: string,
  onAccept: Function,
  onReject: Function,
  closeModal: Function,
};

const AccountExistsModal = ({
  existingEmail,
  onAccept,
  onReject,
  closeModal,
}: Props) => {
  const modalName = 'AccountExistsModal';

  return (
    <Modal
      name={modalName}
      disableOnClickOutside="true"
      disableCloseButton="true"
      className="modal-sm account-exists-modal"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="EmailAlreadyRegistered"
          fallback="There’s already an account with this email."
        />
      </h2>
      <div className="modal-content">
        <div className="account-exists-modal-email">{existingEmail}</div>
        <FormActions align="center">
          <FormActionsItem fullWidth={true}>
            <ButtonPrimary
              onClick={() => {
                closeModal(modalName);
                onAccept(existingEmail);
              }}
            >
              <Trans file="Labels" id="SignIn" fallback="Sign In" />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem fullWidth={true}>
            <TextButton
              onClick={() => {
                closeModal(modalName);
                onReject();
              }}
            >
              <Trans
                file="Labels"
                id="CheckSpellingAndTryAgain"
                fallback="Check spelling and try again."
              />
            </TextButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(null, mapDispatchToProps)(AccountExistsModal);
