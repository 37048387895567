import {
  bodyHasError,
  setServerErrors,
  errorsWithOnlyCodes,
} from './serverErrors';
import { setObjectPropertyByPath } from '../helpers';
export { setServerErrors };
export { errorsWithOnlyCodes };
export { bodyHasError };
export { default as ERRORS_MAP } from './errorsMap';
export * from './schemas';

export function validate(values, schema) {
  const validationSchema = schema(values);

  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    const FIRST_ERROR = 0;
    if (error.inner) {
      return error.inner.reduce((errors, error) => {
        setObjectPropertyByPath(errors, error.path, error.errors[FIRST_ERROR]);
        return errors;
      }, {});
    }
  }
}

export const checkRequestFailure = ({ response, body }) =>
  !body || !!body.errors || !response || response.status !== 200;

export function handleRequestFailure(
  body,
  { setFormikState, setSubmitting },
  errorsMap = {}
) {
  if (body && setFormikState)
    setServerErrors({ body, setFormikState, errorsMap });
  if (setSubmitting) setSubmitting(false);
}
