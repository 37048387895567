//@flow

// Vendors
import React from 'react';
import { Duration } from 'luxon';

// Components
import { Modal, Trans } from '../..';

// Styles
import './vrr-timeout-modal.scss';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

type Props = {
  keepAlive: Function,
  onOpen: Function,
  onClose: Function,
  duration: Duration,
};

const VRRSessionWarningModal = ({
  keepAlive,
  onOpen,
  onClose,
  duration,
}: Props) => {
  return (
    <Modal
      name="VRRSessionWarningModal"
      className="vrr-timeout-warning"
      onOpen={onOpen}
      onClose={onClose}
      disableCloseButton={true}
      disableOnClickOutside={true}
      role="alert"
    >
      <h2 className="h2">
        <Trans file="dmv" id="dmvrr_SessionWarningTitle" />
      </h2>
      <div className="modal-content">
        <h2 className="h2 vrr-timeout-warning-timer">
          {duration.toFormat('m:ss')}
        </h2>
        <div>
          <Trans file="dmv" id="dmvrr_SessionWarningBody" />
        </div>
      </div>
      <ButtonPrimary className="modal-close-button" onClick={keepAlive}>
        <Trans file="Modals" id="StaySignedIn" />
      </ButtonPrimary>
    </Modal>
  );
};

export default VRRSessionWarningModal;
