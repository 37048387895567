// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '..';
import { Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './linking-guidance-modal.scss';

const LinkingGuidanceModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="LinkingGuidanceModal"
      className="linking-guidance-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="LinkingGuidanceModalTitle" />
      </h2>
      <Trans
        file="Modals"
        id="LinkingGuidanceModalBody"
      />
      <div className="modal-content">
        <ButtonPrimary
          className="modal-close-button"
          onClick={() => dispatch(closeModal('LinkingGuidanceModal'))}
        >
          <Trans file="Labels" id="okGotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default LinkingGuidanceModal;