// Helpers
import { to } from '../../../lib/helpers';
import { gaActions } from '../../gaActionTypes';

// Requests
import { userDeactivateUserRequest } from '../../../backend/requests';

// Actions
import { closeModal } from '../../modal/modalActions';
import { signOut } from '../../auth/authActions';

// Consts
export const USER_DEACTIVATE_USER_SUCCESS_RECEIVED = `[USER] ${gaActions.success} Deactivate User Success`;

export const deactivateUser = payload => {
  return async dispatch => {
    const [{ response, body }, err] = await to(
      dispatch(userDeactivateUserRequest(payload))
    );

    if (!body && !err) {
      return false;
    }

    if (response.status !== 200) {
      return Promise.reject({ response, body });
    }

    dispatch({ type: USER_DEACTIVATE_USER_SUCCESS_RECEIVED, payload: body });
    dispatch(closeModal('DeactivateUserModal'));
    dispatch(signOut());

    return Promise.resolve({ response, body });
  };
};
