import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSDKKeys } from '../reducers/auth/authActions';

const SOCURE_JS_SDK = 'https://sdk.dv.socure.us/latest/device-risk-sdk.js';

/**
 * Custom React hook for loading the Socure device risk script.
 * @param {string} key - The name of the Socure key to be used.
 * @returns {boolean} - Indicates whether the script has been loaded.
 */
const useScriptLoader = key => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { socureKeys } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchKeys() {
      try {
        await dispatch(getSDKKeys());
      } catch (error) {
        console.error(error);
      }
    }

    if (!Array.isArray(socureKeys) || !socureKeys.length) {
      fetchKeys();
    }
  }, []);

  useEffect(() => {
    if (!Array.isArray(socureKeys) || !socureKeys.length) {
      return;
    }

    const socureKey = socureKeys.find(({ name }) => name === key);
    if (!socureKey) {
      console.error(`No key found for ${key}`);
      return;
    }

    const script = document.createElement('script');
    script.src = SOCURE_JS_SDK;
    script.defer = true;
    script.setAttribute('data-public-key', socureKey.value);
    script.setAttribute('context', socureKey.name);
    script.onload = () => setIsScriptLoaded(true);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      delete window.SigmaDeviceManager;
    };
  }, [socureKeys]);

  return isScriptLoaded;
};

export default useScriptLoader;
