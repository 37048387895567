//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
// Components
import { Modal, FormActions, FormActionsItem } from '../..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './cancelUpdates.scss';

const CancelUpdates = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="CancelUpdates" className="dps-donations-modal">
      <h2 className="h1 modal-title">
        <Trans file="dps" id="dps_Cancel_Updates" />
      </h2>
      <p className="modal-description">
        <Trans file="dps" id="dps_Cancel_Updates_Desc" />
      </p>

      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(closeModal('CancelUpdates'))}>
            <Trans file="Labels" id="keepUpdating" />
          </ButtonPrimary>
        </FormActionsItem>{' '}
        <FormActionsItem>
          <Button
            onClick={() => {
              dispatch(closeModal('CancelUpdates'));
              dispatch(goBack());
            }}
          >
            <Trans file="Labels" id="yesCancel" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ goBack }, dispatch);

export default connect(null, mapDispatchToProps)(CancelUpdates);
