import * as yup from 'yup';
import ERRORS from '../errorCopy';
import { DateTime } from 'luxon';

export const REGEX = {
  ALPHANUMERIC: /^[\u00C0-\u00FF\w\-']+$/,
  NUMERIC: /^[0-9]+$/,
  // ATLEASTONENUMBER: /\d{1}$/,
  //ATLEASTONEUC: /^[?=.*[A-Z]]+$/,
  //ATLEASTONELC:(?=.*[a-z]),
  //ATLEASTONESPECIALCHAR:(?=.*\W]),
  ALPHANUMERIC_ALLOWED_SPECIAL_CHAR: /^[A-Za-z0-9#():;?,.&*/+'" ]*$/,
  STRONGPW: /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  CITY: /^[A-Za-z0-9#():;?,.&*/+'"\s-]*$/,
  ALPHABETICAL: /^[\u00C0-\u00FFa-zA-Z\s\-.,']+$/,
  STREET_ADDRESS_STARTING_CHARACTER: /^[A-Za-z0-9]/,
  STREET_ADDRESS_INVALID_CHARACTERS: /^[A-Za-z0-9#():;?,.&*/+'"\s-]*$/,
  STREET_ADDRESS_2_INVALID_CHARACTERS: /^[A-Za-z0-9#():;?,.&*/+'"\s]*$/,
  NOT_POST_BOX: /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
  ALPHANUMERIC_LINE_2: /^[a-zA-Z0-9#-/,\w+ ]*$/,
  ADDRESS_LINE2_A: /^[a-zA-Z0-9\s\\\-,'.:/#]*$/,
  EC_STREET_ADDRESS: /^[a-zA-Z0-9\s\\\-'./#]*$/,
  PHONE: /^\d{3}-\d{3}-\d{4}$/,
  PHONE_WITH_PARENS: /^\(\d{3}\) \d{3}-\d{4}$/,
  ZIP_FIVE: /^\d{5}$/,
  ZIP_FOUR: /^\d{4}$/,
  LAST_FOUR_SSN: /^\d{4}$/,
  SSN: /^\d{3}-\d{2}-\d{4}$/,
};

export const PHONE_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const SOCIAL_SECURITY_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const makeRequired = (rule, message) => rule.required(message);

export const selectInput = message =>
  yup.object().shape({
    value: yup.string().required(message || ERRORS.FIELDS.SELECT_OPTION),
  });

export const email = ({ required = true } = {}) => {
  const baseEmail = yup
    .string()
    .nullable()
    .email(ERRORS.FIELDS.EMAIL.INVALID);

  if (required) return baseEmail.required(ERRORS.FIELDS.EMAIL.REQUIRED);

  return baseEmail;
};

export const workEmailInUse = yup
  .string()
  .test(
    'work-email-in-use',
    'this email is already in use',
    val => val.substr(val.length - 4) === '.gov'
  );

export const emailMatching = ref =>
  yup
    .string()
    .oneOf([yup.ref(ref)], ERRORS.FIELDS.EMAIL_MATCHING.REQUIRED)
    .required(ERRORS.FIELDS.EMAIL_MATCHING.REQUIRED);

export const password = yup
  .string()
  .trim()
  .min(8, ERRORS.FIELDS.PASSWORD.MIN)
  .max(128, ERRORS.FIELDS.PASSWORD.MAX)
  .required(ERRORS.FIELDS.PASSWORD.REQUIRED);

export const strongPassword = yup
  .string()
  .trim()
  .min(8, ERRORS.FIELDS.PASSWORD.MIN)
  .max(128, ERRORS.FIELDS.PASSWORD.MAX)
  .matches(REGEX.STRONGPW, ERRORS.FIELDS.PWSTRENGTH)
  .required(ERRORS.FIELDS.NEW_PASSWORD.REQUIRED);

export const passwordMatching = ref =>
  yup
    .string()
    .oneOf([yup.ref(ref)], ERRORS.FIELDS.PASSWORD_MATCHING.MATCHES)
    .required(ERRORS.FIELDS.PASSWORD_MATCHING.REQUIRED);

export const newPassword = ref =>
  yup
    .string()
    .notOneOf([yup.ref(ref)], ERRORS.FIELDS.NEW_PASSWORD.NO_MATCH)
    .min(8, ERRORS.FIELDS.PASSWORD.MIN)
    .max(128, ERRORS.FIELDS.PASSWORD.MAX)
    .required(ERRORS.FIELDS.NEW_PASSWORD.REQUIRED);

export const newStrongPassword = ref =>
  yup
    .string()
    .notOneOf([yup.ref(ref)], ERRORS.FIELDS.NEW_PASSWORD.NO_MATCH)
    .min(8, ERRORS.FIELDS.PASSWORD.MIN)
    .max(128, ERRORS.FIELDS.PASSWORD.MAX)
    .matches(REGEX.STRONGPW, ERRORS.FIELDS.PWSTRENGTH)
    .required(ERRORS.FIELDS.NEW_PASSWORD.REQUIRED);
// .matches(REGEX.ATLEASTONESYMBOL, ERRORS.FIELDS.ATLEASTONESYMBOL)
// .matches(
//   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
//  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
//   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
// )
// https://stackoverflow.com/questions/1559751/regex-to-make-sure-that-the-string-contains-at-least-one-lower-case-char-upper

export const contactCode = yup
  .string()
  .min(6, ERRORS.FIELDS.CONTACT_CODE.MIN)
  .required(ERRORS.FIELDS.CONTACT_CODE.REQUIRED);

export const phone = ({ excludeEmptyString = false, required = true } = {}) => {
  const rule = yup.string().matches(REGEX.PHONE, {
    message: ERRORS.FIELDS.PHONE.VALID,
    excludeEmptyString,
  });

  return required ? rule.required(ERRORS.FIELDS.PHONE.VALID) : rule;
};

export const phoneType = yup
  .string()
  .oneOf(['SMS', 'TALK'], ERRORS.FIELDS.PHONE_TYPE.REQUIRED)
  .required(ERRORS.FIELDS.PHONE_TYPE.REQUIRED);

export const firstName = yup
  .string()
  .trim()
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.FIRST_NAME.REQUIRED);

export const firstNameMin2Chars = yup
  .string()
  .trim()
  .min(2, ERRORS.FIELDS.MIN)
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.FIRST_NAME.REQUIRED);

export const lastName = yup
  .string()
  .trim()
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.LAST_NAME.REQUIRED);

export const lastNameMin2Chars = yup
  .string()
  .trim()
  .min(2, ERRORS.FIELDS.MIN)
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.LAST_NAME.REQUIRED);

export const driverLicense = yup
  .string()
  .min(8, ERRORS.FIELDS.DRIVER_LICENSE.MIN)
  .required(ERRORS.FIELDS.NUMERIC_ONLY);

export const dlIDNumber = driverLicense;

export const auditNumber = yup
  .string()
  .min(11, ERRORS.FIELDS.AUDIT_NUMBER.MIN)
  .max(20, ERRORS.FIELDS.AUDIT_NUMBER.MIN)
  .required(ERRORS.FIELDS.AUDIT_NUMBER.REQUIRED);

export const date = ({ required, format = 'MM/dd/yyyy', min, max }) =>
  yup.mixed().test('date', ERRORS.FIELDS.DATE.FORMAT, function(value) {
    const { path, createError } = this;

    if (required && !value) return createError({ path, message: required });

    const dateEntered = DateTime.fromFormat(value, format);

    if (value && dateEntered.invalid)
      return createError({ path, message: ERRORS.FIELDS.DATE.FORMAT });

    if (min && min.date) {
      if (format === 'MM/yy') {
        const minDate = min.date.minus({ months: 1 });
        if (dateEntered.diff(minDate, 'months').values.months < 0)
          return createError({
            path,
            message: min.message || 'Date is too early.',
          });
      }

      const minDate = min.date.minus({ days: 1 });
      if (dateEntered.diff(minDate, 'days').values.days < 0)
        return createError({
          path,
          message: min.message || 'Date is too early.',
        });
    }

    if (max && max.date) {
      if (format === 'MM/yy') {
        const maxDate = max.date.plus({ months: 1 });
        if (dateEntered.diff(maxDate, 'months').values.months > 0)
          return createError({ path, message: ERRORS.FIELDS.DATE.PAST });
      }

      const maxDate = max.date;
      if (dateEntered.diff(maxDate, 'days').values.days > 0)
        return createError({ path, message: ERRORS.FIELDS.DATE.PAST });
    }

    if (min && typeof min.age === 'number') {
      const dateMin = DateTime.local().minus({ years: min.age });
      if (dateEntered.diff(dateMin, 'years').values.years > 0)
        return createError({
          path,
          message: min.message || 'Date is too early.',
        });
    }

    if (max && typeof max.age === 'number') {
      const dateMax = DateTime.local().minus({ years: max.age });
      if (dateEntered.diff(dateMax, 'years').values.years < 0)
        return createError({
          path,
          message: max.message || ERRORS.FIELDS.DATE.PAST,
        });
    }

    return true;
  });

export const dob = date({
  required: ERRORS.FIELDS.DOB.REQUIRED,
  min: { age: 16, message: ERRORS.FIELDS.DOB.MAX },
  max: { age: 120, message: ERRORS.FIELDS.DOB.MIN },
});

export const date_dlr = ({ required, format = 'MM/dd/yyyy', min, max }) =>
  yup.mixed().test('date', ERRORS.FIELDS.DATE.FORMAT, function(value) {
    const { path, createError } = this;

    if (required && !value) return createError({ path, message: required });

    const dateEntered = DateTime.fromFormat(value, format);
    if (value && dateEntered.invalid)
      return createError({ path, message: ERRORS.FIELDS.DATE.FORMAT });

    if (min && typeof min.age === 'number') {
      const dateMin = DateTime.local().minus({ years: min.age });
      const daysDiff = min.diffMax || 0;
      if (dateEntered.diff(dateMin, 'days').values.days > daysDiff)
        return createError({
          path,
          message: min.message || 'Date is too early.',
        });
    }

    if (max && typeof max.age === 'number') {
      const dateMax = DateTime.local().minus({ years: max.age });
      if (dateEntered.diff(dateMax, 'years').values.years < 0)
        return createError({
          path,
          message: max.message || ERRORS.FIELDS.DATE.PAST,
        });
    }
    return true;
  });

export const dob_dlr = date_dlr({
  required: ERRORS.FIELDS.DOB.REQUIRED,
  min: { age: 16, message: ERRORS.FIELDS.DOB.MAX },
  max: { age: 120, message: ERRORS.FIELDS.DOB.MIN },
});

export const dob_Reverify = date_dlr({
  required: ERRORS.FIELDS.DOB.REQUIRED,
  min: { age: 18, message: ERRORS.FIELDS.DOB.MAX_REVERIFY, diffMax: 31 },
  max: { age: 120, message: ERRORS.FIELDS.DOB.MIN },
});

export const creditcard = {
  name: yup
    .string()
    .trim()
    .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
    .required(ERRORS.FIELDS.CREDITCARD.NAME_ON_CARD.REQUIRED),
  expiration: () =>
    date({
      required: ERRORS.FIELDS.CREDITCARD.EXPIRATION.REQUIRED,
      format: 'MM/yy',
      min: {
        date: DateTime.local(),
        message: ERRORS.FIELDS.CREDITCARD.EXPIRATION.MIN,
      },
    }),
  number: yup
    .string()
    .min(12, ERRORS.FIELDS.CREDITCARD.NUMBER.VALID)
    .max(19, ERRORS.FIELDS.CREDITCARD.NUMBER.VALID)
    .required(ERRORS.FIELDS.CREDITCARD.NUMBER.REQUIRED),
  cvv: yup
    .string()
    .min(3, ERRORS.FIELDS.CREDITCARD.CVV.VALID)
    .max(4, ERRORS.FIELDS.CREDITCARD.CVV.VALID)
    .required(ERRORS.FIELDS.CREDITCARD.CVV.REQUIRED),
};

export const ach = {
  name: yup
    .string()
    .trim()
    .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
    .required(ERRORS.FIELDS.ACH.NAME_ON_CHECK.REQUIRED),
  routing: yup.string().required(ERRORS.FIELDS.ACH.ABA_ROUTING.REQUIRED),
  confirmRouting: ref =>
    yup
      .string()
      .oneOf([yup.ref(ref)], ERRORS.FIELDS.ACH.ABA_ROUTING.CONFIRM)
      .required(ERRORS.FIELDS.ACH.ABA_ROUTING.CONFIRM),

  account: yup.string().required(ERRORS.FIELDS.ACH.ACCOUNT_NUMBER.REQUIRED),
  confirmAccount: ref =>
    yup
      .string()
      .oneOf([yup.ref(ref)], ERRORS.FIELDS.ACH.ACCOUNT_NUMBER.CONFIRM)
      .required(ERRORS.FIELDS.ACH.ACCOUNT_NUMBER.CONFIRM),
};

// TODO: Resolve duplicates once we normalize the field name for lastFourSSN.
export const lastFourSSN = yup
  .string()
  .min(4, ERRORS.FIELDS.LAST_FOUR_SSN.MIN)
  .matches(REGEX.LAST_FOUR_SSN, ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED)
  .required(ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED);

export const ssn = lastFourSSN;

export const last4ssn = yup
  .string()
  .min(4, ERRORS.FIELDS.LAST_FOUR_SSN.MIN)
  .matches(REGEX.LAST_FOUR_SSN, ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED)
  .required(ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED);

export const fullSSN = yup
  .string()
  .min(11, ERRORS.FIELDS.LAST_FOUR_SSN.NINE_CHARACTERS)
  .matches(REGEX.SSN, ERRORS.FIELDS.LAST_FOUR_SSN.NINE_CHARACTERS)
  .required(ERRORS.FIELDS.LAST_FOUR_SSN.NINE_CHARACTERS);

export const requiredCheckbox = yup.boolean().oneOf([true], true);

export const streetAddress = yup
  .string()
  .trim()
  .nullable()
  .matches(
    REGEX.STREET_ADDRESS_STARTING_CHARACTER,
    ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.STARTING_CHARACTER
  )
  .matches(
    REGEX.STREET_ADDRESS_INVALID_CHARACTERS,
    ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.INVALID_CHARACTERS
  )
  .min(3, ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED)
  .required(ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED);

export const streetAddressNotPostBox = yup
  .string()
  .trim()
  .nullable()
  .matches(REGEX.NOT_POST_BOX, ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED)
  .min(3, ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED)
  .max(32, ERRORS.FIELDS.MAX_LENGTH(32))
  .required(ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED);

export const streetAddressForMail = yup
  .string()
  .nullable()
  .min(3, ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED)
  .max(32, ERRORS.FIELDS.MAX_LENGTH(32))
  .required(ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED);

export const addressLine2 = yup
  .string()
  .trim()
  .nullable()
  .matches(
    REGEX.ALPHANUMERIC_LINE_2,
    ERRORS.FIELDS.ADDRESS.ADDRESS_LINE_2.ALPHANUMERIC_LINE_2
  )
  // .min(1, ERRORS.FIELDS.ADDRESS.ADDRESS_LINE_2.REQUIRED)
  .max(32, ERRORS.FIELDS.MAX_LENGTH(32));

export const streetAddress2 = yup
  .string()
  .trim()
  .nullable()
  .matches(
    REGEX.STREET_ADDRESS_2_INVALID_CHARACTERS,
    ERRORS.FIELDS.ADDRESS.STREET_ADDRESS_2.INVALID_CHARACTERS
  )
  .min(0, ERRORS.FIELDS.ADDRESS.STREET_ADDRESS_2.REQUIRED)
  .max(30, ERRORS.FIELDS.MAX_LENGTH(30));

export const addressLine2_a = yup
  .string()
  .trim()
  .nullable()
  .matches(REGEX.ADDRESS_LINE2_A, ERRORS.FIELDS.ADDRESS.ADDRESS_LINE_2.INVALID)
  .max(40, ERRORS.FIELDS.MAX_LENGTH(40));

export const city = yup
  .string()
  .trim()
  .nullable()
  .matches(REGEX.CITY, ERRORS.FIELDS.CITY)
  .min(3, ERRORS.FIELDS.ADDRESS.CITY.REQUIRED)
  .max(19, ERRORS.FIELDS.MAX_LENGTH(19))
  .required(ERRORS.FIELDS.ADDRESS.CITY.REQUIRED);

export const state = yup.object().shape({
  value: yup.string().required(ERRORS.FIELDS.ADDRESS.STATE.REQUIRED),
});

export const ec_street_address = ({
  required = true,
  excludeEmptyString = false,
} = {}) => {
  const rule = yup
    .string()
    .nullable()
    .matches(REGEX.EC_STREET_ADDRESS, {
      message: ERRORS.FIELDS.EC_STREET_ADDRESS_ERROR_INFO,
      excludeEmptyString,
    });
  return required
    ? rule.required(ERRORS.FIELDS.EC_STREET_ADDRESS_ERROR_INFO)
    : rule;
};

export const zipFive = ({
  required = true,
  excludeEmptyString = false,
} = {}) => {
  const rule = yup
    .string()
    .nullable()
    .matches(REGEX.ZIP_FIVE, {
      message: ERRORS.FIELDS.ADDRESS.ZIP_FIVE.DIGITS,
    });
  return required
    ? rule.required(ERRORS.FIELDS.ADDRESS.ZIP_FIVE.REQUIRED)
    : rule;
};

export const zipFour = ({
  required = false,
  excludeEmptyString = true,
} = {}) => {
  const rule = yup
    .string()
    .nullable()
    .matches(REGEX.ZIP_FOUR, excludeEmptyString);
  return required ? rule : '';
};

export const county = yup.object().shape({
  value: yup.string().required(ERRORS.FIELDS.ADDRESS.COUNTY.REQUIRED),
});

export const mailCounty = ({ required = false } = {}) => {
  const rule = yup.string().nullable();
  return required ? rule.required(ERRORS.FIELDS.ADDRESS.COUNTY.REQUIRED) : '';
};

export const country = yup.object().shape({
  code: yup.string().required(ERRORS.FIELDS.ADDRESS.COUNTRY.REQUIRED),
});

export const securityQuestion = yup.object().shape({
  quid: yup
    .string()
    .required(ERRORS.FIELDS.SECURITY_QUESTIONS.QUESTION.REQUIRED),
});

export const identityQuestion = yup
  .string()
  .required(ERRORS.FIELDS.IDENTITY_QUESTION.REQUIRED);

export const securityAnswer = yup
  .string()
  .trim()
  .min(3, ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.MIN)
  .required(ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.REQUIRED);

export const min = number =>
  yup.string().min(number, ERRORS.FIELDS.MIN_LENGTH(number));

export const max = number =>
  yup.string().max(number, ERRORS.FIELDS.MAX_LENGTH(number));

export const MFAOption = yup
  .string()
  .required(ERRORS.FIELDS.MFA_OPTIONS.REQUIRED);

export const yesNoRadioGroup = yup
  .boolean()
  .nullable()
  .required(ERRORS.FIELDS.REQUIRED_YES_NO);

export const displayName = yup
  .string()
  .trim()
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .min(1, ERRORS.FIELDS.MIN_LENGTH(1))
  .max(40, ERRORS.FIELDS.MAX_LENGTH(40))
  .required(ERRORS.FIELDS.REQUIRED);
