import LinkServiceSelectAgency from './LinkServiceSelectAgency';
import LinkServicePreLookup from './LinkServicePreLookup';

import LinkServiceStart from './LinkServiceStart';
import LinkServicePhoneCapture from './LinkServicePhoneCapture';
import LinkServicePhoneVerify from './LinkServicePhoneVerify';
import LinkServiceIdentity from './LinkServiceIdentity';

import LinkServiceLookup from './LinkServiceLookup';
import LinkServiceConfirm from './LinkServiceConfirm';
import LinkServiceStrongPw from './LinkServiceSetStrongPassword';
import LinkServiceAlreadyLinked from './LinkServiceAlreadyLinked';
import LinkServiceIneligible from './LinkServiceIneligible';
import LinkServiceSuccess from './LinkServiceSuccess';
import DMVRRCanNotRenewOnline from '../../apps/DMV/DMVRR/DMVRRSteps/DMVRRCanNotRenewOnline/DMVRRCanNotRenewOnline';

export const LINK_SERVICES_STEPS = {
  SELECT_AGENCY: 'select-agency',
  PRELOOKUP: 'prelookup',
  START: 'start',
  PHONE_CAPTURE: 'phone-capture',
  PHONE_VERIFY: 'phone-verify',
  ID: 'Identity',
  LOOKUP: 'lookup',
  CONFIRM: 'confirm',
  STRONG_PASSWORD: 'strong-password',
  ALREADY_LINKED: 'already-linked',
  INELIGIBLE: 'ineligible',
  SUCCESS: 'success',
  CAN_NOT_RENEW_ONLINE: 'can-not-renew-online',
};

export const steps = [
  {
    id: LINK_SERVICES_STEPS.SELECT_AGENCY,
    component: LinkServiceSelectAgency,
    params: ['appSlug'],
  },
  {
    id: LINK_SERVICES_STEPS.CAN_NOT_RENEW_ONLINE,
    component: DMVRRCanNotRenewOnline,
  },
  {
    id: LINK_SERVICES_STEPS.PRELOOKUP,
    component: LinkServicePreLookup,
    params: ['appSlug'],
  },
  {
    id: LINK_SERVICES_STEPS.START,
    component: LinkServiceStart,
    params: ['appSlug'],
  },
  {
    id: LINK_SERVICES_STEPS.PHONE_CAPTURE,
    component: LinkServicePhoneCapture,
    params: ['appSlug'],
    step: 1,
  },
  {
    id: LINK_SERVICES_STEPS.PHONE_VERIFY,
    component: LinkServicePhoneVerify,
    params: ['appSlug'],
    step: 2,
  },
  {
    id: LINK_SERVICES_STEPS.ID,
    component: LinkServiceIdentity,
    params: ['appSlug'],
    step: 3,
  },
  {
    id: LINK_SERVICES_STEPS.LOOKUP,
    component: LinkServiceLookup,
    params: ['appSlug'],
  },
  {
    id: LINK_SERVICES_STEPS.CONFIRM,
    component: LinkServiceConfirm,
  },
  {
    id: LINK_SERVICES_STEPS.STRONG_PASSWORD,
    component: LinkServiceStrongPw,
    params: ['appSlug'],
  },
  {
    id: LINK_SERVICES_STEPS.ALREADY_LINKED,
    component: LinkServiceAlreadyLinked,
  },
  {
    id: LINK_SERVICES_STEPS.INELIGIBLE,
    component: LinkServiceIneligible,
  },
  {
    id: LINK_SERVICES_STEPS.SUCCESS,
    component: LinkServiceSuccess,
  },
];
