// @flow

import { REQUEST_TYPES } from '.';

export const createAccountAddUserRequest = (
  body: CreateAccountAddUserRequest
): RequestOptions => {
  const path = body.pii.address ? 'IDology' : 'TOAS';

  return {
    url: '/user/ui/addUser',
    type: `[API] Add User ${path}`,
    body,
    APIMethod: REQUEST_TYPES.POST,
  };
};

export const createAccountIDologyQuestionsAnsweredRequest = (
  body: CreateAccountAddUserRequest
): RequestOptions => ({
  url: '/user/ui/addUser/idology',
  type: `[API] Answered IDology Questions`,
  body,
  APIMethod: REQUEST_TYPES.POST,
});

export const createAccountVerifyUserRequest = (
  body: CreateAccountVerifyUserRequest
): RequestOptions => ({
  url: '/user/ui/verifyUser',
  type: `[API] Verify User`,
  body,
  APIMethod: REQUEST_TYPES.POST,
});

export const createAccountCreateUserRequest = (
  body: CreateAccountCreateUserRequest
): RequestOptions => ({
  url: '/user/ui/createUser',
  type: `[API] Create User`,
  body,
  APIMethod: REQUEST_TYPES.POST,
});
