//@flow

// Vendors
import React from 'react';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../..';
import Trans from '../../Trans/Trans';

//Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

import './dps-guid-mismatch-modal.scss';

const DpsGuidMismatchModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal name="DpsGuidMismatchModal" className="dps-guid-mismatch-modal">
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="Linking_GUID_Mismatch" />
      </h2>
      <div className="modal-content">
        <Trans file="Modals" id="Linking_GUID_Mismatch_Body" />
      </div>
      <ButtonPrimary
        className="modal-close-button "
        onClick={() => {
          dispatch(closeModal('DpsGuidMismatchModal'));
          dispatch(push('/account'));
        }}
      >
        <Trans file="Labels" id="AccountSettings" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DpsGuidMismatchModal;
