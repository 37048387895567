//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './loading-dots.scss';

type Props = {
  className?: string,
};

/**
 * @visibleName Dots
 */
const LoadingDots = ({ className, ...props }: Props) => (
  <div {...props} className={classnames('loading-dots-container', className)}>
    <div className="loading-dots">
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
);

export default LoadingDots;
