// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputText, Trans } from '../../../../common';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName First Name
 */
const InputFirstName = ({ className, ...props }: Props) => (
  <InputText
    {...props}
    maxLength="40"
    className={classnames('input-first-name', className)}
  />
);

InputFirstName.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  autoComplete: PropTypes.string,
};

InputFirstName.defaultProps = {
  label: <Trans file="Labels" id="FirstNameID" fallback="Legal first name" />,
  name: 'fName',
  autoComplete: 'fName',
};

export default InputFirstName;
