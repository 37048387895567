// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

// Components
import { Trans, InputNumeric } from '../common';

// Styles
import './fees-donations.scss';

// Types
import { formikInjectedPropsTypes } from '../../lib/validation/propTypes/formikPropTypes';
import ERRORS from '../../lib/validation/errorCopy';

const Fees = ({
  feeList,
  subTotalAmountDue = '74.75',
  showSubTotal = true,
  formikProps,
  setDonationValue,
}) => {
  const calculateSubTotal = () => {
    let totalDonationValue = 0;
    Object.keys(formikProps.values).forEach(donationOption => {
      if (!isNaN(parseFloat(formikProps.values[donationOption]))) {
        totalDonationValue =
          totalDonationValue + parseFloat(formikProps.values[donationOption]);
      }
    });
    //subtract the discount if applicable
    if (showSubTotal) {
      return (parseFloat(subTotalAmountDue) + totalDonationValue - 1).toFixed(
        2
      );
    }
    return (parseFloat(subTotalAmountDue) + totalDonationValue).toFixed(2);
  };
  function handleBlur(fee, e) {
    const value = !e.target.value
      ? 0
      : Number.parseFloat(e.target.value).toFixed(2);
    formikProps.setFieldValue(e.target.name, value);
    if (setDonationValue)
      setDonationValue(
        fee.type.id,
        value > 0 ? value : value,
        calculateSubTotal()
      );
    formikProps.handleBlur(e);
  }
  const list = feeList.map((fee, key) => {
    let feeType;
    if (fee.type.editable) {
      feeType = (
        <div className="value editable flex-item">
          <InputNumeric
            name={`${fee.type.id}`}
            formikProps={formikProps}
            placeholder="0.00"
            onBlur={e => handleBlur(fee, e)}
            autoComplete="off"
            onChange={e => {
              const newValue = isNaN(e.target.value / 100)
                ? e.target.defaultValue.replace('.', '')
                : e.target.value.replace('.', '');

              let dll = (newValue / 100).toFixed(2);

              formikProps.setFieldValue(e.target.name, dll.toString());
            }}
          />
        </div>
      );
    } else {
      feeType = <div className="value flex-item">{fee.value}</div>;
    }

    return (
      <li key={key} className="feeItem flex-row-wrap">
        <div className="key flex-item">{fee.label}</div>
        {feeType}
      </li>
    );
  });

  return (
    <ul className="feesContainer">
      {list}
      {showSubTotal && (
        <>
          <li key="subtotals" className="feeItem subtotals flex-row-wrap">
            <div className="key flex-item">
              <Trans file="Labels" id="subtotal" />,
            </div>
            <div className="value flex-item">{calculateSubTotal()}</div>
          </li>
          <li className="feeItem spacer"></li>
          <li key="discount" className="feeItem discount flex-row-wrap">
            <div className="key flex-item">
              <Trans file="Labels" id="onlineDiscount" />
            </div>
            <div className="value flex-item">-1.00</div>
          </li>
        </>
      )}
      <li key="total" className="feeItem total flex-row-wrap">
        <div className="key flex-item">
          <Trans file="Labels" id="total" />
        </div>
        <div className="value flex-item">${calculateSubTotal()}</div>
      </li>
    </ul>
  );
};

Fees.propTypes = {
  feeList: PropTypes.array.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  subTotalAmountDue: PropTypes.string.isRequired,
  showSubTotal: PropTypes.bool.isRequired,
  setDonationValue: PropTypes.func.isRequired,
};

export default Fees;
