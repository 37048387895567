import React from 'react';
import PropTypes from 'prop-types';

import { Container } from '../..';

import '../icon-container.scss';

const IconContainer = ({ children }) => (
  <Container className="icon-container">{children}</Container>
);

IconContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconContainer;
