//@flow

// Vendors
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TxAlert } from 'texkit-ui/components';

// Helpers
import { publicURL } from '../../../../lib/helpers';
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Components
import { Modal, Form, InputNumeric } from '../..';
import Trans from '../../Trans/Trans';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { verifyAuditNumberSchema } from '../../../../lib/validation';
import Label from '../../Forms/Label/Label';

//Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import { verifyAuditNumber } from '../../../../reducers/agency/agencyActions';

// Styles
import './audit-verification-modal.scss';

// Switch to adjusted SCSS if on iOS device
var userAgent = window.navigator.userAgent.toLowerCase(),
  ios = /iphone|ipod|ipad/.test(userAgent);

// Types
type Props = {
  closeModal: Function,
};

const AuditVerificationModal = ({ closeModal }: Props) => {
  const userKey = (useSelector(selectModalData) || {})['userKey'];
  const redirect = (useSelector(selectModalData) || {})['redirect'];
  const [alertMesage, setAlertMessage] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    dispatch(verifyAuditNumber({ auditNumber: values.auditNumber, userKey }))
      .then(response => {
        closeModal('AuditVerificationModal');
        if (typeof redirect === 'function') {
          redirect();
        }
      })
      .catch(err => {
        setAlertMessage(true);
      });
  };

  return (
    <Modal
      name="AuditVerificationModal"
      className={
        ios
          ? 'audit-verification-modal audit-verification-modal-ios'
          : 'audit-verification-modal'
      }
      onClose={() => setAlertMessage(false)}
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="Audit_Verification_Header"
          fallback="We need more information before you can take care of a driver license or ID transaction"
        />
      </h2>
      <div className="modal-content">
        <Trans
          file="Modals"
          id="Audit_Verification_Body"
          fallback="Please enter the DD/Audit number on your Texas driver license or ID to get started. If you don’t have this information, visit the <a href='https://www.dps.texas.gov/section/driver-license' target='_blank'>Texas Department of Public Safety website</a> for assistance."
        />
        <div className="modal-graphic">
          <img src={publicURL('audit_desktop.svg')} className="desktop-img" />
          <img src={publicURL('audit_mobile.svg')} className="mobile-img" />
        </div>
      </div>
      <Form
        formikConfig={{
          initialValues: {
            auditNumber: '',
          },
          validationSchema: verifyAuditNumberSchema,
          onSubmit: handleSubmit,
        }}
        render={({ FormActions, FormActionsItem, formikProps }) => (
          <>
            <Label className={'audit_label'}>
              {' '}
              <Trans
                file="Labels"
                id="AuditNumber"
                fallback="DD/Audit number"
              />
            </Label>
            <InputNumeric
              name="auditNumber"
              className="label-text"
              placeholder="0000000000000000000"
              mask={[...Array(20)].map(() => /\d/)}
              autoComplete="auditNumber"
              maxLength="20"
              formikProps={formikProps}
              onClick={() => setAlertMessage(false)}
            />

            {alertMesage && (
              <TxAlert
                type="error"
                className="verification-alert"
                heading={
                  <Trans
                    file="Modals"
                    id="Audit_Verification_Error_Header"
                    fallback="Verification failed"
                  />
                }
              >
                <Trans
                  file="Modals"
                  id="Audit_Verification_Error_Body"
                  fallback="Please make sure your information is correct and try again."
                />
              </TxAlert>
            )}

            <FormActions align="center">
              <FormActionsItem>
                <TxButton mode={TxButtonMode.Primary} type="submit">
                  <Trans file="Labels" id="Submit" fallback="Submit" />
                </TxButton>
              </FormActionsItem>
              <FormActionsItem>
                <TxButton
                  onClick={() => closeModal('AuditVerificationModal')}
                  mode={TxButtonMode.Secondary}
                  type="button"
                >
                  <Trans file="Labels" id="Cancel" fallback="Cancel" />
                </TxButton>
              </FormActionsItem>
            </FormActions>
          </>
        )}
      />
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(null, mapDispatchToProps)(AuditVerificationModal);
