// Vendors
import React from 'react';

import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { Modal, Trans } from '../..';
import { closeModal } from '../../../../reducers/modal/modalActions';

//Styles
import './payment-failure-modal.scss';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

export default connect(({ modal }) => ({ modal }), null)(PaymentFailureModal);

export function PaymentFailureModal({ modal }) {
  const dispatch = useDispatch();

  return (
    <Modal
      name="PaymentFailureModal"
      className="payment-failure-modal"
      disableCloseButton
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="Payment_Failure_Title"
        />
      </h2>
      <div className="modal-content">
        <Trans
          file="Modals"
          id="Payment_Failure_Body_1"
        />
      </div>
      <div className="modal-content">
        <Trans
          file="Modals"
          id="Payment_Failure_Body_2"
        />
      </div>
      <ButtonPrimary
        className="modal-close-button "
        onClick={() => {
          dispatch(closeModal('PaymentFailureModal'));
        }}
      >
        <Trans file="Modals" id="Ok" />
      </ButtonPrimary>
    </Modal>
  );
}

PaymentFailureModal.propTypes = {
  modal: PropTypes.object,
};