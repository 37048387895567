//@flow

// Vendors
import React, { useRef } from 'react';
import classnames from 'classnames';

// Hooks
import { useOnClickOutside } from '../../../hooks';

// Types
type Props = {
  className: string,
  disableOnClickOutside: boolean,
  onClickOutside: Function,
  children: Node,
};

const ModalContent = ({
  className,
  disableOnClickOutside,
  onClickOutside,
  children,
}: Props) => {
  const ref = useRef();
  useOnClickOutside({ ref, onClickOutside, disableOnClickOutside });

  return (
    <div ref={ref} className={classnames('modal', className)} role="dialog">
      {children}
    </div>
  );
};

export default ModalContent;
