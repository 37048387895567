//@flow

// Vendors
import React from 'react';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../..';
import Trans from '../../Trans/Trans';

//Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

const DpsLinkingLockoutModal = () => {
  const dispatch = useDispatch();

  const goToDashboard = () => dispatch(push('/dashboard'));

  return (
    <Modal
      name="DpsLinkingLockoutModal"
      onClose={goToDashboard}
      disableCloseButton
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="Linking_Lockout_Header"
          fallback="We weren't able to verify your information"
        />
      </h2>
      <div className="modal-content">
        <Trans
          file="Modals"
          id="Linking_Lockout_Body"
          fallback="There have been too many unsuccessful attempts to verify your information. To protect your personal information, you won't be able to try again for 24 hours."
        />
      </div>
      <ButtonPrimary
        className="modal-close-button "
        onClick={() => {
          dispatch(closeModal('DpsLinkingLockoutModal'));
          goToDashboard();
        }}
      >
        <Trans file="Modals" id="Ok" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DpsLinkingLockoutModal;
