//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';

// Styles
import './cvv-modal.scss';

const CVVModal = () => (
  <Modal name="CVVModal" className="cvv-modal">
    <div className="modal-graphic">
      <img
        src={publicURL('cvv.svg')}
        alt={<Trans file="Modals" id="CVVModalImageAlt" />}
      />
    </div>
  </Modal>
);

export default CVVModal;
