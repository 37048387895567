import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '../..';

const IconComponent = ({ Icon, textFile, textId, className }) => (
  <div className="icon-box">
    <Icon role="presentation" className={`icon-image ${className}`} />
    <div className="icon-box-description">
      <Trans file={textFile} id={textId} />
    </div>
  </div>
);

PropTypes.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  textFile: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default IconComponent;
