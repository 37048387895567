// @flow

// Vendors
import React, { type Node } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Styles
import './drawer-transition.scss';

// Types
type Props = {
  children: Node,
  className?: string,
};

const DrawerTransition = ({ className, ...props }: Props) => (
  <CSSTransition
    {...props}
    classNames={classnames('drawer-transition', className)}
    timeout={300}
    unmountOnExit
  />
);

export default DrawerTransition;
