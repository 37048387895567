// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputText, Trans } from '../../../../common';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Last Name
 */
const InputLastName = ({ className, ...props }) => (
  <InputText
    {...props}
    maxLength="40"
    className={classnames('input-last-name', className)}
  />
);

InputLastName.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  autoComplete: PropTypes.string,
  label: PropTypes.any,
};

InputLastName.defaultProps = {
  label: <Trans file="Labels" id="LastNameID" fallback="Legal last name" />,
  name: 'lName',
  autoComplete: 'lName',
};

export default InputLastName;
