/* eslint-disable react/display-name */
//@flow

// Vendors
import React, { lazy, Suspense } from 'react';
import { type ConnectedRouterProps } from 'connected-react-router';
import PAGE_TYPES from './pageTypes';

// Components
import { Loading } from '../components';

// Pages
import appPages from './apps';

const pageData = [
  ...appPages,
  {
    type: PAGE_TYPES.AUTH,
    name: 'AccountPage',
    component: lazy(() => import('./AccountPage/AccountPage')),
  },
  {
    type: PAGE_TYPES.AUTH,
    name: 'AgencyPage',
    component: lazy(() => import('./AgencyPage/AgencyPage')),
  },
  {
    type: PAGE_TYPES.PUBLIC,
    name: 'CreateAccountPage',
    component: lazy(() => import('./CreateAccountPage/CreateAccountPage')),
  },
  {
    type: PAGE_TYPES.PUBLIC,
    name: 'ForgotPasswordPage',
    component: lazy(() => import('./ForgotPasswordPage/ForgotPasswordPage')),
  },
  {
    type: PAGE_TYPES.PUBLIC,
    name: 'ForgotUsernamePage',
    component: lazy(() => import('./ForgotUsernamePage/ForgotUsernamePage')),
  },
  {
    type: PAGE_TYPES.AUTH,
    name: 'DashboardPage',
    component: lazy(() => import('./DashboardPage/DashboardPage')),
  },
  {
    type: PAGE_TYPES.AUTH,
    name: 'HistoryPage',
    component: lazy(() => import('./HistoryPage/HistoryPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'LinkServicePage',
    component: lazy(() => import('./LinkServicePage/LinkServicePage')),
  },
  {
    name: 'NoMatchPage',
    component: lazy(() => import('./NoMatchPage/NoMatchPage')),
  },
  {
    name: 'TermsConditionsPage',
    component: lazy(() => import('./TermsConditionsPage/TermsConditionsPage')),
  },
  {
    type: PAGE_TYPES.PUBLIC_BASIC,
    name: 'LoginPage',
    component: lazy(() => import('./LoginPage/LoginPage')),
  },
  {
    type: PAGE_TYPES.AUTH_BASIC,
    name: 'OnboardingPage',
    component: lazy(() => import('./OnboardingPage/OnboardingPage')),
  },
  {
    type: PAGE_TYPES.PUBLIC_BASIC,
    name: 'ComingSoonPage',
    component: lazy(() => import('./ComingSoonPage/ComingSoonPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'ConfirmCardPage',
    component: lazy(() => import('./ConfirmCardPage/ConfirmCardPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'EditRenewalNotice',
    component: lazy(() => import('./EditRenewalNotice/EditRenewalNotice')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'ReverifyPage',
    component: lazy(() => import('./ReverifyPage/ReverifyPage')),
  },
];

const getPages = (pageType: string) =>
  pageData
    .filter(page => !page.type || page.type === pageType)
    .reduce((acc, { name, component: PageComponent }) => {
      acc[name] = (props: ConnectedRouterProps) => (
        <Suspense fallback={<Loading style={{ position: 'relative' }} />}>
          <PageComponent {...props} />
        </Suspense>
      );
      return acc;
    }, {});

export default getPages;
