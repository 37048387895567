//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import MessageBanners from '../MessageBanner/MessageBanner';

// Actions
import { dismissMessageBanner } from '../../reducers/messageBanner/messageBannerActions';

// Selectors
import { selectSortedHeaderMessages } from '../../reducers/messageBanner/messageBannerSelectors';

const HeaderMessages = () => {
  const dispatch = useDispatch();
  const { headerMessages, selectedLanguage } = useSelector(state => ({
    headerMessages: selectSortedHeaderMessages(state),
    selectedLanguage: state.language.selected,
  }));

  const handleDismissMessage = messageId => {
    dispatch(dismissMessageBanner(messageId));
  };

  return (
    <>
      {headerMessages.length > 0 && (
        <MessageBanners
          messages={headerMessages}
          language={selectedLanguage}
          dismissable={true}
          handleClose={handleDismissMessage}
        />
      )}
    </>
  );
};

export default HeaderMessages;
