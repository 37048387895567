//@flow

// Vendors
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, InputRadio, FormActions, FormActionsItem, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Helpersid
import { formatAddressForVerifyAddressModal } from '../../../../lib/helpers';

// Styles
import './verify-address-modal.scss';
import { states, counties } from '../../../../lib/data/agencyData/BON';

// Types
type Props = {
  id?: string,
  onCancel?: Function,
  onSuccess: Function,
  modal: Object,
  closeModal: Function,
  disableCloseButton?: boolean,
  className?: string,
};

function VerifyAddressModal(props: Props) {
  const {
    className,
    onSuccess,
    closeModal,
    id = 'VerifyAddressModal',
    modal: { data },
    disableCloseButton,
    ...rest
  } = props;

  if (!data || !data.addressOptions || !data.enteredAddress) return false;
  if (data.enteredAddress.state && data.enteredAddress.state.value)
    data.enteredAddress = {
      ...data.enteredAddress,
      state: data.enteredAddress.state.value,
    };

  const [selectedAddress, setSelectedAddress] = useState(
    data.addressOptions[0] || null
  );

  function handleChange(event) {
    const { name, value } = event.target;
    if (name && value) setSelectedAddress(JSON.parse(value));
  }

  function handleSuccess() {
    closeModal(id);
    onSuccess({
      ...selectedAddress,
      verifySkip: true,
      state:
        states.find(state => state.value === selectedAddress.state) || null,
      county:
        counties.find(county => county.label === selectedAddress.county) ||
        null,
    });
    setSelectedAddress(data.addressOptions[0] || null);
  }

  let displayAddressTruncationMsg = false;
  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className={classnames('verify-address-modal', className)}
      disableCloseButton={!disableCloseButton ? false : true}
    >
      <h1 className="h1 modal-title">
        <Trans
          file="Modals"
          id="ConfirmCorrectAddress"
          fallback="I want to make sure I have your correct address"
        />
      </h1>
      <div className="modal-content">
        <div className="verify-address-modal-input-wrapper">
          <div className="verify-address-modal-label">
            <div className="address-label">
              {data.addressOptions.length > 1 ? (
                <Trans
                  file="Labels"
                  id={
                    /DMV/.test(id)
                      ? 'SuggestedAddresses_star'
                      : 'SuggestedAddresses'
                  }
                  fallback="SuggestedAddresses:"
                />
              ) : (
                <Trans
                  file="Labels"
                  id={
                    /DMV/.test(id)
                      ? 'SuggestedAddress_star'
                      : 'SuggestedAddress'
                  }
                  fallback="Suggested address:"
                />
              )}
            </div>
            <div>
              {data.addressOptions.map((address, index) => {
                if (/DMV/.test(id)) {
                  let isCity_moreThan19 = address.city.length > 19;
                  let isStreetAddress_moreThan30 =
                    address.streetAddress.length > 30;
                  let isAddressLine2_moreThan30 =
                    address.addressLine2.length > 30;
                  if (isCity_moreThan19) {
                    address.city = address.city.substr(0, 19);
                  }
                  if (isStreetAddress_moreThan30) {
                    address.streetAddress = address.streetAddress.substr(0, 30);
                  }
                  if (isAddressLine2_moreThan30) {
                    address.addressLine2 = address.addressLine2.substr(0, 30);
                  }
                  displayAddressTruncationMsg =
                    isCity_moreThan19 ||
                    isStreetAddress_moreThan30 ||
                    isAddressLine2_moreThan30;
                }
                return (
                  <InputRadio
                    key={`verifyAddress${index}`}
                    id={`verifyAddress${index}`}
                    name="verifyAddress"
                    onChange={handleChange}
                    label={formatAddressForVerifyAddressModal(address)}
                    value={JSON.stringify(address)}
                    checked={
                      selectedAddress === null ||
                      JSON.stringify(selectedAddress) ===
                        JSON.stringify(address)
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="verify-address-modal-input-wrapper">
          <div className="verify-address-modal-label">
            <div className="address-label">
              <Trans
                file="Modals"
                id={
                  /DMV/.test(id)
                    ? 'UseAddressEntered_star'
                    : 'UseAddressEntered'
                }
                fallback="Address you entered:"
              />
            </div>
            <div>
              <InputRadio
                id="enteredAddress"
                name="verifyAddress"
                onChange={handleChange}
                label={formatAddressForVerifyAddressModal(data.enteredAddress)}
                value={JSON.stringify(data.enteredAddress)}
              />
            </div>
          </div>
          {/DMV/.test(id) && displayAddressTruncationMsg && (
            <div className="address-truncation-msg">
              <Trans
                file="dmv"
                id="dmvrr_address_truncation_msg"
                fallback="*We shortened your address due to TxDMV requirements. Don't worry, this shouldn't affect the delivery of your sticker."
              />
            </div>
          )}
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={handleSuccess}>
              <Trans
                file="Labels"
                id="UpdateAddress"
                fallback="Update address"
              />
            </ButtonPrimary>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAddressModal);
