import OnboardingWelcome from './OnboardingWelcome/OnboardingWelcome';
import OnboardingGovEmploymentStatus from './OnboardingGovEmployment/OnboardingGovEmploymentStatus';
import OnboardingGovEmailCapture from './OnboardingGovEmployment/OnboardingGovEmailCapture';
import OnboardingGovEmailVerify from './OnboardingGovEmployment/OnboardingGovEmailVerify';
import OnboardingSecurityQuestions from './OnboardingSecurityQuestions/OnboardingSecurityQuestions';
import OnboardingSecurityQuestionsForm from './OnboardingSecurityQuestions/OnboardingSecurityQuestionsForm';
import OnboardingStartLinking from './OnboardingStartLinking/OnboardingStartLinking';

export const ONBOARDING_STEP_ROUTES = {
  WELCOME: 'welcome',
  GOV_EMPLOYMENT_STATUS: 'gov-employment-status',
  GOV_EMAIL_CAPTURE: 'gov-email-capture',
  GOV_EMAIL_VERIFY: 'gov-email-verify',
  SECURITY_QUESTIONS: 'security-questions',
  SECURITY_QUESTIONS_CAPTURE: 'security-questions-capture',
  START_LINKING: 'start-linking',
};

export const ONBOARDING_SAVE_POINT_NAMES = {
  GOVERNMENT_STATUS: 'GOVERNMENT_STATUS',
  SECURITY_QUESTIONS: 'SECURITY_QUESTIONS',
  PHONE_NUM_VALIDATION: 'PHONENUM_VALIDATION',
  COMPLETE: 'Y',
};

export const ONBOARDING_STEPS = [
  { id: ONBOARDING_STEP_ROUTES.WELCOME, component: OnboardingWelcome },
  {
    id: ONBOARDING_STEP_ROUTES.GOV_EMPLOYMENT_STATUS,
    component: OnboardingGovEmploymentStatus,
  },
  {
    id: ONBOARDING_STEP_ROUTES.GOV_EMAIL_CAPTURE,
    component: OnboardingGovEmailCapture,
  },
  {
    id: ONBOARDING_STEP_ROUTES.GOV_EMAIL_VERIFY,
    component: OnboardingGovEmailVerify,
  },
  {
    id: ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS,
    component: OnboardingSecurityQuestions,
  },
  {
    id: ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS_CAPTURE,
    component: OnboardingSecurityQuestionsForm,
  },
  {
    id: ONBOARDING_STEP_ROUTES.START_LINKING,
    component: OnboardingStartLinking,
  },
];
