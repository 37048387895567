//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './progress-bar.scss';

type Props = {
  className?: string,
  percentage?: number,
  fraction?: number,
};

/**
 * @visibleName Default
 */
const ProgressBar = ({ className, percentage, fraction }: Props) => {
  if (!percentage && fraction) percentage = Math.round(fraction * 100);
  if (!percentage) throw Error('Need to pass in a percentage or fraction.');

  return (
    <div className={classnames('progress-bar', className)}>
      <span
        className="progress-bar-indicator"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;
