//@flow

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SessionWarningModal from '../SessionWarningModal/SessionWarningModal';
import VRRSessionWarningModal from '../VRRSessionWarningModal/VRRSessionWarningModal';

const modalMap = {
  SessionWarningModal,
  VRRSessionWarningModal,
};

const WarningModalWrapper = ({ name = 'SessionWarningModal', ...props }) => {
  const Modal = modalMap[name];
  return <Modal {...props} />;
};

WarningModalWrapper.propTypes = {
  name: PropTypes.string.isRequired,
};

export default WarningModalWrapper;
