// @flow

export function formatPhoneNumber(phoneNumber: string, format: string) {
  if (phoneNumber.length < 1 || phoneNumber.includes('*')) {
    return phoneNumber;
  }

  const number = cleanPhoneNumber(phoneNumber);
  const phoneFormat = format || '###-###-####';
  if (phoneFormat === '###-###-####') {
    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
  }
  if (phoneFormat === '(###) ###-####') {
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
  }
}

export function cleanPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/[^\d.]/g, '');
}
