//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './stepper-dots.scss';

// Types
type Props = {
  steps: Array<Object>,
  activeStep: string,
  className?: string,
};

const StepperDots = ({
  steps = [],
  activeStep,
  className,
  ...props
}: Props) => {
  if (!steps.length) return null;

  return (
    <div {...props} className={classnames('stepper-dots', className)}>
      {steps.map(({ id: stepId }) => {
        return (
          <div
            key={stepId}
            className={`stepper-dots-step ${
              activeStep === stepId ? 'isActive' : ''
            }`}
          />
        );
      })}
    </div>
  );
};

export default StepperDots;
