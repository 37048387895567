import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Trans } from '../../..';
import {
  TxButton,
  TxButtonMode,
  TxModal,
  TxModalBody,
  TxModalFooter,
  TxModalHeader,
} from 'texkit-ui/components';

//Helpers
import { publicURL } from '../../../../lib/helpers';

// Styles
import styles from './DMVSpecialCharModal.module.scss';

const DMVSpecialCharModal = ({ open, close }) => {
  return (
    <TxModal
      open={open}
      onClose={() => close()}
      showCloseBtn={true}
      focusOnFirst={true}
    >
      <TxModalHeader>
        <Trans
          file="Modals"
          id="DMVSpecialCharModal_Title"
          fallback="How to enter license plate symbols"
        />
      </TxModalHeader>
      <div className={styles.modalDMVSpecialCharBody}>
        <TxModalBody>
          <section>
            <img
              src={publicURL('license-plate-dmv-modal.png')}
              height="128"
              width="232"
            />
          </section>
          <section>
            <Trans
              file="Modals"
              id="DMVSpecialCharModal_Body"
              fallback="<p>Leave out license plate symbols when entering your license plate number.</p><p>For example: I♥︎TEXAS should be entered as ITEXAS.</p>"
            />
          </section>
        </TxModalBody>
      </div>
      <TxModalFooter>
        <TxButton mode={TxButtonMode.Primary} onClick={() => close()}>
          <Trans file="Modals" id="Ok" fallback="OK, got it" />
        </TxButton>
      </TxModalFooter>
    </TxModal>
  );
};

DMVSpecialCharModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DMVSpecialCharModal;
