//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Helpers
import { formatPhoneNumber } from '../../../lib/helpers';

// Components
import { Trans } from '../..';

// Styles
import './otp-sent-content.scss';

// Types
type Props = {
  className?: string,
  channelCode: string,
  contactInfo: string,
  checkSpam: Boolean,
  phoneFormat: string,
};

const OTPSentContent = ({
  className,
  channelCode,
  contactInfo,
  checkSpam = true,
  phoneFormat = '',
}: Props) => (
  <div className="otp-sent-content">
    {channelCode === 'SMS' ? (
      <p className={classnames(className)} id="digit">
        <Trans
          file="MFA"
          id="CodeSentTo"
          fallback="We sent a six-digit code to "
        />{' '}
        <strong>{formatPhoneNumber(contactInfo, phoneFormat)}</strong>.{' '}
        <Trans
          file="MFA"
          id="CodeSentEnd"
          fallback=" The code will expire in 10 minutes."
        />
      </p>
    ) : (
      <p className={classnames(className)}>
        <Trans
          file="MFA"
          id="CodeSentTo"
          fallback="We sent a six-digit code to "
        />
        <strong className="nobr">{contactInfo}</strong>.
        <Trans
            file="MFA"
            id="CodeSentEnd"
            fallback=" The code will expire in 10 minutes."
          />
        {checkSpam && (
          <Trans
            file="MFA"
            id="CodeSentSpam"
            fallback=" If you don't see it, please check your spam folder."
          />
        )}
      </p>
    )}
  </div>
);

OTPSentContent.propTypes = {
  className: PropTypes.string,
  channelCode: PropTypes.string.isRequired,
  contactInfo: PropTypes.string.isRequired,
  checkSpam: PropTypes.string,
};

export default OTPSentContent;
