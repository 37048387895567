//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Assets
import { ReactComponent as DriverLicense } from '../../../assets/service-icons/driver-license-and-id-graphic.svg';
import { ReactComponent as ProfessionalLicense } from '../../../assets/service-icons/professional-license.svg';
import { ReactComponent as agencyApp } from '../../../assets/service-icons/agency-app.svg';
import { ReactComponent as VoterRegistration } from '../../../assets/service-icons/voter-registration.svg';
import { ReactComponent as VehicleRegistration } from '../../../assets/service-icons/vehicle-registration.svg';
import { ReactComponent as VehicleRegistration1 } from '../../../assets/service-icons/vehicle-registration-1.svg';
import { ReactComponent as VehicleRegistration2 } from '../../../assets/service-icons/vehicle-registration-2.svg';
import { ReactComponent as VehicleRegistration3 } from '../../../assets/service-icons/vehicle-registration-3.svg';
import { ReactComponent as BoatRegistration } from '../../../assets/service-icons/boat-registration.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-new.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import { ReactComponent as GreyDots } from '../../../assets/icons/grey.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as User } from '../../../assets/icons/user.svg';
import { ReactComponent as EditBlue } from '../../../assets/icons/edit-blue.svg';
import { ReactComponent as AlertWarn } from '../../../assets/icons/alert-warning.svg';
import { ReactComponent as CheckMark } from '../../../assets/icons/check-ko.svg';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external-link.svg';

// Styles
import './service-icon.scss';

type Props = {
  type: string,
  className?: string,
};

const icons = {
  'alert-warning': AlertWarn,
  'edit-info': Edit,
  'user-info': User,
  'edit-Icon': EditIcon,
  'users-Icon': UsersIcon,
  'grey-dots': GreyDots,
  'check-mark': CheckMark,
  'driver-license': DriverLicense,
  'professional-license': ProfessionalLicense,
  'agency-app': agencyApp,
  'voter-registration': VoterRegistration,
  'vehicle-registration': VehicleRegistration,
  'vehicle-registration-1': VehicleRegistration1,
  'vehicle-registration-2': VehicleRegistration2,
  'vehicle-registration-3': VehicleRegistration3,
  'boat-registration': BoatRegistration,
  'edit-blue': EditBlue,
  'delete-Icon': Delete,
  'external-link': ExternalLink,
};

const ServiceIcon = ({ type, className }: Props) => {
  if (!type) return null;

  const Icon = icons[type];

  return (
    <div className={classnames(`service-icon service-icon-${type}`, className)}>
      <Icon role="presentation" />
    </div>
  );
};

export default ServiceIcon;
