//@flow

import { REQUEST_TYPES } from '.';
import { APP_TRANSACTION_IDS } from '../reducers/app/appActions';

export const submitDLRLRRenewalRequest = (
  body: submitDLRLRRenewalRequestType
): RequestOptions => ({
  type: `[API] Submit DLRLR Renewal Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const updatedEmergencyContacts = (
  emergencyContacts: Object,
  basicInfo: Object,
  email: Object
): RequestOptions => {
  if (emergencyContacts.primaryEmergencyContactInfo) {
    if (emergencyContacts.primaryEmergencyContactInfo.phoneNumber1) {
      emergencyContacts.primaryEmergencyContactInfo.phoneNumber1 = emergencyContacts.primaryEmergencyContactInfo.phoneNumber1.replace(
        /-/g,
        ''
      );
    }

    if (emergencyContacts.primaryEmergencyContactInfo.phoneNumber2) {
      emergencyContacts.primaryEmergencyContactInfo.phoneNumber2 = emergencyContacts.primaryEmergencyContactInfo.phoneNumber2.replace(
        /-/g,
        ''
      );
    }
  }
  if (emergencyContacts.secondryEmergencyContactInfo) {
    if (emergencyContacts.secondryEmergencyContactInfo.phoneNumber1) {
      emergencyContacts.secondryEmergencyContactInfo.phoneNumber1 = emergencyContacts.secondryEmergencyContactInfo.phoneNumber1.replace(
        /-/g,
        ''
      );
    }

    if (emergencyContacts.secondryEmergencyContactInfo.phoneNumber2) {
      emergencyContacts.secondryEmergencyContactInfo.phoneNumber2 = emergencyContacts.secondryEmergencyContactInfo.phoneNumber2.replace(
        /-/g,
        ''
      );
    }
  }

  return {
    type: `[API] Update Emergency Contacts`,
    url: '/agency/app/transact',
    APIMethod: REQUEST_TYPES.POST,
    body: {
      userKeys: [basicInfo.userKey],
      agencyId: basicInfo.agencyId,
      appId: basicInfo.appId,
      profileInfo: { userKey: basicInfo.userKey },
      transactionInfo: {
        cardType: basicInfo.licenseType,
        emergencyContactsFailedStatus: basicInfo.emergencyContactsFailedStatus,
        primaryEmergencyContactInfo:
          emergencyContacts.primaryEmergencyContactInfo,
        secondryEmergencyContactInfo:
          emergencyContacts.secondryEmergencyContactInfo,
        transactionDateTimeStamp: emergencyContacts.transactionDateTimeStamp,
        personalInformation: {
          firstName: basicInfo.personalInformation.firstName,
          lastName: basicInfo.personalInformation.lastName,
          email: email,
        },
      },
      transactionId: APP_TRANSACTION_IDS.EMERGENCY_CONTACT_TRANSACT,
    },
  };
};
