// Vendors
import * as yup from 'yup';
import ERRORS from '../../errorCopy';

export const dmv = {
  APP_RULES: {
    dmvrr: {
      linkService: {
        lastFourOfVIN: yup
          .string()
          .trim()
          .min(4, { id: 'Field_VinLastFour' })
          .required({ id: 'Field_VinRequired' }),
        plateNumber: yup
          .string()
          .trim()
          .matches(/^[A-Za-z0-9]{1,7}$/, { id: 'Field_LicensePlateRequired' })
          .required({ id: 'Field_LicensePlateRequired' }),
      },
    },
  },
};
