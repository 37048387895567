export const selectUserId = state =>
  state.user.profile ? state.user.profile.userId : null;
export const selectOnBoardingProgressStatus = state =>
  state.user.profile ? state.user.profile.onBoardingProgressStatus : null;
export const selectWorkEmail = state =>
  state.user.contacts && state.user.contacts.workEmail
    ? state.user.contacts.workEmail.contactInfo
    : null;
export const selectPersonalPhone = state =>
  state.user.contacts && state.user.contacts.phone
    ? state.user.contacts.phone.contactInfo
    : null;
export const selectPersonalPhoneType = state =>
  state.user.contacts && state.user.contacts.phone
    ? state.user.contacts.phone.channelCode
    : null;
export const isGovEmployee = state =>
  state.user.profile.isGovernmentEmployee ? true : false;

export const selectUserPhone = state =>
  state.user.profile.phoneNum ? state.user.profile.phoneNum : null;

export const selectUserEmail = state =>
  state.user.profile.userEmail ? state.user.profile.userEmail : null;

export const selectUserProfileAccessTypes = state =>
  state.user.profile.userProfileAccessTypes
    ? state.user.profile.userProfileAccessTypes
    : null;
