/* eslint-disable no-unused-vars */
//actions
import { push } from 'connected-react-router';

// Consts
export const NAVBAR_SEARCH_FORM_TOGGLE = '[NAVBAR] Toggle Search';
export const NAVBAR_TAKEOVER_CONTENT_SET = '[NAVBAR] Set Takeover Nav Content';
export const CLEAR_NAVBAR_TAKEOVER_CONTENT = '[NAVBAR] Clear Takeover Nav Content';

export const toggleSearchForm = open => (dispatch, getState) => {
  const { navbar } = getState();
  const payload = typeof open === 'boolean' ? open : !navbar.searchActive;
  const bodyClassList = document.body.classList;

  if (document.body && payload) bodyClassList.add('searchActive');
  else bodyClassList.remove('searchActive');

  return dispatch({ type: NAVBAR_SEARCH_FORM_TOGGLE, payload });
};

export const setTakeoverNavContent = payload => dispatch =>
  dispatch({ type: NAVBAR_TAKEOVER_CONTENT_SET, payload });

export const clearTakeoverNavContent = payload => dispatch =>
  dispatch({ type: CLEAR_NAVBAR_TAKEOVER_CONTENT, payload });