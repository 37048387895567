import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  Container,
  FormActions,
  FormActionsItem,
  IconComponent,
  IconContainer,
  Trans,
  DontHaveIdModal,
} from '../../../components';
import { TextButton } from 'texkit/dist/components/Buttons';
import { TxButton, TxButtonMode } from 'texkit-ui/components';
import { LINK_SERVICES_STEPS } from '.';
import { selectAppByRouteParam } from '../../../reducers/agency/agencySelectors';
import { openModal } from '../../../reducers/modal/modalActions';

import { ReactComponent as IdIcon } from '../../../assets/icons/icon-id.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/icon-phone.svg';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/icon-shield.svg';

const LinkServiceStart = props => {
  const app = useSelector(state => selectAppByRouteParam(state, props));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!app) {
      dispatch(push(`/link/${LINK_SERVICES_STEPS.PRELOOKUP}/dpslr`));
    }
  }, [app, dispatch]);

  return (
    <>
      <div className="link-services start">
        <h2 className="page-header">
          <Trans file="dps" id="dps_WhatYouNeed" />
        </h2>
        <p className="page-body">
          <Trans file="dps" id="dps_StartPageBody" />
        </p>
        <IconContainer>
          <IconComponent
            Icon={PhoneIcon}
            className="phone"
            textFile="CreateAccount"
            textId="MobilePhone"
          />
          <IconComponent
            Icon={IdIcon}
            className="id"
            textFile="CreateAccount"
            textId="LicenseOrID"
          />
          <IconComponent
            Icon={ShieldIcon}
            className="shield"
            textFile="CreateAccount"
            textId="SocialSecurityNumber"
          />
        </IconContainer>

        <Container className="action-buttons">
          <FormActions className="btn-submit-form" align="right">
            <FormActionsItem>
              <TxButton
                mode={TxButtonMode.Primary}
                onClick={() => {
                  dispatch(
                    push(
                      `/link/${LINK_SERVICES_STEPS.PHONE_CAPTURE}/${app.appSlug}/stepup`
                    )
                  );
                }}
              >
                <Trans file="Labels" id="GetStarted" />
              </TxButton>
            </FormActionsItem>
            <FormActionsItem>
              <TxButton
                mode={TxButtonMode.Secondary}
                onClick={() => dispatch(push('/dashboard'))}
              >
                <Trans file="Labels" id="Cancel" />
              </TxButton>
            </FormActionsItem>
          </FormActions>
        </Container>
        <div className="text-link-button">
          <TextButton
            type="button"
            onClick={() => dispatch(openModal('DontHaveIdModal'))}
          >
            <Trans
              file="CreateAccount"
              id="NoLicenseOrID"
              fallback="Don't have a Texas DL or ID?"
            />
          </TextButton>
        </div>
      </div>
      <DontHaveIdModal
        body={<Trans file="Modals" id="CallHelpDeskReverify" fallback="" />}
      />
    </>
  );
};

const RouteForLinkServiceStart = () => (
  <Route
    path="/link/start/:appSlug"
    render={props => <LinkServiceStart {...props} />}
  />
);

export default RouteForLinkServiceStart;
