//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';

// Styles
import '../dps-FAQ-modal.scss';

const DPS_Contact_Your_Third_Party_Modal = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="DPS_Contact_Your_Third_Party_Modal" className="faq-modal">
      <h2 className="h1 modal-title-general">
        <Trans file="dps" id="dps_Contact_Your_Third_Party" />
      </h2>
      <p className="modal-description-general">
        <Trans file="dps" id="dps_Contact_Your_Third_Party_Desc" />
      </p>

      <ButtonPrimary
        className="modal-close-button-general"
        onClick={() =>
          dispatch(closeModal('DPS_Contact_Your_Third_Party_Modal'))
        }
      >
        <Trans file="Labels" id="okGotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DPS_Contact_Your_Third_Party_Modal;
