//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, Trans } from '../..';
import { Button, ButtonDanger } from 'texkit/dist/components/Buttons';

// Styles
import './delete-contact-modal.scss';

type Props = {
  modal: Object,
  closeModal: Function,
};

const DeleteContactModal = ({
  modal: { data: { onDelete } = {} } = {},
  closeModal,
}: Props) => {
  const onConfirm = async () => {
    onDelete();
    closeModal('DeleteContactModal');
  };
  return (
    <Modal name="DeleteContactModal" className="delete-contact-modal">
      <>
        <h2 className="h1 modal-title delete-contact-modal-header">
          <Trans file="Modals" id="DeleteContact" />
        </h2>

        <div className="modal-footer delete-contact-modal-footer">
          <Button onClick={() => closeModal('DeleteContactModal')}>
            <Trans file="Labels" id="Cancel" />
          </Button>
          <ButtonDanger
            className="delete-contact-modal-button"
            onClick={onConfirm}
          >
            <Trans file="Labels" id="Yes_Delete" />
          </ButtonDanger>
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = ({ modal }): Object => ({ modal });

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContactModal);
