// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Styles
import './dont-have-id-modal.scss';

// Components
import { Trans } from '../../..';

import Modal from '../Modal';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { closeModal } from '../../../../reducers/modal/modalActions';

const DontHaveIdModal = ({ body }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="DontHaveIdModal"
      className="dont-have-id-modal"
      disableCloseButton="true"
    >
      <h2 className="dont-have-id-modal-title">
        <Trans file="Modals" id="DontHaveId" fallback="" />
      </h2>
      {body && <p className="modal-content">{body}</p>}
      <div className="dont-have-id-modal-btns">
        <ButtonPrimary
          onClick={() => {
            dispatch(closeModal('DontHaveIdModal'));
          }}
        >
          <Trans file="Labels" id="gotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

DontHaveIdModal.propTypes = {
  body: PropTypes.node,
};

export default DontHaveIdModal;
