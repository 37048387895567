import React from 'react';
import { Route } from 'react-router-dom';
import { push, goBack } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  Container,
  Form,
  InputPhone,
  InputGroup,
  Trans,
} from '../../../components';
import { TxButton, TxButtonMode } from 'texkit-ui/components';
import { LINK_SERVICES_STEPS } from '.';
import { commonRules } from '../../../lib/validation/rules';
import { capturePhoneSchema } from '../../../lib/validation/schemas/createAccount';
import { cleanPhoneNumber, formatPhoneNumber } from '../../../lib/helpers';
import { storeLinkServiceData } from '../../../reducers/agency/linkService/linkServiceActions';

const LinkServicePhoneCapture = () => {
  const dispatch = useDispatch();
  const existingPhone = useSelector(({ user, agency }) => {
    const { phoneNum, phoneNumType } = user.profile;
    const accountSettingsPhone =
      phoneNum && phoneNumType === 'PM' ? phoneNum : '';

    const agencyPhone =
      (agency.link && agency.link.user && agency.link.user.phoneNum) || '';
    if (agencyPhone) {
      return formatPhoneNumber(agencyPhone, '(###) ###-####');
    }

    return formatPhoneNumber(accountSettingsPhone, '(###) ###-####');
  });

  const handleSubmit = async ({ phoneNum }, { setSubmitting, resetForm }) => {
    const newPHoneNum = cleanPhoneNumber(phoneNum);
    const isPhoneVerified = newPHoneNum === cleanPhoneNumber(existingPhone);
    await dispatch(
      storeLinkServiceData({
        user: {
          phoneNum: newPHoneNum,
          isPhoneVerified: false, // temp value, will later be comparted to existing phone
        },
      })
    );

    if (isPhoneVerified) {
      dispatch(push(`/link/${LINK_SERVICES_STEPS.ID}/dpslr/stepup`));
    } else {
      dispatch(push(`/link/${LINK_SERVICES_STEPS.PHONE_VERIFY}/dpslr/stepup`));
    }

    setSubmitting(false);
    resetForm({ phoneNum: '' });
  };

  return (
    <Container className="link-services phone-capture">
      <h2 className="page-header">
        {existingPhone ? (
          <Trans file="dps" id="dps_PhoneCaptureTitleConfirm" />
        ) : (
          <Trans file="dps" id="dps_PhoneCaptureTitleAdd" />
        )}
      </h2>
      <p className="page-body">
        <Trans file="dps" id="dps_PhoneCaptureBody" />
      </p>

      <Form
        formikConfig={{
          onSubmit: handleSubmit,
          initialValues: {
            phoneNum: existingPhone,
          },
          validationSchema: capturePhoneSchema,
        }}
        render={({ FormError, FormActions, FormActionsItem, formikProps }) => {
          // Formik workaround to enable the 'submit' button due to Formik not recognizing the phone field as 'dirty'
          // when the field is pre-populated with a value.
          let disabled = !formikProps.isValid || formikProps.isSubmitting;
          const { dirty, initialValues } = formikProps;
          if (!dirty && initialValues.phoneNum) {
            disabled = false;
          }

          return (
            <>
              <InputGroup>
                <InputPhone
                  className="phone-capture-input-phone"
                  name="phoneNum"
                  formikProps={formikProps}
                  labelPosition="above"
                  label={
                    <Trans file="CreateAccount" id="PhoneCaptureFieldHelper" />
                  }
                  placeholder="(000) 000-0000"
                  mask={commonRules.PHONE_MASK}
                />
              </InputGroup>
              <FormError />
              <FormActions>
                <FormActionsItem>
                  <TxButton
                    mode={TxButtonMode.Primary}
                    disabled={disabled}
                    type="submit"
                  >
                    <Trans file="Labels" id="Next" fallback="Next" />
                  </TxButton>
                </FormActionsItem>
                <FormActionsItem>
                  <TxButton
                    mode={TxButtonMode.Secondary}
                    onClick={() => dispatch(goBack())}
                  >
                    <Trans file="Labels" id="Back" />
                  </TxButton>
                </FormActionsItem>
              </FormActions>
            </>
          );
        }}
      />
      <p className="page-disclaimer">
        <Trans file="dps" id="dps_PhoneCaptureFooter" />
      </p>
    </Container>
  );
};

const RouteForLinkServicePhoneCapture = () => (
  <Route
    path={`/link/${LINK_SERVICES_STEPS.PHONE_CAPTURE}/:appSlug`}
    render={props => <LinkServicePhoneCapture {...props} />}
  />
);

export default RouteForLinkServicePhoneCapture;
