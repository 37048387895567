// Helpers
export function selectLicense(state) {
  return state.dps.selected;
}

export function selectAppProfile({ dps: { appProfile } }) {
  return appProfile;
}

export function selectTotalFee({ dps: { donationValues: { total } = {} } }) {
  return total;
}

export function selectSurrenderCardOption({
  dps: { surrenderCardOption = false },
}) {
  return surrenderCardOption;
}

export function selectDonationValues({ dps: { donationValues = {} } }) {
  return donationValues;
}

export function selectDonationOptions({ dps: { donationOptions = {} } }) {
  return donationOptions;
}

export function selectOrganDonorOption({ dps: { organDonorOption = false } }) {
  return organDonorOption;
}

export function selectVoterRegistration({
  dps: { voterRegistration = false },
}) {
  return voterRegistration;
}

export function selectVolunteerElectionJudge({
  dps: { volunteerElectionJudge = false },
}) {
  return volunteerElectionJudge;
}

export function selectVoterRegistrationAppType({
  dps: { voterRegistrationAppType = false },
}) {
  return voterRegistrationAppType;
}

export function selectEmergencyContacts({ dps: { emergencyContacts = {} } }) {
  return emergencyContacts;
}

export function selectRenewAddressChangeFlag({
  dps: { renewAddressChangeFlag = false },
}) {
  return renewAddressChangeFlag;
}

export function selectRenewalHomeAddress({ dps: { renewalHomeAddress = {} } }) {
  return renewalHomeAddress;
}

export function selectUpgradeFlag({ dps: { upgradeFlag = false } }) {
  return upgradeFlag;
}

export function selectJointRenewalUpgradeFlag({
  dps: { jointRenewalUpgradeFlag = false },
}) {
  return jointRenewalUpgradeFlag;
}

export function selectRenewalMailingAddress({
  dps: { renewalMailingAddress = {} },
}) {
  return renewalMailingAddress;
}

export function selectMailingSameAsHomeAddress({
  dps: { mailingSameAsHomeAddress = false },
}) {
  return mailingSameAsHomeAddress;
}
export function selectEmergencyContactsFailedStatus({
  dps: { emergencyContactsFailedStatus = false },
}) {
  return emergencyContactsFailedStatus;
}

export function selectDlsEmergencyContactsHasData({
  dps: { isDlsEmergencyContactsHasData = true },
}) {
  return isDlsEmergencyContactsHasData;
}
