//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Assets
import { ReactComponent as AmexSvg } from '../../../assets/payment-methods/amex.svg';
import { ReactComponent as DiscoverLogoSvg } from '../../../assets/payment-methods/discover.svg';
import { ReactComponent as MasterCardLogoSvg } from '../../../assets/payment-methods/mastercard.svg';
import { ReactComponent as VisaLogoSvg } from '../../../assets/payment-methods/visa.svg';

// Styles
import './payment-method-logo.scss';

type Props = {
  name: string,
  className?: string,
};

const paymentMethodLogos = {
  amex: <AmexSvg role="presentation" />,
  discover: <DiscoverLogoSvg role="presentation" />,
  mastercard: <MasterCardLogoSvg role="presentation" />,
  visa: <VisaLogoSvg role="presentation" />,
};

const PaymentMethodLogo = ({ name, className, ...props }: Props) => {
  if (!name) return null;

  return (
    <div {...props} className={classnames('payment-method-logo', className)}>
      {paymentMethodLogos[name]}
    </div>
  );
};

export default PaymentMethodLogo;
