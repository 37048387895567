// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Components
import { Container, Logo, Trans } from '..';
//import { openModal } from '../../../reducers/modal/modalActions';

//Reducers
import { openModal } from '../../reducers/modal/modalActions';

// Styles
import './takeover-nav.scss';

const speedBumpWhitelist = [
  '/dmvrr/success',
  '/dmvrr/in-process-error',
  '/dmvrr/recently-renewed-error',
  '/dmvrr/can-not-renew-online',
  '/dmvrr/citation-cant-renew',
  '/dmvrr/cant-confirm',
  '/dmvrr/error',
];

const TakeoverNavHeading = ({ title, subtitle }) =>
  title ? (
    <>
      <div className="takeover-nav-spacer" />
      <div
        className={classnames('takeover-nav-heading', {
          'takeover-nav-hasSubtitle': subtitle,
        })}
      >
        {subtitle && (
          <span className="takeover-nav-subtitle">
            {typeof subtitle === 'string' ? (
              subtitle
            ) : (
              <Trans file={subtitle.file} id={subtitle.id} />
            )}
          </span>
        )}
        <span className="takeover-nav-title">
          {typeof title === 'string' ? (
            title
          ) : (
            <Trans file={title.file} id={title.id} />
          )}
        </span>
      </div>
    </>
  ) : null;

TakeoverNavHeading.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ file: PropTypes.string, id: PropTypes.string }),
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ file: PropTypes.string, id: PropTypes.string }),
  ]),
};

const TakeoverNav = () => {
  const dispatch = useDispatch();
  const { title, subtitle } = useSelector(state => state.navbar.takeover || {});
  const { pathname } = useSelector(state => state.router.location);

  const displaySpeedBump = () => {
    if (
      pathname.includes('/dmvrr/') &&
      !speedBumpWhitelist.includes(pathname)
    ) {
      dispatch(openModal('RenewalSpeedbumpModal'));
    } else if (
      pathname.includes('reverify') &&
      !speedBumpWhitelist.includes(pathname)
    ) {
      dispatch(openModal('DPS_Reverify_SpeedBump_Modal'));
    } else {
      dispatch(push('/dashboard'));
    }
  };

  return (
    <>
      <nav className="takeover-nav" role="navigation">
        <Container className="takeover-nav-container">
          <Link
            onClick={displaySpeedBump}
            className="takeover-nav-logo"
            aria-label="View My Dashboard"
          >
            <Logo className={pathname.includes("reverify") && "reverify"} />
          </Link>
          <TakeoverNavHeading title={title} subtitle={subtitle} />
        </Container>
      </nav>
    </>
  );
};

TakeoverNav.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ file: PropTypes.string, id: PropTypes.string }),
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ file: PropTypes.string, id: PropTypes.string }),
  ]),
};

TakeoverNavHeading.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ file: PropTypes.string, id: PropTypes.string }),
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ file: PropTypes.string, id: PropTypes.string }),
  ]),
};

export default TakeoverNav;
