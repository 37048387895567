//@flow

// Vendors
import React from 'react';
import { Duration } from 'luxon';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { ReactComponent as TimedOutSVG } from '../../../../assets/timed-out.svg';

// Styles
import './session-warning-modal.scss';

type Props = {
  keepAlive: Function,
  onOpen: Function,
  onClose: Function,
  duration: Duration,
};

const SessionWarningModal = ({
  keepAlive,
  onOpen,
  onClose,
  duration,
}: Props) => (
  <Modal
    name="SessionWarningModal"
    className="modal-sm session-warning-modal"
    onOpen={onOpen}
    onClose={onClose}
    disableCloseButton={true}
    disableOnClickOutside={true}
    role="alert"
  >
    <h2 className="h1 modal-title">
      <Trans
        file="Modals"
        id="AreYouStillThere"
        fallback="Are you still there?"
      />
    </h2>
    <div className="modal-content">
      <div className="modal-graphic">
        <TimedOutSVG role="presentation" />
      </div>
      <div className="session-warning-modal-timer">
        {duration.toFormat('m:ss')}
      </div>
      <div>
        <Trans
          file="Modals"
          id="SessionWillEndInTwoMinutes"
          fallback="Due to inactivity, this session will end in two minutes. Please let us know if you need more time."
        />
      </div>
    </div>
    <FormActions align="center">
      <FormActionsItem fullWidth={true}>
        <ButtonPrimary onClick={keepAlive}>
          <Trans
            file="Labels"
            id="INeedMoreTime"
            fallback="I need more time."
          />
        </ButtonPrimary>
      </FormActionsItem>
    </FormActions>
  </Modal>
);

export default SessionWarningModal;
