//@flow

// Vendors
import React from 'react';
import { useDispatch, connect } from 'react-redux';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './real-id-page-modal.scss';

// Switch to adjusted SCSS if on iOS device
var userAgent = window.navigator.userAgent.toLowerCase(),
  ios = /iphone|ipod|ipad/.test(userAgent);

type Props = {
  handleRenewClick: Function,
  hanldeReplaceCAClick: Object,
  handleEmergencyClick: Object,
  isReplaceCheck: Boolean,
  isEmerContactsCheck: Boolean,
  modal: Object,
};
const RealIdPageModal = ({
  handleRenewClick,
  hanldeReplaceCAClick,
  handleEmergencyClick,
  modal: { data: { isReplaceCheck, isEmerContactsCheck, upgrade } = {} } = {},
}: Props) => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="RealIdPageModal"
      className={ios ? 'real-id-page-modal-ios' : 'real-id-page-modal'}
    >
      <div>
        <h2 className="h1 modal-header">
          <Trans file="dps" id="Real_ID_Page_Title" />
        </h2>
        <p className="modal-description">
          <Trans file="dps" id="Real_ID_Page_Desc1" />
          <Trans file="dps" id="Real_ID_Page_Desc2" />
          <Trans file="dps" id="Real_ID_Page_Desc3" />
        </p>
      </div>
      <ButtonPrimary
        className="modal-close-button"
        onClick={() => {
          if (isReplaceCheck) {
            hanldeReplaceCAClick();
          } else if (isEmerContactsCheck) {
            handleEmergencyClick();
          } else {
            handleRenewClick(upgrade);
          }
          dispatch(closeModal('RealIdPageModal'));
        }}
      >
        <Trans file="Labels" id="Continue" />
      </ButtonPrimary>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modal: state.modal,
});
export default connect(mapStateToProps, null)(RealIdPageModal);
