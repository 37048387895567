import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { push, goBack } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  Container,
  Form,
  InputPhone,
  InputGroup,
  Trans,
} from '../../../components';
import { TxButton, TxButtonMode } from 'texkit-ui/components';
import { LINK_SERVICES_STEPS } from '.';
import { commonRules } from '../../../lib/validation/rules';
import { capturePhoneSchema } from '../../../lib/validation/schemas/createAccount';
import { cleanPhoneNumber, formatPhoneNumber } from '../../../lib/helpers';

const LinkServiceIdentity = () => {
  const dispatch = useDispatch();
  const userLinkData = useSelector(state => state.agency.link);

  return (
    <Container className="link-services identity">
      <h2 className="page-header">Enter your identity information</h2>
    </Container>
  );
};

const RouteForLinkServiceIdentity = () => (
  <Route
    path={`/link/${LINK_SERVICES_STEPS.ID}/:appSlug`}
    render={props => <LinkServiceIdentity {...props} />}
  />
);

export default RouteForLinkServiceIdentity;
