// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { Trans } from '../..';
import NavDropdown from '../NavDropdown/NavDropdown';

// Actions
import { signOut } from '../../../reducers/auth/authActions';

// Styles
import './navbar-account-dropdown.scss';

import { getServiceMenuItems } from '../NavbarServiceMenu/NavbarServiceMenu';

const NavbarAccountDropdown = () => {
  const user = useSelector(state => state.user || {});
  const agency = useSelector(state => state.agency || {});
  const dispatch = useDispatch();

  const menuItems = [
    {
      label: <Trans file="Labels" id="Home" fallback="Home" />,
      path: '/dashboard',
    },
    {
      label: (
        <Trans file="Labels" id="AccountSettings" fallback="Account Settings" />
      ),
      path: '/account',
    },
    {
      label: <Trans file="Labels" id="History" fallback="History" />,
      path: '/history/transactions',
    },
    {
      label: <Trans file="Labels" id="Language" fallback="Language" />,
    },
    {
      label: <Trans file="Labels" id="SignOut" fallback="Sign Out" />,
      onClick: () => dispatch(signOut()),
    },
  ];

  const menuItemsServices = getServiceMenuItems(agency);

  if (!user || !user.profile || !user.profile.initials) return null;

  return (
    <>
      {user.profile.displayName}
      <NavDropdown
        id="navbar-account-dropdown"
        className="navbar-account-dropdown"
        items={menuItems}
        itemsServices={menuItemsServices}
        toggleComponent={({ ...props }) => (
          <button
            {...props}
            className="navbar-avatar dropdown-toggle"
            aria-label="Additional Account Settings"
          />
        )}
      />
    </>
  );
};

export default withRouter(NavbarAccountDropdown);
