// @flow
import { gaActions } from '../gaActionTypes';

// Helpers
import { selectActiveModal } from './modalSelectors';

// Consts
export const MODAL_CLOSE = `[MODAL] ${gaActions.global} Close`;
export const MODAL_OPEN = `[MODAL] ${gaActions.global} Open`;
export const MODAL_TOGGLE = '[MODAL] Toggle';
export const MODAL_CLEAR_DATA = '[MODAL] Clear Data';
export const MODAL_SCROLL_TO_TOP = '[MODAL] Scroll To Top';

export const toggleModal = (modalId: string, data?: Object) => (
  dispatch: Dispatch<Action<Object>>
) => dispatch({ type: MODAL_TOGGLE, payload: { activeModal: modalId, data } });

export const openModal = (modalId: string, data?: Object) => (
  dispatch: Dispatch<Function | Object>,
  getState: Function
) => {
  const { modal } = getState();
  if (modal.activeModal && modal.activeModal !== modalId) {
    return dispatch(
      closeModal(modal.activeModal, {
        onClose: () => dispatch(openModal(modalId, data)),
      })
    );
  }

  if (!modal.activeModal || modal.activeModal !== modalId) {
    return dispatch({
      type: MODAL_OPEN,
      payload: {
        activeModal: modalId,
        data,
        onOpen: () => {
          if (data && typeof data.onOpen === 'function') data.onOpen();

          setTimeout(() => {
            if (document.body)
              return document.body.classList.add('modalActive');
          }, 10);
        },
      },
    });
  }
};

export const closeModal = (modalId: string, data?: Object) => (
  dispatch: Dispatch<Action<{ activeModal: string, data?: Object }>>,
  getState: Function
) => {
  const { modal } = getState();

  if (modal.activeModal === modalId) {
    dispatch({
      type: MODAL_CLOSE,
      payload: {
        activeModal: modalId,
        onClose: () => {
          if (document.body) document.body.classList.remove('modalActive');
          if (data && typeof data.onClose === 'function') data.onClose();
        },
      },
    });

    return setTimeout(() => clearModalData(), 300);
  }
};

export const closeCurrentModal = () => (
  dispatch: Dispatch<Action<>>,
  getState: Function
) => dispatch(closeModal(selectActiveModal(getState())));

export const clearModalData = () => (dispatch: Dispatch<Action<>>) =>
  dispatch({ type: MODAL_CLEAR_DATA });

export const scrollToTopModal = (modalId: string) => (
  dispatch: Dispatch<Action<{ activeModal: string, data?: Object }>>,
  getState: Function
) => {
  const { modal } = getState();

  if (modal.activeModal === modalId) {
    dispatch({
      type: MODAL_SCROLL_TO_TOP,
      payload: {
        activeModal: modalId,
      },
    });
    document.querySelector('#' + modalId).scrollTop = 0;
  }
};

export const scrollToTopCurrentModal = () => (
  dispatch: Dispatch<Action<>>,
  getState: Function
) => dispatch(scrollToTopModal(selectActiveModal(getState())));
